import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VDialog,{staticClass:"rtets",attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"sign-in-btn",attrs:{"height":"44px"}},'v-btn',attrs,false),on),[_vm._v(" Sign Up ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"signup-card"},[_c(VCard,[_c(VForm,{ref:"signup_form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCardTitle,[_c('span',{staticClass:"text-h5 card-title"},[_vm._v("Sign Up")])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VTextField,{attrs:{"label":"User name*","outlined":"","height":"36px","dense":"","rules":[
                                            _vm.rules.required,
                                            (value) => {
                                                return this.is_unique_username || this.username_error_message;
                                            },
                                        ]},on:{"keyup":function($event){return _vm.updateUserNameErrorMessage()}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VTextField,{attrs:{"label":"Email*","type":"email","outlined":"","height":"36px","dense":"","rules":[
                                            _vm.rules.required,
                                            _vm.rules.email,
                                            (value) => {
                                                return this.is_unique_email || this.email_error_message;
                                            },
                                            (value) => {
                                                return (
                                                    this.is_valid_email_domain ||
                                                    'Email address is not valid. Please use non-disposable email'
                                                );
                                            },
                                        ]},on:{"keyup":function($event){return _vm.updateEmailErrorMessage()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VTextField,{attrs:{"label":"Password*","type":"password","outlined":"","height":"36px","dense":"","rules":[_vm.rules.required]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VAutocomplete,{attrs:{"label":"Country*","items":_vm.countries,"rules":[_vm.rules.required],"color":"primary","outlined":"","height":"36px","dense":"","menu-props":{ offsetY: true },"append-icon":""},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"cancel-btn",on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c(VBtn,{staticClass:"signup-btn",attrs:{"type":"submit","loading":_vm.is_submitting}},[_vm._v(" Sign Up ")])],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }