const state = {
    characterName: false,
};

const mutations = {
    SET_CHARACTER_NAME: (state, error) => {
        state.characterName = error;
    },
};

const actions = {};

export default {
    state,
    mutations,
    actions,
};
