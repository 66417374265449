import axios from 'axios';
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BOT_API, // url = base url + request url
    timeout: 6000, // request timeout
});

service.interceptors.request.use(
    (config) => {
        // do something before request is sent
        config.headers['Content-Type'] = `application/json`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// response interceptor
service.interceptors.response.use(
    (response) => {
        const res = response;
        return res;
    },
    (error) => {
        console.log('err' + error); // for debug
        return Promise.reject(error.response);
    },
);
export function botTrade(payload, token) {
    return service.request({
        url: `/trade?token=${token}`,
        method: 'post',
        data: JSON.stringify(payload),
    });
}

// export function pingBotRequest() {
//     return service.request({
//         url: `/trade`,
//         method: 'post',
//         baseURL: process.env.VUE_APP_BOT_RESERVE_API,
//         // data: JSON.stringify(payload)
//     });
// }
