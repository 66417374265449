<template>
    <div class="trade-info">
        <div class="section-title">General Information</div>
        <div class="profile-item-counter">
            <p class="secondary-color-text">Steam ID64:</p>
            <p class="value">{{ user.steam_id }}</p>
        </div>
        <div class="section-line"></div>
        <div class="profile-item-counter">
            <div class="trade-link-actions">
                <p class="secondary-color-text nowrap">Trade Link:</p>
                <v-text-field class="trade-link__input" v-model="tradeLinkValue" outlined ref="input__trade-link">
                </v-text-field>
            </div>
            <div class="trade-link-actions">
                <v-btn color="#17D0C1" class="signin-btn">
                    <a
                        :href="`https://steamcommunity.com/profiles/${user.steam_id}/tradeoffers/privacy#trade_offer_access_url`"
                        target="_blank"
                        class="get-link"
                    >
                        get the link
                    </a></v-btn
                >

                <v-btn color="#17D0C1" class="signin-btn" @click="saveOfferLink()">save</v-btn>
            </div>
        </div>
        <div class="section-line"></div>
        <div class="profile-item-counter">
            <div class="trade-link-actions" style="gap: 57px">
                <p class="secondary-color-text nowrap">Email:</p>

                <v-text-field
                    class="trade-link__input"
                    v-model="user_email"
                    :rules="[rules.required, rules.email]"
                    outlined
                    ref="input__trade-link"
                >
                </v-text-field>
            </div>
            <div class="trade-link-actions">
                <v-btn color="#17D0C1" class="signin-btn" @click="saveEmail()">save</v-btn>
            </div>
        </div>
        <div class="section-line"></div>
        <div class="profile-item-counter">
            <div class="trade-link-actions" style="gap: 36px">
                <p class="secondary-color-text">Country:</p>
                <v-autocomplete
                    label="Country*"
                    v-model="user_country"
                    :items="countries"
                    :rules="[rules.required]"
                    color="secondary"
                    outlined
                    dense
                    :menu-props="{ offsetY: true }"
                    append-icon=""
                >
                </v-autocomplete>
            </div>
            <div class="trade-link-actions">
                <v-btn color="#17D0C1" class="signin-btn" @click="saveCountry()">save</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Countries from '@/assets/json/countries.json';
import { setUserCountry, setUserEmail, setTradeOfferInfo } from '../../api/auth';
import { toastConfig } from '../../utils/toastConfig.js';

export default {
    name: 'tradeInfo',
    props: {},
    data() {
        return {
            tradeLinkValue: '',
            user_email: '',
            countries: [],
            user_country: '',
            modal: false,
            saving: false,
            msg: '',
            rules: {
                required: (value) => !!value || 'Input field is required!',
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Invalid e-mail. Please enter valid email.';
                },
            },
        };
    },
    methods: {
        async saveOfferLink() {
            await setTradeOfferInfo(this.user._id, { link: this.tradeLinkValue }, this.user.access_token)
                .then((res) => {
                    this.$store.dispatch('getInfo');
                    const t = toastConfig('Your trade link has been updated successfully', 5000);
                    this.$toast.success(t.msg, t.options);
                })
                .catch((err) => {
                    const t = toastConfig('There was an issue updating your trade link, please try again later.', 5000);
                    this.$toast.error(t.msg, t.options);
                });
        },
        async saveCountry() {
            await setUserCountry(this.user._id, { country: this.user_country }, this.user.access_token)
                .then((res) => {
                    this.$store.dispatch('getInfo');
                    const t = toastConfig('Your country has been updated successfully', 5000);

                    this.$toast.success(t.msg, t.options);
                })
                .catch((err) => {
                    const t = toastConfig('There was an error updating your country, pleaser try again later.', 5000);

                    this.$toast.error(t.msg, t.options);
                });
        },
        async saveEmail() {
            await setUserEmail(this.user._id, { email: this.user_email }, this.user.access_token)
                .then((res) => {
                    this.$store.dispatch('getInfo');

                    if (res.data && res.data.k === 'email' && res.data.msg) {
                        const t = toastConfig(res.data.msg, 5000);
                        this.$toast.error(t.msg, t.options);
                    } else {
                        const t = toastConfig('Your email has been updated successfully.', 5000);
                        this.$toast.success(t.msg, t.options);
                    }
                })
                .catch((error) => {
                    const t = toastConfig('There was an error when updating your email please try again later.', 5000);
                    this.$toast.error(t.msg, t.options);
                });
        },
        hideModal() {
            this.modal = false;
        },
    },
    computed: {
        ...mapGetters(['user']),
    },
    mounted: async function () {
        this.tradeLinkValue = `https://steamcommunity.com/tradeoffer/new/?partner=${this.user.trade_partner_id}&token=${this.user.trade_token}`;
        this.user_email = this.user.email;
        this.user_country = this.user.country;
        this.countries = Countries.map((country) => {
            return country.name;
        });
    },
    watch: {
        user: {
            async handler(val) {
                this.tradeLinkValue = `https://steamcommunity.com/tradeoffer/new/?partner=${this.user.trade_partner_id}&token=${this.user.trade_token}`;
                this.user_email = this.user.email;
                this.user_country = this.user.country;
            },
        },
    },
};
</script>

<style scoped>
.trade-info {
    background-color: #383f51;
    border-radius: 4px;
    /* max-width: 70.5vw; */
    padding: 25px;
}
/** medium screens */
@media screen and (max-width: 1500px) {
    .trade-info {
        width: 570px;
    }
    .trade-link__input {
        min-width: 0px !important;
    }
}

/** small screens */
@media screen and (max-width: 1000px) {
    .trade-info__row {
        padding: 10px 10px 10px 10px !important;
        margin-right: 15px !important;
    }
}

@media screen and (max-width: 820px) {
    /* .profile-item-counter > div > .trade-link.actions:nth-child(even)  {
        background-color: red;
    } */
    .profile-item-counter {
        display: flex;
        flex-direction: column;
    }
    /** actions buttons on rows */
    .trade-link-actions:nth-child(odd) {
        display: flex;
        flex-basis: column;
        justify-content: flex-end;
    }
}
.section-title {
    color: #f1f1f1;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 25px;
}
.profile-item-counter {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.section-line {
    border-top: 0.5px solid #4c515c;
    width: 100%;
    padding-block: 5px;
}

.value {
    color: #f1f1f1;
}
.secondary-color-text {
    color: #c0c0c2;
}

.trade-link-actions {
    display: flex;
    gap: 25px;
    align-items: center;
}
.trade-link-actions > a {
    /* color: #17d0c1; */
    padding: 10px 10px 10px 10px;
}

.trade-link__input {
    box-sizing: border-box;
    font-size: 16px;
    color: #c0c0c2;
    width: 100%;
    min-width: 692px;
    padding-top: 0px !important;
    margin-top: 0px !important;
}
.nowrap {
    white-space: nowrap;
}
.trade-link-actions > a:hover {
    background-color: #56595f;
    border-radius: 5px;
}
.get-link {
    color: rgba(0, 0, 0, 0.87) !important;
}
</style>
