<template>
    <v-dialog v-model="show" persistent width="500">
        <v-card color="#555C70">
            <v-card-title class="headline modal grey text-white">Note</v-card-title>

            <v-card-text class="modal text-white">
                Need more gold than what your skins offer? Buy your gold at our Shop to the most competitive prices on
                the market.
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#17D0C1" @click="handleClick('navigation')"> GO TO SHOP </v-btn>
                <v-btn color="#17D0C1" @click="handleClick('stay')"> STAY </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'shopNavModal',
    components: {},
    props: {
        showModal: Boolean,
    },
    data() {
        return {};
    },
    computed: {
        show() {
            return this.$store.state.shop.showShopNavModal;
        },
    },
    methods: {
        handleClick(action) {
            if (action === 'navigation') {
                this.$store.commit('SET_KEY_VALUE_PAIR', ['showShopNavModal', false]);
                this.$router.push('/shop');
            }
            if (action === 'stay') {
                this.$store.commit('SET_KEY_VALUE_PAIR', ['showShopNavModal', false]);
            }
        },
    },
};
</script>

<style scoped></style>
