import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user.steam_id)?_c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"persistent":"","width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"color":"#555C70"}},[_c(VCardTitle,{staticClass:"headline modal grey text-white"},[_vm._v(" TRADE OFFER LINK ")]),_c(VCardText,{staticClass:"modal text-white"},[_c('p',{staticClass:"text-center"},[_vm._v(" To calculate your Steam Inventory to MMO Gold Trade-Raid needs your Trade Offer Link. "),_c('br'),_c('span',[_vm._v(" You can retrieve your Trade Offer Link ")]),_c('a',{staticClass:"ext_link",attrs:{"href":`https://steamcommunity.com/profiles/${_vm.user.steam_id}/tradeoffers/privacy#trade_offer_access_url`,"target":"_blank"}},[_vm._v(" HERE ")])]),_c('p',{staticClass:"mobile-text"},[_vm._v(" On mobile: If your Steam app doesn't load, close the app and press the link above again. ")])]),_c('p',{staticClass:"text-center text-white"},[_vm._v("Copy and Paste your Trade Offer Link in the box below:")]),_c('div',{staticStyle:{"width":"70%","margin":"0 auto","color":"white"}},[_c(VTextField,{staticClass:"text-white",attrs:{"filled":"","label":"Your Trade Offer Link","outlined":"","color":"#fff","light":"","required":""},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"signin-btn mr-3",staticStyle:{"width":"100%"},attrs:{"color":"#17D0C1"},on:{"click":function($event){return _vm.hideModal()}}},[_vm._v(" Save ")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }