import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCol,{staticClass:"user-offer-container",attrs:{"cols":"12"}},[_c(VCard,{attrs:{"outlined":""}},[_c('h1',{staticStyle:{"display":"none"}},[_vm._v("Trade Service")]),_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"card-title"},[_vm._v("You offer")]),_c('span',{staticClass:"items-count"},[_vm._v(_vm._s(`${_vm.offerItems.length}`)+" "),_c('span',[_vm._v("items")])])]),_c(VCardText,{staticClass:"d-flex align-stretch"},[_c(VRow,[(!_vm.offerItems.length)?_c(VCol,{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"small-text"},[_vm._v("No items chosen")])]):_vm._l((_vm.offerItems),function(item,index){return _c('user-offer-item',{key:index,attrs:{"item":item}})})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }