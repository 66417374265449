<template>
    <div class="backdrop" @click="$store.state.shop.aside = false"></div>
</template>

<script>
export default {
    data() {
        return {};
    },
    mounted: function () {
        document.body.style.overflow = 'hidden';
        // document.body.style['touch-action'] = 'none';
    },
    beforeDestroy() {
        document.body.style.overflow = '';
    },
};
</script>

<style>
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
}
body {
    overflow: hidden;
}
</style>
