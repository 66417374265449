<template>
    <div v-if="orders" class="orders-wrapper">
        <vue-good-table
            :columns="columns"
            :rows="orders"
            :pagination="true"
            :search="true"
            :line-numbers="true"
            theme="nocturnal"
            :search-options="{
                enabled: true,
                placeholder: 'Search for an Order',
            }"
            :pagination-options="{
                enabled: true,
                perPage: 10,
            }"
        >
            <div slot="emptystate">You have no Orders yet</div>
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'id'">
                    <div
                        class="order-id"
                        data-tooltip="This is your order id, if you are having issues with your trade, provide this id to our support channels"
                    >
                        {{ props.row.id }}
                    </div>
                </span>
                <span v-if="props.column.field === 'status'">
                    <div class="order-status" :class="statusController(props.row.status).toLowerCase()">
                        {{ statusController(props.row.status) }}
                    </div>
                </span>
                <span v-if="props.column.field === 'deposit'">
                    <div class="exchange-details" style="display: flex; align-items: center; text-align: center">
                        <div
                            data-tooltip="The amount you delivered to Trade-Raid in the deposit"
                            style="display: flex; align-items: center; text-align: center"
                        >
                            {{ abbreviateAmount(props.row.deposit.amount, 'compact', 'short') }}
                            <Coin
                                class="cost mr-1"
                                kind="gold"
                                style="width: 15px; height: auto; margin-right: 10px"
                                :include_logo="false"
                            />
                            <ArrowRightIcon />
                        </div>
                        <div
                            class="game"
                            data
                            tooltip="The game you traded from in the exchange"
                            style="display: flex; align-items: center; text-align: center"
                        >
                            {{ props.row.deposit.game_info.version }}
                            <MMOGameIcon
                                v-if="gameType(props.row.deposit.game_info.version)"
                                class="game-icon-mmo"
                                :game="gameType(props.row.deposit.game_info.version)"
                            />
                        </div>
                        <div
                            class="server"
                            data-tooltip="The server and faction you traded from in the deposit"
                            style="display: flex; align-items: center; text-align: center"
                        >
                            {{ props.row.deposit.game_info.server }}
                        </div>
                        <div
                            class="delivery-method"
                            data-tooltip="The delivery method you used for your deposit"
                            style="display: flex; align-items: center; text-align: center"
                        >
                            {{ props.row.deposit.delivery_method.obj_type }}
                            <ArrowRightIcon />
                        </div>
                        <div
                            class="character-name"
                            data-tooltip="The character name you used in your deposit"
                            style="display: flex; align-items: center; text-align: center"
                        >
                            {{ props.row.deposit.character_name }}
                            <ArrowRightIcon />
                        </div>
                        <div
                            class="receiver-name"
                            data-tooltip="The character you deposited the gold to"
                            style="display: flex; align-items: center; text-align: center"
                        >
                            {{ props.row.deposit.receiver_name }}
                        </div>
                    </div>
                </span>
                <span v-if="props.column.field === 'exchange'">
                    <div class="exchange-details" style="display: flex; align-items: center; text-align: center">
                        <div
                            class="amount"
                            data-tooltip="The amount you received in the exchange"
                            style="display: flex; align-items: center; text-align: center"
                        >
                            {{ abbreviateAmount(props.row.exchange.amount, 'compact', 'short') }}
                            <Coin
                                class="cost mr-1"
                                kind="gold"
                                style="width: 15px; height: auto; margin-right: 10px"
                                :include_logo="false"
                            />
                            <ArrowRightIcon />
                        </div>
                        <div
                            class="game"
                            data
                            tooltip="The game you traded to in the exchange"
                            style="display: flex; align-items: center; text-align: center"
                        >
                            {{ props.row.exchange.game_info.version }}
                            <MMOGameIcon
                                v-if="gameType(props.row.exchange.game_info.version)"
                                class="game-icon-mmo"
                                :game="gameType(props.row.exchange.game_info.version)"
                            />
                        </div>
                        <div
                            class="server"
                            data-tooltip="The server and faction you traded to in the exchange"
                            style="display: flex; align-items: center; text-align: center"
                        >
                            {{ props.row.exchange.game_info.server }}
                        </div>
                        <div
                            class="delivery-method"
                            data-tooltip="The delivery method you used for your exchange"
                            style="display: flex; align-items: center; text-align: center"
                        >
                            {{ props.row.exchange.delivery_method.obj_type }}
                            <ArrowRightIcon />
                        </div>
                        <div
                            class="character-name"
                            data-tooltip="The character name you used in your exchange"
                            style="display: flex; align-items: center; text-align: center"
                        >
                            {{ props.row.exchange.character_name }}
                            <ArrowRightIcon />
                        </div>
                        <div
                            class="receiver-name"
                            data-tooltip="The character you deposited the gold to"
                            style="display: flex; align-items: center; text-align: center"
                        >
                            {{ props.row.exchange.receiver_name }}
                        </div>
                    </div>
                </span>
                <span v-if="props.column.field == 'created_at'">
                    {{ new Date(props.row.created_at).toLocaleDateString() }}
                    {{
                        new Date(props.row.created_at).toLocaleTimeString(language, {
                            hour: 'numeric',
                            minute: 'numeric',
                        })
                    }}
                </span>
                <span v-if="props.column.field == 'show_more'">
                    <div @click="showMore(props.row)" class="interactive-btn interactive">show more</div>
                </span>
            </template>
        </vue-good-table>
        <v-dialog v-model="modal" width="500" class="custom-modal-config">
            <ExchangeOrderModal :order="current_order" />
        </v-dialog>
    </div>
    <div v-else class="loader">
        <Loader class="load-spinner" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { orderHistory } from '../../api/auth';
import { ArrowRightIcon, CornerDownRightIcon, SearchIcon } from 'vue-feather-icons';
import Coin from '../UI/SVG/coin.vue';
import Loader from '../UI/loader.vue';
import MMOGameIcon from '../UI/GameIcons/mmo-icons.vue';
import { MMOGameAlias, OrderStatusController, OrderabbreviateAmount } from '../../utils/orderService.js';
import ExchangeOrderModal from './exchangeOrderModal.vue';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
export default {
    name: 'ExchangeOrders',
    props: {},
    components: {
        Loader,
        SearchIcon,
        Coin,
        CornerDownRightIcon,
        MMOGameIcon,
        ArrowRightIcon,
        ExchangeOrderModal,
        VueGoodTable,
    },
    data() {
        return {
            type: 'ts',
            orders: null,
            columns: [
                {
                    label: 'Order ID',
                    field: 'id',
                },
                {
                    label: 'Status',
                    field: 'status',
                },
                {
                    label: 'Deposit Details',
                    field: 'deposit',
                    width: '200px',
                    tdClass: 'text-center',
                },
                {
                    label: 'Exchange Details',
                    field: 'exchange',
                    width: '200px',
                    tdClass: 'text-center',
                },
                {
                    label: 'Date',
                    field: 'created_at',
                    type: 'date',
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
                    dateOutputFormat: 'yyyy-MM-dd HH:mm:ss', // outputs 2023-01-02 16:25:40
                },
                {
                    label: '',
                    field: 'show_more',
                },
            ],
            searchQuery: '',
            language: null,
            modal: false,
            current_order: null,
            modal: false,
            no_orders: null,
        };
    },
    methods: {
        filteredList() {
            return this.orders.filter((x) => {
                const string = JSON.stringify(x.id);
                return string.includes(this.searchQuery);
            });
        },
        statusController(status) {
            return OrderStatusController(status);
        },
        showMore(order) {
            this.current_order = order;
            this.modal = true;
        },
        gameType(game) {
            return MMOGameAlias(game);
        },
        abbreviateAmount(number, notation, compactDisplay) {
            return OrderabbreviateAmount(number, notation, compactDisplay);
        },
    },
    computed: {
        ...mapGetters(['user']),
    },
    mounted: async function () {
        this.language = navigator.language;
        this.orders = (await orderHistory('es', this.user._id, this.user.access_token)).data.data;
    },
    watch: {
        user: {
            async handler() {
                this.orders = (await orderHistory('es', this.user._id, this.user.access_token)).data.data;
            },
            immediate: true,
        },
    },
};
</script>

<style scoped>
.orders-wrapper {
    width: 100%;
}

.table-header {
    color: hsla(0, 0%, 100%, 0.3);
    display: flex;
    width: auto;
}
.order-id-header {
    min-width: 7.69%;
}
.order-status-header {
    min-width: 6.42%;
}
.order-deposit-details-header {
    min-width: 39.69%;
}
.order-exchange-header {
    min-width: 32.69%;
}
.order-created-at-header {
}

.search-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    border: 2px solid hsla(0, 0%, 100%, 0.3);
    padding: 5px 5px 5px 5px;
    width: 35vmin;
    border-radius: 5px;
}

.deposit-details,
.exchange-details {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-start;
    align-content: center;
}
.deposit-details {
    min-width: 600px;
}

.exchange-details {
    min-width: 500px;
}
.deposit-details > div {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.exchange-details > div {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.exchange-details > div:hover,
.deposit-details > div:hover {
    font-size: bold;
    color: #17d0c1;
}

.search {
    color: #f1f1f1;
    font-size: 1rem;
    padding: 5px 10px 5px 10px;
}
input:focus,
textarea:focus,
select:focus {
    outline: none;
}

.search-icon {
    color: hsla(0, 0%, 100%, 0.3);
    min-width: 25px;
}
.loader {
    margin: auto;
    background-color: #383f51;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.order-row {
    display: flex;
    background-color: hsla(0, 0%, 100%, 0.3);
    color: #f1f1f1;
    align-items: flex-end;
    font-size: 0.9rem;
    margin: 10px 0;
    border-radius: 5px;
    justify-content: flex-start;
}

.order-row > td > div {
    display: flex;
}

th,
td {
    text-align: left;
    padding: 15px;
    justify-content: center;
}
td {
    display: flex;
}
.order-status {
    padding: 5px;
    border-radius: 5px;
    min-width: 85px;
    display: flex;
    justify-content: center;
    font-size: 0.7rem;
}
.id-col {
    min-width: 115px;
}
.order-col {
    min-width: 100px;
}
.expand-order {
    margin-left: auto !important;
}
.accepted {
    color: #79c175;
    background-color: #3d4442;
}
.pending {
    color: #fad303;
    background-color: #a59233;
    height: 50%;
}
.declined {
    color: #dc6e6f;
    background-color: #53484e;
}

.escrow {
    color: #5a96c7;
    background-color: #3357a5;
}
.interactive {
    color: #17d0c1;
    cursor: pointer;
}
.interactive:hover {
    border-radius: 5px;
}

[data-tooltip]:before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;

    /* customizable */
    transition: all 0.15s ease;
    padding: 10px;
    color: #f1f1f1;
    border-radius: 5px;
}
[data-tooltip]:hover:before {
    opacity: 1;
    background-color: #20283c;
    margin-top: -100px;
    margin-left: 0px;
}

[data-tooltip]:not([data-tooltip-persistent]):before {
    pointer-events: none;
}

@media screen and (max-width: 1903px) {
    .deposit-details,
    .exchange-details,
    .order-deposit-details-header,
    .order-exchange-header {
        display: none;
    }
    .order-id-header {
        min-width: 12.79%;
    }
    .order-status-header {
        min-width: 12.22%;
    }

    .order-created_at {
        min-width: 200px;
    }
    .order-status-header {
        min-width: 12.22%;
    }
}
@media screen and (max-width: 640px) {
    .order-created_at {
        min-width: 150px;
    }
}
@media screen and (max-width: 1255px) {
    .order-id-header {
        min-width: 17.79%;
    }
    .order-status-header {
        min-width: 23%;
    }

    .order-created_at {
        min-width: 200px;
    }
}

@media screen and (max-width: 536px) {
    .order-created_at {
        min-width: 100px;
    }
}
</style>
