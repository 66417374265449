<template>
    <div class="product-modal-gold" v-if="current_product.type === 'gold'">
        <div class="product-modal-info" v-if="!this.localLoad">
            <div class="title mt-5 mb-5 flex-inline">
                <MMOGameIcon v-if="game" class="game-icon-mmo" :game="abbreviateGame(current_product.game)" />
                {{ current_product.title }}
                <tradeProtection v-if="current_product.tradeProtect" class="protect-container" />
            </div>
            <div class="description mb-5 sub-text text-16">
                {{ current_product.description_short }}
            </div>
            <div class="flex-inline margin-bottom-15">
                <v-tooltip v-for="(label, index) in labels" :key="index" bottom>
                    <template #activator="{ on }">
                        <div
                            :style="`backgroundColor: ${label.backgroundColor}`"
                            class="popularity-label cursor"
                            @click="setServerFilter(label.tag)"
                            v-on="on"
                        >
                            {{ label.tag }}
                        </div>
                    </template>
                    <span>{{ label.desc }}</span>
                </v-tooltip>
            </div>
            <v-form ref="form">
                <div class="select-gold-info">
                    <div class="form-group">
                        <label for=""
                            >Region
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        v-on="on"
                                        v-bind="attrs"
                                        src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                        alt="info icon"
                                        style="margin-left: 7px"
                                    />
                                </template>
                                <span>Please mention the region you want to receive gold on.</span>
                            </v-tooltip>
                        </label>
                        <v-select
                            v-model="region"
                            :items="current_product.regions"
                            placeholder="Choose your region"
                            :rules="[rules.required]"
                            outlined
                            height="36px"
                            dense
                            hide-details
                            :append-icon="'mdi-chevron-down'"
                            :menu-props="{ offsetY: true }"
                        >
                        </v-select>
                    </div>
                    <div class="form-group">
                        <label for="">
                            Server
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        v-on="on"
                                        v-bind="attrs"
                                        src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                        alt="info icon"
                                        style="margin-left: 7px"
                                    />
                                </template>
                                <span>Please mention the server you want to receive gold on.</span>
                            </v-tooltip>
                        </label>
                        <v-select
                            v-model="server"
                            :items="gold_service.servers"
                            placeholder="Choose a server"
                            :rules="[rules.required]"
                            outlined
                            height="36px"
                            width="60px"
                            dense
                            hide-details
                            :append-icon="'mdi-chevron-down'"
                            :menu-props="{ offsetY: true }"
                        >
                            <template v-slot:item="data">
                                <div class="server-label flex-inline">
                                    <span style="color: #f1f1f1">{{ data.item }}</span>
                                    <div
                                        :class="getCurrentPopularityLabel(data.item)"
                                        :style="`backgroundColor: ${getLabelColor(data.item)}`"
                                        class="popularity-label margin-auto"
                                    >
                                        {{ getCurrentPopularityLabel(data.item) }}
                                    </div>
                                </div>
                            </template>
                            <template v-slot:selection="data">
                                <div class="server-label">
                                    <span>{{ server }}</span>
                                    <div
                                        v-if="currentPopularityLabel"
                                        :class="currentPopularityLabel.toLowerCase()"
                                        :style="`backgroundColor: ${getLabelColor(server)}`"
                                        class="popularity-label margin-auto"
                                    >
                                        {{ currentPopularityLabel }}
                                    </div>
                                </div>
                            </template>
                        </v-select>
                    </div>
                    <div class="form-group">
                        <label for="">
                            Character Name
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        v-on="on"
                                        v-bind="attrs"
                                        src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                        alt="info icon"
                                        style="margin-left: 7px"
                                    />
                                </template>
                                <span>Please mention the character name of the gold receiver</span>
                            </v-tooltip>
                        </label>
                        <v-text-field
                            v-model="character_name"
                            placeholder="Character Name"
                            :rules="[rules.required]"
                            outlined
                            height="36px"
                            dense
                            hide-details
                        ></v-text-field>
                    </div>
                    <div class="form-group">
                        <label for=""
                            >Delivery Method
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        v-on="on"
                                        v-bind="attrs"
                                        src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                        alt="info icon"
                                        style="margin-left: 7px"
                                    />
                                </template>
                                <span>Please choose your delivery method</span>
                            </v-tooltip>
                        </label>
                        <v-select
                            v-if="current_product.game === 'Lost Ark' || current_product.game === 'Final Fantasy XIV'"
                            v-model="delivery_method"
                            :items="gold_service.mail_only"
                            placeholder="Delivery Method"
                            :rules="[rules.required]"
                            outlined
                            height="36px"
                            dense
                            hide-details
                            :append-icon="'mdi-chevron-down'"
                            :menu-props="{ offsetY: true }"
                        >
                        </v-select>
                        <v-select
                            v-else
                            v-model="delivery_method"
                            :items="gold_service.delivery_options"
                            placeholder="Delivery Method"
                            :rules="[rules.required]"
                            outlined
                            height="36px"
                            dense
                            hide-details
                            :append-icon="'mdi-chevron-down'"
                            :menu-props="{ offsetY: true }"
                        >
                        </v-select>
                    </div>
                    <div class="form-group" v-if="delivery_method === 'Auction House'">
                        <label for="">
                            Battle Pet Name/BOE Item
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        v-on="on"
                                        v-bind="attrs"
                                        src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                        alt="info icon"
                                        style="margin-left: 7px"
                                    />
                                </template>
                                <span
                                    >Write the name of the Battle Pet or BOE Item (WOTLK Classic) that you will list on
                                    the Auction House with the price of the agreed gold sum</span
                                >
                            </v-tooltip>
                        </label>
                        <v-text-field
                            v-model="delivery_item"
                            placeholder="Battle Pet Name/BOE Item"
                            :rules="[rules.required]"
                            outlined
                            height="36px"
                            dense
                            hide-details
                        ></v-text-field>
                    </div>
                </div>
            </v-form>

            <div class="gold-range">
                <div class="custom-form flex-block">
                    <div class="title" for="">Amount of Gold you wish to receive</div>
                    <span class="unit-price flex-inline"> </span>
                    <div class="sub-text title-grey mt-2">
                        Use the input field or slider to select how much gold you want to receive in your order.
                    </div>
                    <div class="title gold-receive-render flex-inline">
                        {{ gold_receive }}
                        <Coin class="cost mr-1" kind="gold" style="width: 15px; height: auto" :include_logo="false" />
                    </div>
                    <input class="custom-input" v-model="gold_receive" type="text" />
                    <input
                        class="mb-5 range-slider"
                        type="range"
                        v-model="gold_receive"
                        :max="gold_service.max_trade_amount"
                        :min="gold_service.min_trade_amount"
                        @change="priceCalculation()"
                    />
                </div>
            </div>
            <div class="price mb-2">
                <div class="title-grey mb-2">Order Details</div>
                {{ $store.state.shop.activeCurrency }} {{ calculateGoldPrice }}
                <div class="sub-text title-grey mt-2 flex-inline">
                    {{ gold_receive }}
                    {{ current_product.title }}
                    <Coin class="cost mr-1" kind="gold" style="width: 15px; height: auto" :include_logo="false" />
                </div>
            </div>
            <div class="action-buttons">
                <div @click="addToCart()">
                    <AddToCartButton />
                </div>

                <!-- <div class="custom-button dark-grey-bg flex-row flex-inline flex-center">
                    <div class="icon-wrap">
                        <MessageCircleIcon />
                    </div>
                    <span class="min-width-65 flex-row flex-inline flex-center">Chat</span>
                </div> -->
            </div>
        </div>
        <div class="loader" v-else>
            <Loader />
            <div class="loader-info">Calculating gold prices...</div>
        </div>
    </div>

    <div v-else class="product-modal">
        <div class="product-modal-info">
            <div class="title mt-5 mb-5 flex-inline">
                <MMOGameIcon v-if="game" class="game-icon-mmo" :game="abbreviateGame(game)" />
                {{ current_product.title }}
            </div>
            <div
                v-for="(line, lineNumber) of computeLongDescription(current_product.description_long)"
                v-bind:key="lineNumber"
            >
                {{ line }}<br />
            </div>
            <div class="description_list mb-5 mt-5">
                <div class="title mt-5 mb-5">Requirements</div>
                <li
                    class="list-item-sub-description custom-list-bullets"
                    v-for="(item, index) in current_product.description_list"
                    :key="index"
                >
                    {{ item }}
                </li>
            </div>
            <div class="rewards">
                <div class="title mb-5">What you will get</div>
                <div class="mb-5">
                    <li
                        class="list-item-sub-description custom-list-bullets"
                        v-for="(item, index) in current_product.reward_list"
                        :key="index"
                    >
                        {{ item }}
                    </li>
                </div>
            </div>
        </div>
        <div class="product-modal-aside">
            <div class="sticky">
                <div class="mb-2">
                    <div class="title-grey">Region</div>
                    <div class="flex-inline">
                        <div
                            v-for="(item, index) in current_product.regions"
                            :key="index"
                            @click="setActiveRegion(item)"
                            class="custom-button dark-grey-bg"
                            :class="{ activeBtn: item === active_region }"
                        >
                            {{ item }}
                        </div>
                    </div>
                </div>
                <div class="mb-2">
                    <div class="title-grey">Faction</div>
                    <div class="flex-inline">
                        <div
                            v-for="(item, index) in factions"
                            :key="index"
                            @click="setActiveFaction(item)"
                            class="custom-button dark-grey-bg"
                            :class="{ activeBtn: item === faction }"
                        >
                            {{ item }}
                        </div>
                    </div>
                </div>
                <div class="boost-method mb-2">
                    <div class="title-grey">BOOST METHOD</div>
                    <div class="flex-inline">
                        <div
                            v-for="(item, index) in current_product.boost_method"
                            @click="active_boost_method = item"
                            :key="index"
                            class="custom-button dark-grey-bg"
                            :class="{ activeBtn: item === active_boost_method }"
                        >
                            {{ item }}
                        </div>
                    </div>
                </div>
                <div class="execution-speed mb-2">
                    <div class="title-grey">EXECUTION SPEED</div>
                    <div class="flex-inline">
                        <div
                            v-for="(item, index) in current_product.execution_speed"
                            :key="index"
                            @click="activeExecutionSpeed = item"
                            class="custom-button dark-grey-bg"
                            :class="{ activeBtn: item === activeExecutionSpeed }"
                        >
                            {{ item }}
                        </div>
                    </div>
                </div>

                <div v-if="current_product.custom_options_buttons.length" class="custom-options mb-2">
                    <div class="title-grey">Custom options</div>
                    <div class="flex-block">
                        <div
                            v-for="(item, index) in current_product.custom_options_buttons"
                            :key="index"
                            class="custom-button-slim dark-grey-bg"
                            :class="{ activeBtn: active_options && active_options.includes(index) }"
                            @click="addOption(item, index)"
                        >
                            {{ item.name }} + {{ $store.state.shop.activeCurrency }} {{ item.price_increase }}
                        </div>
                    </div>
                </div>
                <div class="level-slider" v-if="current_product.level_boost_feature">
                    <div class="title-grey">Select Level Range</div>
                    <span>Level: {{ getLevelProximity }}</span>
                    <v-range-slider
                        v-model="level_service.level_requested"
                        strict
                        :step="1"
                        color="#4b90ff"
                        :min="this.level_service.range[0]"
                        :max="this.level_service.range[1]"
                    ></v-range-slider>
                </div>
                <div class="price mb-2">
                    <div class="title-grey mb-2">Order Details</div>
                    {{ $store.state.shop.activeCurrency }} {{ setLevelBoostPrice }}
                    <div class="sub-text title-grey mt-2">1 DAY ORDER COMPLETION</div>
                </div>
                <div class="action-buttons">
                    <div @click="addToCart()" v-if="setLevelBoostPrice > 0 && checkRange">
                        <AddToCartButton />
                    </div>
                    <div v-else class="actions-buttons">
                        <div class="">
                            <AddToCartButton :disabled="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import './utility.styles.css';
import Coin from '../UI/SVG/coin.vue';
import { OrderabbreviateAmount } from '../../utils/orderService.js';
import { MMOGameAlias } from '../../utils/orderService.js';
import { getUnitCost } from '../../api/shop.js';
import { getServerListMmoGold } from '../../api/services.js';
import { toastConfig } from '../../utils/toastConfig.js';
import Loader from '../UI/loader.vue';
import tradeProtection from '../UI/trade-protection.vue';
export default {
    name: 'modal-info',
    props: {
        current_product: Object,
        price: Number,
        type: String,
        game: String,
    },
    components: {
        Coin,
        Loader,
        tradeProtection,
    },
    data() {
        return {
            rules: {
                required: (value) => !!value || 'Is required!',
            },
            currentPopularityLabel: 'Standard',
            localLoad: false,
            labels: [
                {
                    tag: 'Sale',
                    desc: 'Get tons of gold - we have excess gold on these servers and we are having a sale!',
                    backgroundColor: 'rgb(150, 212, 115)',
                },
                {
                    tag: 'Popular',
                    desc: 'Receive more gold on popular servers to a solid price.',
                    backgroundColor: '#5c2cc6',
                },
                {
                    tag: 'High Demand',
                    desc: 'These mega-servers are in high demand which affects the price.',
                    backgroundColor: '#dc6e6f',
                },
                {
                    tag: 'Standard',
                    desc: 'Receive gold in games or servers to a fair price.',
                    backgroundColor: '#007bff',
                },
            ],
            totalPrice: 0,
            productsWithTradeGuarantee: ['63ea0ca1fd21c2dde65584c0', '63ea0ca2fd21c2dde65584d8'],
            server: '',
            character_name: '',
            faction: 'Alliance',
            factions: ['Alliance', 'Horde'],
            region: 'EU',
            delivery_method: '',
            delivery_item: null,
            serverList: null,
            serverListFilter: 'All',
            activeProductFilter: 'All',
            typeFilters: null,
            clickedAddToCartTimeout: false,
            active_region: 'EU',
            active_boost_method: 'Self-play',
            activeExecutionSpeed: 'Normal',
            customOptions: [],
            active_options: [],
            games_with_faction: ['The War Within', 'WOTLK Classic', 'Classic Era'],
            errors: {
                character_name: false,
                server: false,
            },
            gold_receive: 0,
            level_service: {
                min: 10,
                max: 70,
                level_requested: [60, 70],
                // upper bound range for DF level boosts
                range: [10, 70],
                tick: 1,
            },
            gold_service: {
                delivery_options: ['Auction House', 'Mail'],
                mail_only: ['Mail'],
                servers: [],
                min_trade_amount: 0,
                max_trade_amount: 0,
                unit_price: 0,
            },
        };
    },
    computed: {
        calculateGoldPrice() {
            return Math.floor(parseInt(this.gold_receive) * this.gold_service.unit_price * this.serverMargin());
        },
        abbreviateGoldReceiveAmount() {
            return OrderabbreviateAmount(this.gold_receive, 'compact', 'short');
        },
        computeUnitPrice() {
            return this.gold_service.unit_price * this.serverMargin();
        },
        checkRange() {
            return this.level_service.level_requested[1] - this.level_service.level_requested[0] > 0;
        },
        setLevelBoostPrice() {
            if (this.current_product.level_boost_feature) {
                let base_price = this.current_product.price;
                const min = this.level_service.level_requested[0];
                const max = this.level_service.level_requested[1];
                const maxRange = this.level_service.range[1] - this.level_service.range[0];
                const reduct = maxRange - (max - min);
                let iterator = 0;
                while (iterator < reduct) {
                    base_price -= this.current_product.level_boost_feature.price_reduction_per_level;
                    iterator++;
                }
                return base_price.toFixed(2);
            } else {
                return this.current_product.price;
            }
        },
        setLevelBoostPricePercentageBased() {
            const base_price = this.current_product.price;
            const min = this.level_service.level_requested[0];
            const max = this.level_service.level_requested[1];
            const yield_percentage_level =
                ((max - min) * 100) / (this.level_service.range[1] - this.level_service.range[0]);
            const yield_price = Math.ceil((yield_percentage_level * base_price) / 100);
            return yield_price;
        },
        getLevelProximity() {
            const from_level = this.level_service.level_requested[0];
            const to_level = this.level_service.level_requested[1];
            return `${from_level} - ${to_level}`;
        },
    },
    watch: {
        game: function () {
            this.getActiveGame();
            this.setInfo();
        },
        region: function () {
            this.setInfo();
        },
    },
    mounted: async function () {
        this.totalPrice = this.price;
        this.setInfo();
        this.getActiveGame();
    },
    methods: {
        getCurrentPopularityLabel(server) {
            let orignal_server = this.getOriginalServer(server, true);
            const s = this.serverList.filter((s) => s.display_title === orignal_server)[0];

            return s.label.toLowerCase();
        },
        getLabelColor(server) {
            let orignal_server = this.getOriginalServer(server, true);
            const s = this.serverList.filter((s) => s.display_title === orignal_server)[0];
            const getEntry = this.labels.filter((x) => {
                if (x.tag.toLowerCase() === s.label.toLowerCase()) {
                    return x;
                }
            });

            return getEntry[0] && getEntry[0].backgroundColor ? getEntry[0].backgroundColor : '';
        },
        serverMargin() {
            if (this.serverList) {
                let serverInfo = this.getOriginalServer(this.server, true);
                const server = this.serverList.filter((s) => s.display_title === serverInfo)[0];
                const get_standard_servers = this.serverList.filter((x) => x.label === 'Standard');
                const sub_margin = Math.max(...get_standard_servers.map((x) => x.shop.margin));
                if (server && server.shop && server.shop.margin) {
                    if (server.sub_label) {
                        this.currentPopularityLabel = server.sub_label;
                        return sub_margin;
                    } else {
                        this.currentPopularityLabel = server.label;
                        return server.shop.margin;
                    }
                }
            }

            return 1;
        },
        async setUnitCost() {
            const getUnitPrice = await getUnitCost(this.game);
            if (this.game !== 'All') {
                this.gold_service.unit_price = getUnitPrice.data.cost;
                this.gold_service.min_trade_amount = getUnitPrice.data.min_trade_amount;
                this.gold_service.max_trade_amount = getUnitPrice.data.max_trade_amount;
                this.gold_receive = this.gold_service.min_trade_amount;
            } else {
                let g = this.getUnitCostGame(this.current_product.game.toLowerCase());
                this.gold_service.unit_price = getUnitPrice.data[g].cost;
                this.gold_service.min_trade_amount = getUnitPrice.data[g].min_trade_amount;
                this.gold_service.max_trade_amount = getUnitPrice.data[g].max_trade_amount;
                this.gold_receive = this.gold_service.min_trade_amount;
            }
        },
        getOriginalServer(server, display) {
            if (!this.games_with_faction.includes(this.current_product.game)) {
                return server;
            }
            let s = server.split(' - ');
            if (display) {
                return s[0];
            }
            return s;
        },
        async setServerList() {
            let g = this.getActiveGame(this.game);
            if (this.game === 'All') {
                g = this.current_product.game;
            }

            this.serverList = (await getServerListMmoGold(this.region, g)).data;
            const sales = [];
            const standard = [];
            const high_demand = [];
            const popular = [];
            let servers = [];
            if (!this.games_with_faction.includes(this.current_product.game)) {
                this.gold_service.servers = this.serverList.map((x, idx) => {
                    const label = x.label.toLowerCase();
                    if (label === 'sale') {
                        sales.push(`${x.display_title}`);
                    }
                    if (label === 'standard') {
                        standard.push(`${x.display_title}`);
                    }

                    if (label === 'high demand') {
                        high_demand.push(`${x.display_title}`);
                    }

                    if (label === 'popular') {
                        popular.push(`${x.display_title}`);
                    }
                });
            } else {
                // set serverlist Server - Faction for games with faction
                this.serverList.map((x, idx) => {
                    const label = x.label.toLowerCase();
                    if (label === 'sale') {
                        for (const faction of this.factions) {
                            sales.push(`${x.display_title} - ${faction}`);
                        }
                    }
                    if (label === 'standard') {
                        for (const faction of this.factions) {
                            standard.push(`${x.display_title} - ${faction}`);
                        }
                    }

                    if (label === 'high demand') {
                        for (const faction of this.factions) {
                            high_demand.push(`${x.display_title} - ${faction}`);
                        }
                    }

                    if (label === 'popular') {
                        for (const faction of this.factions) {
                            popular.push(`${x.display_title} - ${faction}`);
                        }
                    }
                });
            }

            servers.unshift(...standard);
            servers.unshift(...high_demand);
            servers.unshift(...popular);
            servers.unshift(...sales);
            this.gold_service.servers = servers;
            this.server = this.gold_service.servers[0];
        },
        async setInfo() {
            this.localLoad = true;
            await this.setServerList();
            await this.setUnitCost();
            this.localLoad = false;
        },
        getUnitCostGame(key) {
            const unitCostGameKeys = {
                'the war within': 'retail',
                'final fantasy xiv': 'final fantasy xiv',
                'lost ark': 'lost ark',
                'classic era': 'classic era',
                'wotlk classic': 'wotlk',
            };
            return unitCostGameKeys[key];
        },
        getActiveGame() {
            const game_list = {
                'The War Within': 'the war within',
                'WOTLK Classic': 'WOTLK Classic',
                'Final Fantasy XIV': 'Final Fantasy XIV',
                'Classic Era': 'classic season of mastery',
                'Lost Ark': 'lost ark',
            };
            let game = this.game;
            const active_game = game_list[game];
            return active_game;
        },
        addToCart() {
            let price = 0;
            let info = {};
            if (this.current_product.type === 'gold') {
                price = Math.floor(parseInt(this.gold_receive) * this.gold_service.unit_price * this.serverMargin());

                // [server, faction]
                let serverInfo = [this.server, null];
                if (this.games_with_faction.includes(this.current_product.game)) {
                    serverInfo = this.getOriginalServer(this.server, false);
                }

                info = {
                    gold_receive: this.gold_receive,
                    margin: this.serverMargin(),
                    server: serverInfo[0],
                    faction: serverInfo[1],
                    region: this.region,
                    delivery_info: {
                        type: this.delivery_method,
                        character_name: this.character_name,
                        item: this.delivery_item,
                    },
                };
            } else {
                // type boosts
                price = this.setLevelBoostPrice;
                const options = [];

                if (this.customOptions.length) {
                    this.customOptions.filter((x, idx) => {
                        if (this.active_options.includes(idx)) {
                            options.push(x.item);
                        }
                    });
                }

                info = {
                    region: this.active_region,
                    boost_method: this.active_boost_method,
                    speed: this.activeExecutionSpeed,
                    options: options,
                    faction: this.faction,
                    level_range: this.level_service.level_requested,
                    level_proximity: this.getLevelProximity,
                };
            }

            const item = {
                _id: this.current_product._id,
                delivery_time:
                    this.current_product && this.current_product.delivery_time_hours
                        ? this.current_product.delivery_time_hours
                        : 48,
                price: price,
                title: this.current_product.title,
                type: this.current_product.type,
                backgroundImage: this.current_product.backgroundImage,
                game: this.current_product.game,
                info,
            };

            // add boost type products to cart
            const toastInit = (msg) => toastConfig(msg, 5000);
            const validate = this.validateOrder();
            if (validate) {
                this.$store.dispatch('addItemToCart', item);
                const t = toastInit(`Added : ${item.title} to your Cart`);
                this.$toast.success(t.msg, t.options);
                this.$store.commit('SET_KEY_VALUE_PAIR', ['productModalState', false]);
            } else {
                const t = toastInit('An error occured when adding this product to your cart.');
                this.$toast.error(t.msg, t.options);
            }
        },
        validateOrder() {
            // stupid but v-form is not rendered on this type, and validation is different on this type of product
            if (this.current_product.type !== 'gold') {
                return true;
            }
            if (this.$refs.form.validate() && this.current_product.type === 'gold') {
                return true;
            }
        },
        addOption(item, index) {
            let product_price = this.current_product.price;
            if (!this.active_options.includes(index)) {
                this.active_options.push(index);
                this.customOptions.push({ item, idx: index });
            } else {
                this.active_options.unshift(index);
                this.active_options = this.active_options.filter((x) => x !== index);
                this.customOptions = this.customOptions.filter((x) => x.idx !== index);
            }

            let additionalPriceFromOptions = 0;
            for (const option of this.customOptions) {
                additionalPriceFromOptions += parseInt(option.item.price_increase, 10);
            }

            if (additionalPriceFromOptions) {
                product_price += parseInt(additionalPriceFromOptions, 10);
            }

            this.totalPrice = product_price;
        },
        setActiveRegion(item) {
            this.active_region = item;
            // update server-list
            if (this.current_product.type === 'gold') {
                this.gold_service.servers = this.serverList[this.getActiveGame()][this.active_region];
            }
        },
        setActiveFaction(item) {
            this.faction = item;
        },
        abbreviateGame() {
            let g = this.game.toLowerCase();
            if (g === 'all') {
                g = this.current_product.game;
            }
            const game = MMOGameAlias(g);
            return game;
        },
        computeLongDescription(desc) {
            const description = desc.split('\\n');
            return description;
        },
    },
};
</script>

<style scoped lang="scss">
/** v-select dropdown toggle color */
.v-list-item__title {
    color: var(--white) !important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: var(--white) !important;
}
.loader {
    min-height: 50vh;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

// popularity labels
.popularity-label {
    font-size: 10px;
    max-width: 65px;
    min-width: 65px;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    color: #e8e5ec;
}

.standard {
    background-color: #007bff;
}
.popular {
    background-color: #5c2cc6;
}
.high-demand {
    background-color: #d17f33;
}
.sale {
    background-color: rgb(150, 212, 115);
}

.server-label {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 15px;
    font-size: 15px;
    width: 100%;
}

.server-label > div {
    margin-left: 0 auto;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.loader-info {
    margin-top: 150px;
    color: #f1f1f1;
    font-weight: bold;
}
.product-modal-container {
    color: #f1f1f1 !important;
    overflow-y: hidden !important;
}

.product-modal-info {
    color: #f1f1f1 !important;
    padding-block: 15px;
    padding-inline: 15px;
}
.product-modal-aside {
    background-color: #20283c;
    border-radius: 5px;
    color: #f1f1f1 !important;
    min-width: 250px;
    padding-block: 15px;
    padding-inline: 15px;
    max-height: 590px;
    margin-left: auto;
}

.protect-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}
.theme--light.v-select .v-select__selection {
    color: white !important;
}
.price {
    font-size: 1.3rem;
    font-weight: bold;
}
.action-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.v-select__selection--comma {
    color: #fff !important;
}
.collapse-container {
    width: 100%;

    .v-expansion-panel {
        border-radius: 2px;
        background: #383f51 !important;
        margin-top: 0;
        margin-bottom: 20px;
    }
    .v-expansion-panel-header {
        font-size: 18px;
        line-height: 24px;
        color: #fff !important;
        &::v-deep .v-expansion-panel-header__icon i {
            color: #fff !important;
        }
        &.v-expansion-panel-header--active,
        &.v-expansion-panel-header--active::v-deep .v-expansion-panel-header__icon i {
            color: #fff !important;
        }
    }
    .v-expansion-panel-content {
        padding-left: 30px;
        padding-right: 30px;
        font-size: 16px;
        line-height: 30px;
        width: 300px;
        color: rgba(255, 255, 255, 1);
        div {
            padding-top: 0;
            padding-bottom: 25px;
        }
    }
    @media (min-width: 500px) {
        .v-expansion-panel-content {
            max-width: 300px;
        }
    }
    .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
        padding: 0;
        font-size: 16px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.7);
    }
}
@media (max-width: 500px) {
    .product-modal-container {
        overflow: hidden !important;
    }
    .collapse-container {
        max-width: 300px;
    }
}

.v-text-field {
    color: #fff !important;
    input {
        color: #fff !important;
    }
}
.v-select__selections {
    color: #f1f1f1 !important;
    width: 100%;
}
.v-input__control {
    color: #f1f1f1 !important;
}
.form-group {
    max-width: 280px;
    min-width: 280px;
    margin-bottom: 20px;
    .v-input {
        background-color: var(--color-dark-grey) !important;
        // border ::active
        color: white;
    }
    label {
        //
    }
    .v-input__append-inner {
        background-color: var(--color-dark-grey) !important;
        color: #f1f1f1 !important;
    }
}
.cursor {
    cursor: pointer;
}

.v-input__control {
    display: flex;
    flex-direction: column;
    height: auto;
    flex-grow: 1;
    flex-wrap: wrap;
    min-width: 0;
    width: 100%;
}

// dropdown styles for v-select
.v-menu__content .v-list {
    background: #383f51;
}

.select-gold-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
}

.gold-range {
    width: 100%;
}
.range-slider {
    background-color: var(--color-dark-grey);
    border-radius: 5px;
    width: 30vw;
}
.level-range-slider {
    background-color: var(--color-dark-grey);
    border-radius: 5px;
    // width: 30vw;
    width: 100%;
}
.v-list-item__title {
    color: var(--white);
}
.custom-input {
    color: #f1f1f1 !important;
    padding-inline: 5px;
    padding-block: 2px;
    border-radius: 5px;
    max-width: 150px;
    background-color: var(--color-dark-grey);
}
.custom-input:focus {
    border: 1px solid var(--blue);
}
</style>
