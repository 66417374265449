import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VOverlay,{staticClass:"custom-overlay",attrs:{"value":_vm.visible},on:{"click":_vm.handleBackgroundClick}},[_c(VCard,{staticClass:"custom-card"},[_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_c('span',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" Pay with Skins - only for Gold orders ")])]),_c(VCardText,{staticClass:"custom-card-text"},[_vm._v(" Do you want to pay your MMO Gold with Skins?"),_c('br'),_vm._v(" Our Trade Service is just what you're looking for! The service let's you trade your Skins for MMO Gold, seamlessly."),_c('br'),_vm._v(" It's easy to use: just sign in with Steam, then choose the Skins you want to trade for the amount of MMO Gold you want."),_c('br'),_c('br'),_c('h3',[_vm._v("Video guide to our Trade Service:")]),_c('iframe',{staticClass:"thumbnail",attrs:{"id":"thumbnail_vid","src":"https://youtube.com/embed/oP1DQVRN8po","allowfullscreen":"allowfullscreen","mozallowfullscreen":"mozallowfullscreen","msallowfullscreen":"msallowfullscreen","oallowfullscreen":"oallowfullscreen","webkitallowfullscreen":"webkitallowfullscreen","frameBorder":"0","width":"100%","height":"300px"}}),_c('br'),_c('br'),_vm._v("Would you like to proceed to the Trade Service? ")]),_c(VCardActions,[_c(VBtn,{attrs:{"color":"green"},on:{"click":_vm.proceedToTrade}},[_vm._v("Yes, go to the Trade page")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"red"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Let me pay by card")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }