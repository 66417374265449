<template>
    <div v-if="active" class="announcement">
        <v-alert v-model="dismissed" class="web" :color="color" dark dismissible>
            <span style="color: white">
                <bold class="fat"
                    >{{ icon_start }} {{ text_desktop }}
                    <a v-if="href_link" class="instagram" target="_blank" :href="href_link">{{ link_text }}</a
                    >{{ icon_end }}
                </bold>
            </span>
        </v-alert>
        <v-alert v-model="dismissed" class="mobile" :color="color" dark dismissible>
            <span style="color: white">
                <bold class="fat">
                    {{ icon_start }} {{ text_desktop }}
                    <a v-if="href_link" class="instagram" target="_blank" :href="href_link">{{ link_text }}</a
                    >{{ icon_end }}
                </bold>
            </span>
        </v-alert>
    </div>
</template>

<script>
export default {
    props: {
        banner: Object,
    },
    data() {
        return {
            pages: [
                '/',
                '/services',
                '/faq',
                '/blog',
                '/giveaways',
                '/trade',
                '/exchange',
                '/shop',
                '/about-us',
                '/influencers',
                '/business',
                '/support',
                '/terms',
                '/privacy-policy',
                '/cookie-policy',
            ],
            active: false,
            dismissed: true,
            color: null,
            text_desktop: null,
            text_mobile: null,
            href_link: null,
            link_text: null,
            icon_start: null,
            icon_end: null,
        };
    },
    methods: {
        setBanner(banner) {
            this.color = banner.color;
            this.text_desktop = banner.text_desktop;
            this.text_mobile = banner.text_mobile;
            this.href_link = banner.href_link;
            this.link_text = banner.link_text;
            this.icon_start = banner.icon_start;
            this.icon_end = banner.icon_end;
        },
        watchBanner(currentPath) {
            if (this.banner.pages.includes(currentPath)) {
                this.active = true;
            } else {
                this.active = false;
            }
        },
    },
    mounted: function () {
        this.setBanner(this.banner);
        this.watchBanner(this.$route.path);
        this.$watch(
            () => this.$route.path,
            (to, from) => {
                this.watchBanner(to);
            },
        );
    },
};
</script>

<style scoped lang="scss">
* {
    transition: ease-in-out 0.5s;
}
.v-alert__dismissible {
    background-color: #f1f1f1 !important;
    color: white !important;
}

.close-btn {
    right: 0;
}
.instagram {
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.mobile {
    display: none;
}

.announcement {
    width: 100%;
    max-height: 150px;
    margin: 0;
    margin-top: 97px;
    overflow: hidden;
    z-index: 1001;

    position: fixed;

    text-align: center;
    justify-content: center;
    color: #f1f1f1;
}

@media screen and (max-width: 600px) {
    .web {
        display: none;
    }
    .mobile {
        display: block;
        max-height: 115px;
    }
}
.fat {
    font-weight: bold;
    color: #fff;
}
</style>
<style lang="scss">
.theme--light.v-application {
    background-color: transparent;
    background: transparent !important;
}

.bold {
    font-weight: bold;
    margin: 0 !important;
    padding: 0;
}
</style>
<!-- https://vuetifyjs.com/en/components/alerts/
^Documentation for the 'v-alert' component/ -->
