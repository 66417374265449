<template>
    <v-overlay :value="visible" class="custom-overlay" @click="handleBackgroundClick">
        <v-card class="custom-card">
            <div @click.stop>
                <v-toolbar color="primary" dark
                    ><span class="text-h5 font-weight-bold"> Pay with Skins - only for Gold orders </span>
                </v-toolbar>
                <v-card-text class="custom-card-text">
                    Do you want to pay your MMO Gold with Skins?<br />
                    Our Trade Service is just what you're looking for! The service let's you trade your Skins for MMO
                    Gold, seamlessly.<br />
                    It's easy to use: just sign in with Steam, then choose the Skins you want to trade for the amount of
                    MMO Gold you want.<br /><br />
                    <h3>Video guide to our Trade Service:</h3>
                    <iframe
                        class="thumbnail"
                        id="thumbnail_vid"
                        src="https://youtube.com/embed/oP1DQVRN8po"
                        allowfullscreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen"
                        msallowfullscreen="msallowfullscreen"
                        oallowfullscreen="oallowfullscreen"
                        webkitallowfullscreen="webkitallowfullscreen"
                        frameBorder="0"
                        width="100%"
                        height="300px"
                    >
                    </iframe>
                    <br /><br />Would you like to proceed to the Trade Service?
                </v-card-text>
                <v-card-actions>
                    <v-btn color="green" @click="proceedToTrade">Yes, go to the Trade page</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red" @click="$emit('close')">Let me pay by card</v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-overlay>
</template>

<script>
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleBackgroundClick() {
            this.$emit('close');
        },
        proceedToTrade() {
            this.$router.push('/trade');
        },
    },
};
</script>

<style scoped>
.custom-overlay .v-overlay__content {
    cursor: pointer;
}

.custom-overlay .v-overlay__content .v-card {
    cursor: default;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-content {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    position: relative;
    max-width: 400px;
    width: 90%;
}

.custom-card-text {
    overflow-wrap: break-word;
    word-wrap: break-word;
    max-width: 100%;
}

.custom-card {
    max-width: 600px;
    background-color: #383f51;
}

h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
}

h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #fff;
}

button.proceed-button,
button.cancel-button {
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    transition: background-color 0.3s;
}

button.proceed-button {
    background-color: #4caf50;
    color: #fff;
    margin-right: 10px;
}

button.proceed-button:hover {
    background-color: #43a047;
}

button.cancel-button {
    background-color: #f44336;
    color: #fff;
}

button.cancel-button:hover {
    background-color: #e53935;
}
</style>
