<template>
    <div v-if="enabled" class="extra_gold_tab_orange" :style="{ color: tc, backgroundColor: bc }">
        <div :class="`${'e-gold-text'}`">{{ p }}</div>
    </div>
</template>

<script>
export default {
    props: {
        percentage: String,
        type: String,
    },
    data() {
        return {
            p: null,
            enabled: false,
            bc: null,
            tc: null,
        };
    },
    methods: {
        getData() {},
    },
    mounted() {
        const doc = this.$store.state.trade.eventBadges;

        const game = this.$store.state.trade.wow_version.toLowerCase();

        this.enabled = doc[game].active;

        if (this.enabled) {
            const s = doc[game].percentage.toString();

            const split = s.split('.');

            this.p = doc[game].text;

            this.tc = doc.text_color;

            this.bc = doc.background_color;

            if (split.length < 1) {
                this.enabled = false;
            }
        }
    },
};
</script>

<style scoped>
.extra_gold_tab_blue {
    width: 40px;
    height: auto;
    font-size: 10px;
    color: rgb(130, 172, 130);
    background: rgba(44, 141, 87, 0.63);

    font-weight: bold;
    align-items: center;
    display: inline-flex;
    padding: 4px;
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
    border-radius: 4px;
    padding-left: 8px;
    font-family: Apercu Pro, system-ui;
}

.extra_gold_tab_orange {
    height: auto;
    font-size: 10px;
    color: #fafafa;
    /* color: black; */
    /* color: #ffc144; */
    /* color: #ec5028; */
    /* background-color: rgba(206, 203, 56, 0.849);
     */
    /* background: rgba(255, 193, 68, 0.1);  */
    /* background-color: #f56f42;
     */
    background-color: #1084b5;
    filter: blur(5);
    font-weight: bold;
    align-items: center;
    display: inline-flex;
    padding: 4px;
    font-size: 11px;
    font-weight: bold;
    line-height: 1;
    border-radius: 4px;
    font-family: Apercu Pro, system-ui;
    justify-content: center;
    align-items: center;
    display: flex;
}
.extra_gold_tab_green {
    width: 40px;
    height: auto;
    font-size: 10px;
    color: #5acd8d;
    background: rgba(90, 205, 141, 0.1);
    font-weight: bold;
    align-items: center;
    display: inline-flex;
    padding: 4px;
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
    border-radius: 4px;
    padding-left: 8px;
    font-family: Apercu Pro, system-ui;
    right: 10px;
    position: absolute;
}
.e-gold-text {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
}
</style>
