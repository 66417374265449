<template>
    <v-container fluid fill-height id="about">
        <h1 class="page-title">Hi Y Combinator</h1>
        <p class="page-sub-title">
            Thanks for checking us out!<br /><br />
            Our website is currently divided into two services: <br />
            1. The Trade service, where you can trade your Steam items to MMO Gold.<br />
            2. The Exchange service, where you can trade your MMO gold across MMO games, servers and regions
            seamlessly<br />
            <br />
            We want to show you the Trade service, as it is our core cross game trading experience. It also mostly
            resembles what we're currently building for Trade-Raid 2.0<br />
            Trade-Raid 2.0 will combine our 2 services and have more trade combinations, fiat payment, trading both ways
            and of course more games to trade from and to.
            <br /><br />
            Join us in helping gamers retain their hard-earned value!
            <br />Thank you.
        </p>
        <!-- Create a button where user can click and will be sent to /trade page -->
        <!-- <v-btn color="#17D0C1" class="signin-btn" @click="goToTrade()">Go to the Trade page</v-btn> -->
        <p style="color: rgba(255, 255, 255, 0.7)">
            Start by clicking the button below to sign in with the provided account
        </p>
        <sign-in ref="signIn"></sign-in>
    </v-container>
</template>

<script>
import SignIn from '../../components/SignIn';

export default {
    name: 'YCombinator',
    metaInfo: {
        title: 'YCombinator',
        meta: [{ charset: 'utf-8' }, { vmid: 'description', name: 'description', content: 'Hi there, YCombinator' }],
    },
    components: {
        SignIn,
    },
    created() {
        this.$refs.signInComponent.$options.created[0].call(this.$refs.signInComponent);
    },
    methods: {
        goToTrade() {
            this.$router.push('/trade');
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
    #about {
        width: 100%;
        height: auto;
    }
}
#about {
    padding-top: calc(80px + 100px);
    padding-bottom: 124px;
    flex-direction: column;
    align-content: center;
    background: linear-gradient(
        90deg,
        rgba(0, 14, 25, 0.1) 0%,
        rgba(15, 24, 45, 0.1) 30.7%,
        rgba(62, 0, 108, 0.1) 60.29%,
        rgba(11, 72, 67, 0.1) 79.69%,
        rgba(15, 24, 45, 0.1) 95.48%
    );
    .page-title {
        width: 100%;
        font-weight: 600;
        font-size: 36px;
        line-height: 49px;
        color: #fff;
        text-align: center;
    }
    .page-sub-title {
        text-align: center;
        width: 100%;
        max-width: 963px;
        font-size: 18px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 70px;
    }
    .about-container {
        width: 100%;
        max-width: 1530px;
        .image-container {
            width: 100%;
            height: 100%;
            max-height: 400px;
            min-height: 400px;
            margin-bottom: 80px;
            background: url('https://trade-raid-com.mo.cloudinary.net/dota.jpeg') no-repeat;
            background-size: cover;
            background-position: 0 -108px;
        }
        .info-container {
            h2 {
                font-weight: 600;
                font-size: 36px;
                line-height: 60px;
                color: #fff;
            }
            .desc {
                font-size: 18px;
                line-height: 35px;
                color: rgba(255, 255, 255, 0.7);
                margin-bottom: 30px;
            }
            a {
                border: 1px solid #ffffff;
                box-sizing: border-box;
                border-radius: 3px;
                font-family: Open Sans;
                font-weight: bold;
                font-size: 13px;
                line-height: 18px;
                text-transform: uppercase;
                color: #ffffff;
                text-decoration: none;
                padding: 13px 30px;
                display: inline-block;
            }
            a + a {
                margin-left: 20px;
            }
        }
    }
}
@media screen and (max-width: 960px) {
    #about .about-container .info-container h2,
    #about .about-container .info-container .desc {
        text-align: center;
    }
}
</style>
