<template>
    <div class="announcement">
        <v-alert class="web" color="#006FE6" dark dismissible>
            <span style="color: white">
                <bold class="fat"
                    >🚨Steam is facing issues with inventory loading for CSGO Items. Between 2 and 10PM CET it's more
                    likely to get errors. DOTA2/TF2/Rust Items are unaffected🤖
                </bold>
            </span>
        </v-alert>

        <v-alert class="mobile" color="#006FE6" dark dismissible>
            <span style="color: white">
                <bold class="fat">
                    🚨Steam is facing issues with inventory loading for CSGO Items. DOTA2/TF2/Rust Items are
                    unaffected🤖
                </bold>
            </span>
        </v-alert>
        <!-- <v-alert class="web" color="#5761FF" dark dismissible>

            <span style="color: white">
                <bold class="fat">
                    🎉 Triple Giveaway - Participate at our
                    <span
                        ><a class="instagram" target="_blank" href="https://www.instagram.com/trade_raid/"
                            >Instagram!</a
                        ></span
                    >
                    +10% on all Trade Service Orders. Ends 27th February </bold
                >-
                <router-link
                    style="color: white; text-decoration: underline; font-weight: bold; margin-left: 2px"
                    to="/trade"
                >
                    Trade Now!
                </router-link>
                🎉
            </span> -->
        <!-- <button class="close-btn" @click="active=false">close</button>
             -->
        <!-- <v-btn color="primary" text @click="active = false"> Close </v-btn> -->
        <!-- <span style="font-weight: bold; margin-right: 4px">❄️ GOLD GIVEAWAY AT TRADE-RAID!</span> -->
        <!-- <p class="bold">⌛24 HOURS LEFT! FINAL GIVEAWAY - THREE PRIZES🏆 +10% Gold on all orders extended to October 26th 🎉</p> -->
        <!-- <a
                    href="https://trade-raid.com/reward"
                    target="_blank"
                    style="color: white; text-decoration: underline; margin-left: 2px"
                    >Join Now!</a
                > -->
        <!-- </v-alert> -->
    </div>
</template>

<script>
export default {
    props: {
        active: Boolean,
    },
    data() {
        return {
            set: false,
            active: true,
        };
    },
};
</script>

<style scoped lang="scss">
// .v-alert__dismissible .v-btn .v-btn--icon .v-btn--round .theme--dark .v-size--small {
//     color: white;
//     fill: white;
//     background-color: white!important;
// }

.v-alert__dismissible {
    background-color: #f1f1f1 !important;
    color: white !important;
}

// .theme--dark.v-icon {
//     background-color: #333;
// }
// .instagram {
//     color: #F6A355;
//     text-decoration: underline;

// }

.close-btn {
    right: 0;
}
.instagram {
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.mobile {
    display: none;
}
.announcement {
    width: 100%;
    max-height: 150px;
    margin: 0;
    margin-top: 97px;
    overflow: hidden;
    z-index: 1001;
    position: fixed;
    text-align: center;
    justify-content: center;
    color: #f1f1f1;
}

@media screen and (max-width: 600px) {
    .web {
        display: none;
    }
    .mobile {
        display: block;
        max-height: 115px;
    }
}
.fat {
    font-weight: bold;
    color: #fff;
}
</style>
<style lang="scss">
.theme--light.v-application {
    background-color: transparent;
    background: transparent !important;
}

.bold {
    font-weight: bold;
    margin: 0 !important;
    padding: 0;
}
</style>
<!-- https://vuetifyjs.com/en/components/alerts/
^Documentation for the 'v-alert' component/ -->
