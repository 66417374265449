<template>
    <div class="text-center" v-if="!userObj.steam_id">
        <v-dialog persistent v-model="dialog" width="500">
            <v-card color="#555C70">
                <v-card-title class="headline modal grey text-white"> Sign in with Steam </v-card-title>
                <v-card-text class="modal text-white">
                    Our Trade Service use Steam to provide the value of your inventory. Please sign in with Steam below
                    to use our Trade Service.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <ConnectSteam />
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { getSteamLink } from '../../api/auth';
import ConnectSteam from '../ConnectSteam';
export default {
    props: {
        dialog: Boolean,
        userObj: Object,
    },
    components: {
        ConnectSteam,
    },
    data() {
        return {
            url: '',
        };
    },
    computed: {},
    methods: {
        async login() {
            await getSteamLink()
                .then((res) => {
                    window.open(res.data.url, '_self');
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async hideModal() {
            try {
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
<style scoped lang="scss">
// .v-dialog .modal.grey {
//     background: #555c70;
// }
// .text-white {
//     color: #fff !important;
// }
// .ext_link {
//     color: #17d0c1;
//     text-decoration: underline;
// }

// .ext_link:hover {
//     color: #17d0c1 !important;
//     opacity: 0.5;
// }
// .v-dialog .modal.text-white {
//     color: #fff;
//     padding: 10px 20px;
//     font-size: 17px;
//     line-height: 35px;
//     font-family: 'Roboto';
// }
// .v-dialog .text-white >>> .v-label {
//     color: #fff;
// }
// .v-dialog .text-white >>> input {
//     color: #fff;
// }
</style>
