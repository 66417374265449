<template>
    <div class="image-container ml-auto">
        <v-tooltip bottom>
            <template #activator="{ on }">
                <img
                    src="https://trade-raid-com.mo.cloudinary.net/trade-protect-icon.png"
                    alt="Trade-Raid guarantee"
                    class="ml-2 cursor-pointer image-link"
                    v-on="on"
                    @click="goToTradeGuarantee"
                />
            </template>
            <span class="custom-tooltip-text"
                >Enjoy convenience and safety with the Trade-Raid Guarantee:
                <li>Price Guarantee</li>
                <li>Delivery Guarantee</li>
                <li>Refund Guarantee</li>
            </span>
        </v-tooltip>
    </div>
</template>
<script>
export default {
    name: 'trade-protection',
    methods: {
        goToTradeGuarantee() {
            window.open('/guarantee', '_blank');
        },
    },
};
</script>
<style lang="scss" scoped>
.image-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    margin-top: 40px;
}
.image-link {
    height: 60px;
    width: auto;
}
.custom-tooltip-text {
    background-color: #000;
    color: #fff;
    font-size: 14px;
}
.v-tooltip__content {
    background-color: #000;
    color: #fff;
    /* add any other styling you want here */
}
</style>
