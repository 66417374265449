import router from './router';
import store from './store';
import { getAccessToken, connectSteam } from './api/auth';
import { getToken, setToken, getClientToken, removeClientToken } from './utils/auth';

const whiteList = [
    '/faq',
    '/trade',
    '/services',
    '/exchange',
    '/trade-success',
    '/trade-offer',
    '/exchange-success',
    '/support',
    '/business',
    '/signup',
    '/privacy-policy',
    '/cookie-policy',
    '/terms',
    '/404',
    '/giveaways',
    '/giveaway',
    '/blog',
    '/posts/:slug',
    '/sitemap.xml',
    '/robots.txt',
    '/ycombinator',
    '/shop',
    '/about-us',
    '/influencers',
];

router.beforeEach(async (to, from, next) => {
    if (to.redirectedFrom === '/home') {
        /** Google has indexed /home as our frontpage in a link so this is a fix to redirect to the actual index page if users hit this route through Google */
        next('/');
    }
    window.scrollTo(0, 0);
    // set page title
    // document.title = getPageTitle(to.meta.title)
    // determine whether the user has logged in
    if (to.fullPath === '/forgot-password' && JSON.stringify(to.query) === '{}') {
        next('/');
    }

    const hasToken = getToken();
    let ttt = false;

    if (hasToken && ttt) {
        const hasGetUserInfo = store.getters.user;
        if (hasGetUserInfo.id || hasGetUserInfo._id) {
            next();
        }
        if (hasGetUserInfo.steam_id) {
            await store.dispatch('getInfo');
            next('/trade');
        }
    } else {
        /* has no token*/
        if (to.query.token) {
            let res = await getAccessToken(to.query.token);
            store.commit('SET_TOKEN', res.data);
            setToken(res.data);
            const clientToken = getClientToken();
            const user = await store.dispatch('getInfo');

            if (clientToken && clientToken !== 'undefined') {
                // SET STEAM PROPERTIES ON USER
                await connectSteam(clientToken, user, res.data);
                await store.dispatch('getInfo');
                // removeClientToken()
            }
            store.commit('SHOW_INFO_TRADE_MODAL', true);

            next('/trade');
        } else {
            next();
        }
        if (whiteList.indexOf(to.path) !== -1) {
            next();
        } else {
            next();
            // next(``);
        }
    }
});

router.afterEach(() => {});
