<template>
    <div class="order-modal">
        <v-card id="pdf" color="#202136">
            <v-card-title class="headline modal grey text-white background-color-gradient"
                >Order: {{ order.id }}
            </v-card-title>
            <v-card-text>
                <div class="product-wrapper">
                    <div class="trade-info-wrap">
                        <div class="order-created_at">
                            <ClockIcon />
                            {{ new Date(order.created_at).toLocaleDateString() }}
                            {{
                                new Date(order.created_at).toLocaleTimeString(language, {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                })
                            }}
                        </div>
                        <div class="order-status" :class="statusController(order.status).toLowerCase()">
                            {{ statusController(order.status) }}
                        </div>
                    </div>

                    <div class="modal-info">
                        <div class="k bold" style="color: #f1f1f1">VAT:</div>
                        <div class="v bold min-width-70" style="color: #f1f1f1">
                            <span>{{ $store.state.shop.activeCurrency }}</span> <span>{{ order.vat }}</span>
                        </div>
                    </div>

                    <div class="modal-info">
                        <div class="k bold" style="color: #f1f1f1">Service Payment Fee:</div>
                        <div class="v bold min-width-70" style="color: #f1f1f1">
                            <span>{{ $store.state.shop.activeCurrency }}</span> <span>{{ order.fee }}</span>
                        </div>
                    </div>

                    <div class="modal-info">
                        <div class="k bold" style="color: #f1f1f1">Sub Total:</div>
                        <div class="v bold min-width-70" style="color: #f1f1f1">
                            <span> {{ $store.state.shop.activeCurrency }}</span>
                            <span>{{ order.total_price_in_cents }}</span>
                        </div>
                    </div>

                    <div class="section-line"></div>

                    <div class="product-detail" v-for="(product, index) in order.client_items" :key="index">
                        <div class="flex-inline">
                            <img class="product-image" v-if="product.backgroundImage" :src="product.backgroundImage" />
                            <div class="title">{{ product.title }}</div>
                        </div>

                        <div class="modal-info">
                            <div class="k bold secondary-color">Delivery Time:</div>
                            <div class="v bold flex-inline">{{ product.delivery_time }} Hours<ClockIcon /></div>
                        </div>

                        <div class="modal-info">
                            <div class="k bold secondary-color">Game:</div>
                            <div class="v bold flex-inline">
                                {{ gameType(product.game) }}
                                <MMOGameIcon
                                    v-if="gameType(product.game)"
                                    class="game-icon-mmo"
                                    :game="gameType(product.game)"
                                    style="margin-right: 5px"
                                />
                            </div>
                        </div>

                        <div class="modal-info">
                            <div class="k bold secondary-color">Product Type</div>
                            <div class="v bold">{{ product.type }}</div>
                        </div>

                        <div class="modal-info">
                            <div class="k bold secondary-color">Price:</div>
                            <div class="v bold">{{ $store.state.shop.activeCurrency }}{{ computePrice(product) }}</div>
                        </div>

                        <div v-if="product.info.options && product.info.options.length" class="modal-info">
                            <div class="k bold secondary-color">Additional Purchase</div>
                            <div class="v">
                                <div v-if="product.info.options" class="custom-option-display flex-inline">
                                    <li
                                        class="tooltip custom-list-bullets"
                                        v-for="(option, idx) in product.info.options"
                                        :key="idx"
                                    >
                                        <span class="bold"> {{ option.name }} </span>
                                        <span class="tooltiptext"
                                            >+ {{ $store.state.shop.activeCurrency }} {{ option.price_increase }}
                                        </span>
                                    </li>
                                </div>
                            </div>
                        </div>

                        <div class="delivery-details">
                            <div class="title-small bold">In game delivery details</div>
                            <div class="modal-info">
                                <div class="k bold secondary-color">Region:</div>
                                <div class="v bold">{{ product.info.region }}</div>
                            </div>

                            <div v-if="product.info.boost_method" class="modal-info">
                                <div class="k bold secondary-color">Boost Method:</div>
                                <div class="v bold">{{ product.info.boost_method }}</div>
                            </div>

                            <div v-if="product.info.speed" class="modal-info">
                                <div class="k bold secondary-color">Boost Speed:</div>
                                <div class="v bold">{{ product.info.speed }}</div>
                            </div>

                            <div v-if="product.info.gold_receive" class="modal-info">
                                <div class="k bold secondary-color">Gold to Receive:</div>
                                <div class="v bold">
                                    {{ abbreviateAmount(product.info.gold_receive, 'compact', 'short') }}
                                    <Coin
                                        class="cost mr-1"
                                        kind="gold"
                                        style="width: 15px; height: auto; margin-right: 10px"
                                        :include_logo="false"
                                    />
                                </div>
                            </div>

                            <div v-if="product.info.server" class="modal-info">
                                <div class="k secondary-color bold">Server/Faction</div>
                                <div class="v bold">{{ product.info.server }}/{{ product.info.faction }}</div>
                            </div>

                            <div v-if="product.info.delivery_info" class="modal-info">
                                <div class="k bold secondary-color">Delivery Character Name</div>
                                <div class="v bold">
                                    {{ product.info.delivery_info.character_name }}
                                </div>
                            </div>

                            <div v-if="product.info.delivery_info" class="modal-info">
                                <div class="k bold secondary-color">Delivery Type:</div>
                                <div class="v bold">{{ product.info.delivery_info.type }}</div>
                            </div>
                            <div
                                v-if="product.info.delivery_info && product.info.delivery_info.item"
                                class="modal-info"
                            >
                                <div class="k bold secondary-color">Delivery Item:</div>
                                <div class="v bold">{{ product.info.delivery_info.item }}</div>
                            </div>
                        </div>
                        <div class="section-line"></div>
                        <!-- <span style="color: white"> {{product}} </span> -->
                    </div>
                    <div v-if="showControls" class="support-section bold">
                        Have questions or need help with your order?
                        <br />
                        <span style="display: flex; align-items: flex-end">
                            Use our on page chat-functionality in the bottom right corner to get help.
                            <ArrowRightIcon class="icon-color-primary"
                        /></span>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import MMOGameIcon from '../UI/GameIcons/mmo-icons.vue';
import Coin from '../UI/SVG/coin.vue';

import { ClockIcon, ArrowRightIcon, DownloadIcon } from 'vue-feather-icons';
import {
    MMOGameAlias,
    OrderStatusController,
    OrderabbreviateAmount,
    getTotalShopOrderPrice,
} from '../../utils/orderService.js';

export default {
    name: 'ShopOrderModal',
    components: {
        ClockIcon,
        MMOGameIcon,
        Coin,
        ArrowRightIcon,
        DownloadIcon,
    },
    props: {
        order: Object,
        includeControls: Boolean,
        includeDownload: Boolean,
    },
    data() {
        return {
            language: null,
            dataOrder: null,
            showControls: false,
            downloading: false,
            order_name: null,
        };
    },
    methods: {
        computePrice: (product) => {
            let base_price = product.price;
            if (product && product.options && product.options.length) {
                for (const option of product.options) {
                    base_price += option.price_increase;
                }
            }
            return base_price;
        },
        gameType(game) {
            return MMOGameAlias(game);
        },
        statusController(status) {
            return OrderStatusController(status);
        },
        abbreviateAmount(number, notation, compactDisplay) {
            return OrderabbreviateAmount(number, notation, compactDisplay);
        },
        computedTotalPrice(products) {
            return getTotalShopOrderPrice(products);
        },
    },
    watch: {},
    computed: {},
    mounted: function () {
        this.language = navigator.language;
        this.dataOrder = this.order;
        this.showControls = this.includeControls;
        this.order_name = `trade-raid.com-order:${this.order.id}.pdf`;
    },
};
</script>

<style scoped>
.order-modal {
    offset-position: 150px !important;
    overflow-x: hidden !important;
}

.white {
    color: #f1f1f1;
}

.product-wrapper {
    margin-top: 15px !important;
}

.product-detail {
    padding-block: 5px;
}

.product-detail > div {
    color: #f1f1f1;
}

.flex-inline {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.product-image {
    width: 10%;
    border-radius: 5px;
}

.product-items {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-end;
    min-width: 250px;
    padding-bottom: 10px;
}

.custom-option-display {
    font-size: 15px;
    display: flex;
    flex-wrap: wrap;
    color: #f1f1f1;
    cursor: pointer;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    width: 120px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
}
.tooltip:hover .tooltiptext {
    visibility: visible;
}
.modal-info {
    display: flex;
    padding: 5px;
    justify-content: space-between;
    align-items: center;
}
.modal-info > .v {
    display: flex;
    gap: 5px;
    align-items: center;
}

.v > .min-width-70 {
    display: flex;
    min-width: 70px;
}

.custom-list-bullets {
    font-size: 15px;
    list-style: none; /* Remove default bullets */
    font-weight: bold;
}

.custom-list-bullets::before {
    content: '\2022'; /* CSS Code/unicode for a bullet */
    color: #5761ff;
    font-weight: bold;
    display: inline-block;
    width: 1em;
}

.secondary-color {
    color: hsla(0, 0%, 100%, 0.3) !important;
}
.section-line {
    border-top: 0.5px solid #4c515c;
    width: 100%;
    padding-block: 5px;
}
.trade-info-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 15px;
    gap: 5px;
    color: #f1f1f1;
}
.order-created_at {
    display: flex;
    align-items: center;
    gap: 15px;
}
.accepted {
    color: #79c175;
    background-color: #3d4442;
}
.pending {
    color: #fad303;
    background-color: #a59233;
    height: 50%;
}
.declined {
    color: #dc6e6f;
    background-color: #53484e;
}
.order-status {
    padding: 5px;
    border-radius: 5px;
    min-width: 85px;
    display: flex;
    justify-content: center;
    font-size: 0.7rem;
}

.icon-color-primary {
    color: #17d0c1;
}

.support-section {
    color: #f1f1f1;
    font-size: 0.8rem;
}

.text-white {
    color: #f1f1f1;
}
.background-color-gradient {
    background-image: linear-gradient(#5761ff, #4b90ff) !important;
}
</style>
