<template>
    <v-lazy
        v-model="isActive"
        :options="{
            threshold: 0.5,
        }"
        min-height="200"
        transition="fade-transition"
    >
        <v-container fluid fill-height id="faq">
            <h1>Frequently asked questions</h1>
            <p>
                If the FAQ does not answer your questions please contact Trade-Raid Support through
                <a target="_blank" href="https://www.facebook.com/TradeRaid" style="color: #17d0c1">Facebook</a> Live
                Chat or by Mail <br />
                We are ready to deliver your gold when you make your order but please note that Trade-raid is currently
                in BETA.
            </p>
            <h2 id="trade">Trade Service</h2>
            <section class="collapse-container">
                <v-expansion-panels flat>
                    <v-expansion-panel v-for="(faq, index) in faqs" :key="index">
                        <v-expansion-panel-header expand-icon="mdi-chevron-down">{{
                            faq.title
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content class="flex-column">
                            <div v-html="faq.ans"></div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </section>
            <h2 id="ratios">Gold Ratios</h2>
            <section class="collapse-container">
                <v-expansion-panels flat>
                    <v-expansion-panel v-for="(faq, index) in faqs3" :key="index">
                        <v-expansion-panel-header expand-icon="mdi-chevron-down">{{
                            faq.title
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content class="flex-column">
                            <div v-html="faq.ans"></div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </section>
            <h2 id="exchange">Exchange Service</h2>
            <section class="collapse-container">
                <v-expansion-panels flat>
                    <v-expansion-panel v-for="(faq, index) in tgbs" :key="index">
                        <v-expansion-panel-header expand-icon="mdi-chevron-down">{{
                            faq.title
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content class="flex-column">
                            <div v-html="faq.ans"></div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </section>
            <h2 id="delivery">Delivery</h2>
            <section class="collapse-container">
                <v-expansion-panels flat>
                    <v-expansion-panel v-for="(faq, index) in faqs2" :key="index">
                        <v-expansion-panel-header expand-icon="mdi-chevron-down">{{
                            faq.title
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content class="flex-column">
                            <div v-html="faq.ans"></div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </section>
        </v-container>
    </v-lazy>
</template>
<script>
export default {
    name: 'FAQ',
    metaInfo: {
        title: 'FAQ',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content:
                    'Read more about Trade-Raid at our Frequently asked questions page. Learn to know how our services works, gold ratios and delivery speed.',
            },
        ],
    },
    methods: {
        expand(expand_type) {
            const hash = window.location.hash;
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            const index = parseInt(params.q);
            if (hash === `#${expand_type}`) {
                return index;
            }
            return null;
        },
    },
    data: () => ({
        isActive: false,
        faqs: [
            {
                title: 'What is Trade-Raid.com?',
                ans: `
                    <p>Trade-Raid is a cross-game trading platform that allows gamers to trade their Steam Items to WoW Gold and exchange their own Gold between Retail and Classic.</p>
                    <p>We offer two unique and innovative services:</p>
                    <p>Trade Service: Perform one-way trades with Steam Items (CSGO, DOTA2, RUST, TF2 & Steam Community Items) for WoW Retail/Classic Gold .</p>
                    <p>Exchange Service: Allow gamers to trade their gold across Retail, Cataclysm Classic and Classic Era both ways.</p>
                `,
            },
            {
                title: 'How do I start trading my Steam Items for Gold?',
                ans: `
                    <p>The process is simple and straightforward - punctually:</p>
                    <ul>
                        <li><b style="bold; color:white">Sign in with Steam</b> in the Top Right corner and paste your <b style="bold; color:white">trade link</b></li>
                        <li>Pick the <b style="bold; color:white">WoW Version, Region and Server</b> you want your gold delivered to</li>
                        <li>On the left, <b style="bold; color:white">select the items</b> you would like to trade <b style="bold; color:white">from your inventory</b>. On the right, you can see <b style="bold; color:white">how much gold</b> you will receive for the items you have deposited.</li>
                        <li><b style="bold; color:white">Fill out</b> the remaining blankets (E-mail, Character Name and Gold Delivery Method)</li>
                        <li>Press the <b style="bold; color:white">TRADE</b> button and our trade-bot will <b style="bold; color:white">open a new window</b> with the offer for the items you have requested to trade for gold</li>
                        <li><b style="bold; color:white">Accept the offer on your PC</b> - Double check that the Steam Level and Registration Date of the Trade-Bot matches when you confirm on your Phone</li>
                        <li><b style="bold; color:white">Confirm the offer on your Phone</b> - Double check that the Steam Level and Registration Date of the Trade-Bot matches when you accepted the offer on your PC</li>
                        <li><b style="bold; color:white">Once we have successfully received your items</b> Trade-Raid will deliver the gold to your in-game character.</li>
                    </ul>
                `,
            },
            {
                title: 'Do I need to have Steam Mobile Authenticator to trade my Steam Items to MMO gold?',
                ans: `
                    <p>We strongly recommend all users of Trade-Raid to confirm their trade with Steam Mobile Authenticator.</p>
                    <p>It makes the trade instant, and it is safer.</p>
                    <p>Trade-Raid will first prepare the gold once your items have been received in our bots’ inventory.</p>
                    <p>In the case you have not set up Steam Mobile Authenticator we advise you to download the Steam App to your phone, login with your Steam Account and set up Steam Guard on the Steam app and wait the required 7 days from Steam to trade again.</p>
                `,
            },
            {
                title: 'I do not have Steam Mobile Authenticator, but I want to trade my items anyway, is this possible?',
                ans: `
                    <p>Yes, but beware that Steam will set the trade on hold for 15 days which means we will first receive your items after the trade hold.</p>
                    <p>At Trade-Raid this is not an issue. We deliver gold to users that waited the 15-day trade on hold daily. Approximately 20% of all our orders are successfully done through e-mail confirmations where the user waits the 15-day trade on hold period.</p>
                    <p>The gold will always be delivered after we have received your items.</p>
                `,
            },
            {
                title: 'I have Steam Balance or a Steam Gift Card, can I trade these for MMO Gold?',
                ans: `
                    <p>Yes. Trade-Raid accepts Steam Balance/Gift Card for Gold. 
                    <p>Due to the limitations of our Trade Page, users that seek to conduct such exchange for MMO gold can only be done manually. </p>
                    <p>The minimum trade amount is 10USD for Steam Balance/Gift Card Orders.</p>
                    <p>Please add Trade-Raid on <a href="https://steamcommunity.com/id/wowgoldtrader" style="color:white">Steam</a> to get started.</p>
                `,
            },
            {
                title: 'I want to use your services, but I am a little skeptical, what can I do?',
                ans: `
                    <p>We encourage all users to do their own research about Trade-Raid.com. Check out our Answer our <a href="https://uk.trustpilot.com/review/trade-raid.com?utm_medium=trustbox&utm_source=Carousel" style="color:white">Trustpilot</a> or 
                    <a href="https://www.instagram.com/trade_raid/" style="color:white">Instragram</a> pages and see the engagement between us and our users.
                        <br>In addition to this, you can also:</p>
                    <ul>
                    <li>Answer our <a href="https://docs.google.com/forms/d/e/1FAIpQLSdnkhfGqQgBHbGGgornL5r4aGJNysObFpGXJaVGeNZuXrGj6A/viewform" style="color:white">Feedback Survey</a> and receive a small bag of gold directly to your characters in-game mailbox for free.</li>
                    <li>Create a small trade with our low minimum trade amounts
                    <li>Get in touch with our Support <a href="https://www.trade-raid.com.com/support/" style="color:white">Support</a> or lurk around our <a href="https://discord.gg/4evrM735xN" style="color:white">Discord</a>!
                    </li>
                    </p>
                `,
            },
            {
                title: 'My initial trade offer was declined, and I have received a new offer, what do I do?',
                ans: `
                    <p>Always double check the Trade-bot's Steam Level and Registration Date both when you accept the trade offer and when you confirm the offer on your phone. They must match. If not, please decline your trade immediately without hesitation.</p>
                    <p>If the first trade offer you created was declined automatically, and you receive a new offer that you have not created (a second trade offer) please also decline that trade immediately without hesitation. You may be API scammed.</p>
                    <p>Please read more about API scams here: https://dmarket.com/blog/steam-api-key-scam/</p>
                    <p>For Danish Users: https://scammed.dk/api-scam/</p>
                    <p>You can always reach out for Trade-Raid <a target="_blank" href="https://trade-raid.com/support" style="color:white">support</a> if you need further assistance.</p>
                `,
            },
            {
                title: 'Is it safe to use your services?',
                ans: `
                    <p>At Trade-Raid.com transparency with our users is essential as our services place us in a grey zone.</p>
                    <p>To be clear: Exchanging gold for other currencies outside the game's universes is against the games rules. Trade at your own risk. Though at Trade-Raid.com, we do our best to reduce this risk as much as possible.</p> 
                    <p>Here is how:</p>
                    <ul>
                        <li>We collaborate with the same boosting communities and partners that we have known for years (since 2015)</li>
                        <li>Trade-Raid has a zero-tolerance policy on the usage of bots and other exploits</li>
                        <li>We supplement our gold delivery with gold from our own users that deposited their gold at our Exchange Service</li>
                        <li>In case a user reaches out to us and says that they have been sanctioned, Trade-Raid will ask him for all the relevant details, and we will consider stopping the cooperation with the responsible collaborator.</li>
                    </ul>
                    <p>Trade-Raid's statistics are:</p>
                    <ul>
                        <li>More than 5000 total orders handled (since October 2020 to April 2023)</li>
                        <li>8 user-reported temporary bans & gold stripped</li>
                        <li>Sanction percentage 0,0016%</li>
                    </ul>
                    <p>Although unreported cases may exist, using Trade-Raid's services is low risk. We have <b style="bold; color:white">no records of permanent bans</b>. This includes for all our users, partners and our own gold stocks.</p>
                    <p>If it happens that a user is sanctioned after using our services, please reach out to Trade-Raid Support. We will ask you for details of when and how. Trade-Raid will give compensation to cooperative users.</p>
                `,
            },
            {
                title: 'Can I get my items refunded?',
                ans: `
                    <p>All trades on the Trade-Raid website are final. Trade-Raid do not offer refunds in general due to the nature of the exchange between games and the practical challenges that are involved in such transactions. Most Steam Items are locked for 8 days after they have been traded and it is common practice that in-game gold is never returned once the gold has been delivered.</p>
                    <p>Refunds are possible in the rare case that the user has not received their gold yet or if an unexpected error has occurred.</p>
                `,
            },
        ],
        tgbs: [
            {
                title: 'What is the Exchange Service?',
                ans: `
                    <p>The Exchange Service allows players to trade their MMO currencies across servers, games and game versions (eg. WOTLK to Retail)</p>
                    <p>Exchange Service is currently in BETA and is in active development, and we are constantly tweaking and improving the service's functionality and accessibility. We plan to completly revamp and merge the Exchange Service into our Trade Service in the near future.</p> 
                `,
            },
            {
                title: 'How does Exchange Service work?',
                ans: `
                    <p>The process is very simple – punctually:</p>
                    <ul>
                        <li>Sign In/Sign Up in the top right corner with your E-mail</li>
                        <li>On the left side chose which Version, Region and Server you want to deposit gold from your character to Trade-Raid’s Gold recipient.</li>
                        <li>Mention the Character Name that is going to mail the gold in-game</li>
                        <li>On the right side pick your desired WoW Version, Region and Server you want your gold Exchanged to.</li>
                        <li>Mention the Character Name that is going to receive the Exchanged Gold Amount from Trade-Raid.</li>
                        <li>Fill out your e-mail and press the TRADE Button</li>
                        <li>You will afterwards be directed to our order request page with all the details that you should double-check. Read the name of Trade-Raid’s Gold Recipient on the chosen Server and Faction.</li>
                        <li>Once ready, press the ‘’Ready to Trade Button’’.</li>
                        <li>In-game mail the Deposit Gold Amount to Trade-Raid’s Gold Recipient.</li>
                        <li>Press ‘’I have deposited my gold’’ and let Trade-Raid handle the rest.</li>
                    </ul>
                `,
            },
            {
                title: 'What are the Exchange Rates?',
                ans: `
                    <p>Trade-Raid’s gold ratio is 1:1. <br /> 
                    When it comes to trading across different game versions (Retail to WOTLK for example) our goal is to maintain a 1:1 ratio as much as possible though fluctuations may occur.<br /> 
                    The base fee for any exchange is 20%.
                    The “Exchanged Amount” is always the total Gold Amount you will receive in-game.</p>
                    Users that seek to move their gold from or to specific servers are subject to an extra fee. <br />
                    Visit our <a target="_blank" href="https://docs.google.com/document/d/1G1JIQZfEHb4Su3InJswHXp_7T6YDHNxR65TxapscCIc/edit?usp=sharing" style="color:white">MMO Currency Tendencies Document Here.</a></p>
                    `,
            },
            {
                title: 'What is the basis of the fee rate?',
                ans: `
                    <p>At Trade-Raid we have found that 8 out of 10 of our users exchange their gold from less popular/less desired servers to more popular/more desired servers. 
                    In other words: users are trading less valuable gold to more valuable gold. </p>
                    <br>
                    <p>Originally when we created launched our Exchange Service in September 2021, our intentions were to create a exchange system that would be able to manage all sorts of trade combinations. 
                    Though, we realised that our 1.0 Version of Exchange Service simply was simply not sophisticated enough to handle this. 
                    We thus decided to go with a "one-size fits all" fee of 20%. 
                    We plan to create an Exchange Service 2.0 in the future that is much more flexible and accessible to our users. </p>
                `,
            },
        ],
        faqs2: [
            {
                title: 'How long does it take before I receive my gold?',
                ans: `
                    <p>At Trade-Raid we always try to deliver your gold as soon as possible. After 72 hours, you can request a refund if you have not received your gold yet. 
                    If you have not received your gold after 5 days, you can choose to receive an equivalent refund or the original gold amount.<br>
                    Delivering the original gold amount will always remain the first option at all times.
                    Exchange Service orders do on average take longer to complete because Trade-Raid has to await and confirm the user's in-game deposit before the order can be completed. 
                    Exchange Service orders still follow the same delivery expectations as Trade Service orders once the gold deposit has been confirmed. 
                    You can at any time contact Trade-Raid support if you have any questions about your order.
                    </p>
                    <p>You can at any time contact Trade-Raid <a target="_blank" href="https://trade-raid.com/support" style="color:white">support</a> if you have any questions about your order.</p>
                `,
            },
            {
                title: 'Which in-game gold delivery methods do Trade-Raid offer?',
                ans: `
                    <p>At Trade-Raid the in-game gold delivery methods we offer to our users are: 1) Mail and 2) Auction House.</p>
                    <p>Mail: <br>If the user chooses to receive their gold by Mail, Trade-Raid will deliver the gold straight to your character's in-game mailbox.
                    <br>For larger orders it is common that the gold received may be split into smaller mails that the user will receive on different timestamps throughout the day.
                    <br>Please note that WoW delays all in-game mails by 1 hour.</p>
                    <p>Auction House: <br>If the user chooses to receive their gold by the in-game Auction House, Trade-Raid will purchase the Battle Pet/BoE Item the user wrote on the Trade-Page that will be listed by the user on the Auction House.
                    <br>At Trade-Raid it is our impression that the chosen gold delivery method is completly determined by the users preference.
                    <br>Note that the in-game Auction House takes a 5% cut which means that the user always receives more gold when they choose the Mail delivery method.
                    <br>If you want Trade-Raid to deliver your gold Face to Face in-game or to deposit the gold to your own Guild Bank please reach out to our Support on <a href="https://steamcommunity.com/id/wowgoldtrader/" style="color:white">Steam</a> or Facebook.
                    <br>It is estimated that 80% of all Trade-Raid's orders are delivered by mail. 15% are delivered through Auction House. The remaining 5% are delivered through special requests of Face to Face Trades or Guild Bank deposits.</p>
                `,
            },
            {
                title: 'I have filled out incorrect WoW Information/Details but I have confirmed my trade! What do I do?',
                ans: `
                    <p>Please reach out to Trade-Raid <a href="https://trade-raid.com/support" style="color:white">Support</a> immediately to assist you.</p>
                    `,
            },
        ],
        faqs3: [
            {
                title: 'Which economies and price sources do Trade-Raid base its gold ratios on?',
                ans: `<p>At our MMO Games, Trade-Raid bases its gold ratios on WoW Token and competitive gold market sites. We do this to ensure that we can offer the highest amount of gold to our users.</p>
                <p>At Steam, Trade-Raid retrieves the average sale tendency over the last 14 days of your Steam Items from the Steam Community Market. To supplement this, we also retrieve data from real life third-party markets such as buff.163.com for CSGO and DOTA2.</p>
                <p>For Rust, TF2 and Steam Community Items our gold ratios are solely based on the Steam Community market.</p>
                <p>Trade-Raid updates its gold ratios on a daily basis.</p>
                `,
            },
            {
                title: 'I have an item that might be bugged with an absurd high value at the Trade Service, should I trade my item?',
                ans: `
                    <p>Trade-Raid retrieves the average sale tendency over the last 14 days of your Steam Items from the Steam Community Market. This means your gold offers may fluctuate a bit. </p>
                    <p>Trade-Raid is in BETA and utilizes a simplified ‘’one-size fits all’’ method to retrieve the data for of Steam Items to MMO gold. Most of our trades are accurate and properly calculated but we have had rare cases of inaccurate calculations.</p>
                    <p>Currently, CSGO StatTrak Knives, Rare TF2 and Steam Community Items often have an unstable or low sale tendency that tend to be valuated too high and thus inaccurately by our gold calculator. Please note that if a User trades such items Trade-Raid may have to reduce the gold amount or in rare cases cancel your trade.</p>
                    <p>Thus, we advise you not to trade items that has an absurd high value. Instead, reach out for Trade-Raid support first and see if we can help you.</p>
                    <p>If you find an item that is bugged and has an extreme high price and reach out to us with a screenshot of the item at our Trade-Page and the Item Name. Trade-Raid support will reward you with extra free gold on your next order.</p>
                `,
            },
            {
                title: 'My item is going for (e.g 20€) on the Steam Market should I not receive the gold equivalency of 1 WoW Token?',
                ans: `
                    <p>Your Steam Value is locked within the Steam platform and cannot be withdrawn to your bank account as well as you cannot pass your Steam Items to Blizzard and exchange it for a WoW Token.</p>
                    <p>In other words: Your Item is worth 20€ on the Steam Market but not in real life currency markets.</p>
                    <p>It is also important to state that ALL Steam Items have 15% added value that are pure Sale Fees that goes to Steam if you sell the item on the Steam Market.</p>
                    <p>A common rule of thumb is to pay 45-65% of Steam Market value if someone were to buy a CSGO Skin for real life money. The percentage is substantially lower for less popular games such as TF2 and Rust and for low tier/trash items.</p>
                `,
            },
            {
                title: 'Why do some games have better gold ratios than other games?',
                ans: `
                    <p>This is solely due to the liquidity and popularity of those games and their skin economy. Turning Rust Items into real life money is a more expensive process and requires more knowledge, time and effort compared to DOTA2 high tier items.</p>
                    <p>At Trade-Raid, we have two metrics that increases the gold ratio of your items:</p>
                    <p>1) The popularity of the Steam Game you are trading items from (Where CSGO has the highest and Steam Community Items have the lowest ratios)</p>
                    <p>2) The value of the specific items in regards of their item ''tiers'' (only for CSGO and DOTA2)</p>
                `,
            },
            {
                title: 'Does Trade-Raid pay additional gold to float, patterns, and gems?',
                ans: `
                    <p>Trade-Raid is in BETA and utilizes a simplified ‘’one-size fits all’’ method to retrieve the data for of Steam Items to MMO gold from the Steam Market. It is therefore not possible for us to pay additional gold for such items.</p>
                `,
            },
            // {
            //     title: 'Why does Trade-Raid collect Value Added Taxes (VAT) when I choose my billing country?',
            //     ans: `
            //         <p>Trade-Raid is a Danish Company based in Denmark. We are <bold class="fat">obligated</bold> to collect VAT on behalf of Danish tax regulations, if a user picks a billing country that is a <bold class="fat">member of the European Union (EU)</bold>, which results in a reduction of your gold amount in accordance to the VAT % rate in your own country. The deducted amount is <bold class="fat">not</bold> part of Trade-Raid's cut of your trade but a tax.</p>
            //         <p>You can <bold class="fat">freely choose a non-VAT country</bold>, if this does not apply to you. The choice of billing country does not affect the region or server of your in-game delivery. </p>
            //     `,
            // },
        ],
    }),
};
</script>

<style>
#faq .v-expansion-panel-content p {
    margin-bottom: 0;
}
</style>

<style lang="scss" scoped>
#faq {
    padding-top: calc(80px + 100px);
    padding-bottom: 124px;
    flex-direction: column;
    align-content: center;
    background: linear-gradient(
        90deg,
        rgba(0, 14, 25, 0.1) 0%,
        rgba(15, 24, 45, 0.1) 30.7%,
        rgba(62, 0, 108, 0.1) 60.29%,
        rgba(11, 72, 67, 0.1) 79.69%,
        rgba(15, 24, 45, 0.1) 95.48%
    );
    h1 {
        width: 100%;
        font-weight: 600;
        font-size: 36px;
        line-height: 49px;
        color: #fff;
        text-align: center;
    }
    h2 {
        width: 100%;
        font-weight: 400;
        font-size: 26px;
        line-height: 39px;
        color: #fff;
        text-align: center;
    }
    .spec-list {
        list-style: circle;
    }
    .fat {
        font-weight: bold;
        color: #fff;
    }
    p {
        text-align: center;
        font-size: 18px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 70px;
        a {
            color: #fff;
        }
    }
    .collapse-container {
        width: 100%;
        max-width: 1170px;
        .v-expansion-panel {
            border-radius: 2px;
            background: #383f51;
            margin-top: 0;
            margin-bottom: 20px;
        }
        .v-expansion-panel-header {
            padding: 25px 30px;
            font-size: 18px;
            line-height: 24px;
            color: #fff;
            &::v-deep .v-expansion-panel-header__icon i {
                color: #fff;
            }
            &.v-expansion-panel-header--active,
            &.v-expansion-panel-header--active::v-deep .v-expansion-panel-header__icon i {
                color: #17d0c1;
            }
        }
        .v-expansion-panel-content {
            padding-left: 30px;
            padding-right: 30px;
            font-size: 16px;
            line-height: 30px;
            color: rgba(255, 255, 255, 1);
            div {
                padding-top: 0;
                padding-bottom: 25px;
            }
        }
        .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
            padding: 0;
            font-size: 16px;
            line-height: 30px;
            color: rgba(255, 255, 255, 0.7);
        }
    }
}
</style>
