import Cookies from 'js-cookie';

const TokenKey = 'user-token';

const clientToken = 'client-id';

const dismissedEmail = 'dismissed-email';

export function getToken() {
    return Cookies.get(TokenKey);
}

export function getClientToken() {
    return Cookies.get(clientToken);
}

export function setToken(token) {
    return Cookies.set(TokenKey, token, { expires: 7 });
}

export function setDismissEmail(value) {
    return Cookies.set(dismissedEmail, value, { expires: 3 });
}

export function getDismissedEmail() {
    return Cookies.get(dismissedEmail);
}

export function setClientToken(token) {
    return Cookies.set(clientToken, token, { expires: 7 });
}

export function removeClientToken() {
    return Cookies.remove(clientToken);
}

export function removeToken() {
    return Cookies.remove(TokenKey);
}

// used for tracking unique views on blog posts
export function createBlogToken(token) {
    return Cookies.set('blog', token);
}

export function getBlogToken() {
    return Cookies.get('blog');
}
