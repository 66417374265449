<template>
    <div class="text-center" v-if="!user.email">
        <v-dialog persistent v-model="dialog" width="500">
            <v-card color="#555C70">
                <v-card-title class="headline modal grey text-white"
                    >Add email and password to secure your account</v-card-title
                >
                <v-card-text class="modal text-white">
                    <p class="mb-4">
                        {{ explainer }}
                    </p>
                    <p class="error error-msg" v-if="error">{{ error_msg }}</p>
                    <v-text-field
                        :name="`username_${Math.random()}`"
                        v-model="email"
                        label="Email*"
                        type="email"
                        outlined
                        clearable
                        prepend-icon="mdi-email"
                        color="white"
                        height="36px"
                        dense
                        autocomplete="off"
                        :rules="[rules.required, rules.email]"
                    ></v-text-field>
                    <v-text-field
                        :name="`username_${Math.random()}`"
                        v-model="password"
                        label="Password*"
                        outlined
                        :type="show4 ? 'text' : 'password'"
                        prepend-icon="mdi-lock-outline"
                        :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
                        height="36px"
                        dense
                        @click:append="show4 = !show4"
                        color="white"
                        :rules="[rules.requiredPassword]"
                        autocomplete="off"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#17D0C1" class="signin-btn mr-3" v-if="showDismissable" @click="dismiss()"
                        >Remind me later</v-btn
                    >
                    <v-btn color="#17D0C1" class="signin-btn" @click="submit()">Secure account</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import SignIn from '../SignIn';
import { setEmailOnSteamUser } from '@/api/auth.js';
import { setDismissEmail, getDismissedEmail } from '../../utils/auth.js';
import { toastConfig } from '../../utils/toastConfig.js';
export default {
    name: 'EmailNotSignedIn',
    props: {
        dialog: Boolean,
        user: Object,
        explainer: String,
        dissmisable: Boolean,
    },
    components: {
        SignIn,
    },
    data() {
        return {
            email: '',
            password: '',
            error: false,
            error_msg: '',
            show3: false,
            show4: false,
            dismissed: false,
            rules: {
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Invalid e-mail.';
                },
                required: (value) => !!value || 'Input field is required',
                requiredPassword: (value) =>
                    (!!value && value.length > 6) || 'Password is required and must be more than 6 characters long',
            },
        };
    },
    computed: {
        showDismissable() {
            return JSON.parse(this.dissmisable);
        },
    },
    methods: {
        async submit() {
            try {
                await setEmailOnSteamUser(
                    this.user._id,
                    { email: this.email, password: this.password },
                    this.user.access_token,
                ).then((res) => {
                    if (res.data && res.data.k === 'email') {
                        this.error = true;
                        this.error_msg = res.data.msg;
                        const t = toastConfig(res.data.msg, 5000);
                        this.$toast.error(t.msg, t.options);
                        return;
                    }
                    if (res.data.msg === 'success') {
                        this.valid_sign_up = true;
                        const t = toastConfig(`Your profile has been updated with email: ${this.email}`, 5000);
                        this.$toast.success(t.msg, t.options);
                    }
                    this.$store.dispatch('getInfo');
                });
            } catch (error) {}
        },
        async hideModal() {
            try {
            } catch (error) {
                console.log(error);
            }
        },
        updateSignupEmailErrorMessage() {
            // this.$refs.signup_form.validate();
        },
        dismiss() {
            setDismissEmail(true);
            this.dialog = false;
        },
        mounted: function () {},
    },
};
</script>
<style scoped>
.v-dialog .modal.grey {
    background: #555c70;
}

.email {
    padding-top: 15px;
}
.text-white {
    color: #fff !important;
}
.ext_link {
    color: #17d0c1;
    text-decoration: underline;
}

.ext_link:hover {
    color: #17d0c1 !important;
    opacity: 0.5;
}
.v-dialog .modal.text-white {
    color: #fff;
    padding: 10px 20px;
    font-size: 17px;
    line-height: 35px;
    font-family: 'Roboto';
}
.v-dialog .text-white >>> .v-label {
    color: #fff;
}
.v-dialog .text-white >>> input {
    color: #fff;
}
.error-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
.update-profile-btn {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 13px !important;
    line-height: 18px !important;
    text-align: center !important;
    text-transform: uppercase !important;
    text-decoration: none !important;
    color: #ffffff !important;
    background: #17d0c1 !important;
    box-shadow: 0px 0px 20px rgba(31, 223, 206, 0.4) !important;
    border-radius: 3px !important;
    padding: 0 30px !important;
}
</style>
