<template>
    <!-- TrustBox widget -->
    <div
        ref="trustbox"
        class="trustpilot-widget"
        data-locale="en-US"
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="602b33e15092110001846808"
        data-style-height="28px"
        data-style-width="100%"
        data-theme="dark"
    >
        <a href="https://www.trustpilot.com/review/example.com" target="_blank" rel="noopener">Trustpilot</a>
    </div>
    <!-- End TrustBox widget -->
</template>

<script>
export default {
    props: {
        templateId: String,
    },
    mounted() {
        const trustbox = this.$refs.trustbox;
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(trustbox);
        }
    },
};
</script>
