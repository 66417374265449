<template>
    <body>
        <div id="id01" class="modal">
            <form
                class="modal-content animate"
                action="https://trade-raid.us7.list-manage.com/subscribe/post"
                method="POST"
            >
                <div class="imgcontainer">
                    <span
                        onclick="document.getElementById('id01').style.display='none'"
                        class="close"
                        title="Close Modal"
                        >&times;</span
                    >
                    <img src="https://trade-raid-com.mo.cloudinary.net/letter.svg" alt="Avatar" class="avatar" />
                </div>
                <h2 class="headertekst">Subscribe to Trade-Raid's Newsletter</h2>
                <p class="smalltekst">
                    Most people know that users who subscribe to Trade-Raid's newsletter simply have more gold on their
                    characters than non-subscribers. Why? Because Trade-Raid newsletter subscribers automatically
                    participate in our gold giveaways and when we run bonus gold events.

                    <br />We promise not to spam you and only share content and news that matters to our users.
                </p>

                <div class="container">
                    <input type="hidden" name="u" value="d717ab197117c22f26fd9508c" />
                    <input type="hidden" name="id" value="6eb2ec1e27" />
                    <label class="label1" for="MERGE1"><b>CharacterName-Server-Faction</b></label>
                    <input
                        type="text"
                        placeholder="WoW CharacterName-Server-Faction"
                        name="MERGE1"
                        id="MERGE1"
                        required
                    />

                    <label class="label1" for="MERGE0"><b>Email</b></label>
                    <input
                        type="email"
                        autocapitalize="off"
                        autocorrect="off"
                        placeholder="Email address"
                        name="MERGE0"
                        id="MERGE0"
                        required
                    />

                    <button type="submit">Subscribe</button>
                </div>
            </form>
        </div>
    </body>
</template>

<script>
// Get the modal
var modal = document.getElementById('id01');

// When the user clicks anywhere outside of the modal, close it
window.onclick = function (event) {
    if (event.target == modal) {
        ocument.getElementById('id01').style.display = 'none';
    }
};

window.onload = function () {
    setTimeout(function () {
        document.getElementById('id01').style.display = 'block';
    }, 120000);
};
</script>

<style scoped>
/* POPUP */
/* POPUP */
/* POPUP */

body {
    font-family: Arial, Helvetica, sans-serif;
}

/* Full-width input fields */
input[type='text'],
input[type='email'] {
    width: 100%;
    padding: 6px 10px;
    color: white;
    margin: 4px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

label {
    color: white;
}

/* Set a style for all buttons */
button {
    background-color: #17d0c1;
    color: white;
    padding: 7px 10px;
    margin: 4px 0;
    border: none;
    cursor: pointer;
    width: 100%;
}

button:hover {
    opacity: 0.8;
}

/* Extra styles for the cancel button */
.cancelbtn {
    width: auto;
    padding: 5px 9px;
    background-color: #f44336;
}

/* Center the image and position the close button */
.imgcontainer {
    text-align: center;
    margin: 12px 0 6px 0;
    position: relative;
}

img.avatar {
    width: 10%;
    border-radius: 10%;
}

.container {
    padding: 8px;
}

span.psw {
    float: right;
    padding-top: 8px;
}

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    padding-top: 60px;
}

/* Modal Content/Box */
.modal-content {
    width: 50%;
    background-color: #383f51;
    margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button (x) */
.close {
    position: absolute;
    right: 12px;
    top: 0;
    color: #fff;
    font-size: 35px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: red;
    cursor: pointer;
}

h2.headertekst {
    text-align: center;
    color: #fff;
}

p.smalltekst {
    text-align: center;
    color: #fff;
}

label.label1 {
    color: #fff;
}

/* Add Zoom Animation */
.animate {
    -webkit-animation: animatezoom 0.6s;
    animation: animatezoom 0.6s;
}

@-webkit-keyframes animatezoom {
    from {
        -webkit-transform: scale(0);
    }
    to {
        -webkit-transform: scale(1);
    }
}

@keyframes animatezoom {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
    span.psw {
        display: block;
        float: none;
    }
    .cancelbtn {
        width: 100%;
    }
}
</style>
