import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"tp-widget-mobile"},[_c('trustpilot-horizontal')],1),_c('div',{staticClass:"tp-widget-pc"},[_c('trustPilot',{attrs:{"templateId":'5419b6ffb0d04a076446a9af'}})],1),_c('div',{staticClass:"layout-shop-container"},[_c('div',{staticClass:"aside"},[_c('ul',_vm._l((_vm.sideFilters),function(filter,index){return _c('li',{key:index,class:{ active: filter === _vm.activeSideFilter },on:{"click":function($event){return _vm.setActiveFilter(filter)}}},[_vm._v(" "+_vm._s(filter)+" ")])}),0)]),(_vm.product_display)?_c('ShopProducts',{attrs:{"propProducts":_vm.product_display,"game":_vm.activeSideFilter,"propProductFilters":_vm.product_display_filters}}):_c('div',{staticClass:"loader"},[_c('Loader')],1),_c(VDialog,{staticClass:"custom-modal-config",attrs:{"persistent":"","width":"800"},model:{value:(_vm.maintenance),callback:function ($$v) {_vm.maintenance=$$v},expression:"maintenance"}},[_c('div',{staticClass:"product-modal-container"},[_c(VCard,{attrs:{"color":"#202136"}},[_c(VCardTitle,{staticClass:"headline modal background-color-gradient text-white"},[_c('div',{staticClass:"close"})]),_c(VCardText,[_c('span',{staticClass:"maintenance"},[_c('span',{staticClass:"text-20 pb-5"},[_vm._v("The Shop is under going maintenance - Returns 1st of October 2023!")]),_c('span',{staticClass:"text-15 pb-5"},[_vm._v("You can still use our other services.")]),_c('div',{staticClass:"links"},[_c('span',[_c(VBtn,{staticClass:"background-color-gradient color-white",on:{"click":function($event){return _vm.goToService('/trade')}}},[_vm._v("Skins for Gold")])],1),_c('span',[_c(VBtn,{staticClass:"background-color-gradient color-white",on:{"click":function($event){return _vm.goToService('/exchange')}}},[_vm._v("Gold for Gold")])],1)])]),_c('br')])],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }