import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Meta from 'vue-meta';
import NotFound from '@/views/404/';
import Home from '@/views/Home/';
import FAQ from '@/views/FAQ/';
import Exchange from '@/views/Exchange/';
import Trade from '@/views/Trade/';
import TradeSuccess from '@/views/TradeSuccess/';
import ExchangeSuccess from '@/views/ExchangeSuccess/';
import Support from '@/views/Support';
import Business from '@/views/Business';
import PrivacyPolicy from '@/views/PrivacyPolicy/';
import CookiePolicy from '@/views/CookiePolicy/';
import Terms from '@/views/Terms/';
import Giveaways from '@/views/Giveaways';
import Signup from '@/views/Signup';
import UpdatePassword from '@/components/UpdatePassword';
import Profile from '@/components/Profile';
import Services from '@/views/Services';
import TradeOffer from '@/components/TradeOffer';
import ForgotPassword from '@/views/ForgotPassword';
import Orders from '@/components/Profile/orders';
import Blog from '@/views/Blog';
import blogPost from '@/views/Blog/blogPost';
import Shop from '@/components/Shop';
import ShopOrderErrorCB from '@/views/ShopOrderCallBacks/error_cb';
import ShopOrderSuccessCB from '@/views/ShopOrderCallBacks/success_cb';
import PaymentFlow from '@/components/PaymentFlow/payment-flow.vue';
import YCombinator from '@/views/YCombinator';
import AboutUs from '@/views/AboutUs';
import Influencers from '@/views/Business/influencers.vue';

Vue.use(VueRouter);

Vue.use(Meta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true,
});

const routes = [
    {
        path: '/404',
        name: '404',
        // component: () => import('@/views/404'),
        component: NotFound,
        //hidden: true
    },
    {
        path: '/order-success',
        name: 'order-success-cb',
        component: ShopOrderSuccessCB,
    },
    {
        path: '/order-failed',
        name: 'order-failed-cb',
        component: ShopOrderErrorCB,
    },
    {
        path: '/shop/:slug',
        name: 'shop',
        component: Shop,
    },
    {
        path: '/shop',
        name: 'shop',
        component: Shop,
    },
    {
        path: '/checkout',
        name: 'payment-flow',
        component: PaymentFlow,
    },
    {
        path: '/services',
        name: 'Services',
        component: Services,
    },

    {
        path: '/',
        name: 'Home',
        // component: () => import('@/views/Home/'),
        component: Home,
    },

    {
        path: '/faq',
        name: 'FAQ',
        // component: () => import('@/views/FAQ/'),
        component: FAQ,
    },
    {
        path: '/forgot-password',
        name: 'FP',
        component: ForgotPassword,
    },
    {
        path: '/trade',
        name: 'Trade',
        // component: () => import('@/views/Trade/'),
        component: Trade,
    },
    {
        path: '/exchange',
        name: 'Exchange',
        component: Exchange,
    },

    {
        path: '/trade-success',
        name: 'Trade Success',
        // component: () => import('@/views/AfterTrade/'),
        component: TradeSuccess,
    },

    {
        path: '/exchange-success',
        name: 'Exchange Success',
        component: ExchangeSuccess,
        props: true,
    },
    {
        path: '/privacy-policy',
        name: 'Privacy Policy',
        // component: () => import('@/views/AfterTrade/'),
        component: PrivacyPolicy,
    },

    {
        path: '/support',
        name: 'Support',
        // component: () => import('@/views/AfterTrade/'),
        component: Support,
    },
    {
        path: '/business',
        name: 'Business',
        component: Business,
    },

    {
        path: '/influencers',
        name: 'Influencers',
        component: Influencers,
    },

    {
        path: '/about-us',
        name: 'About Us',
        component: AboutUs,
    },

    {
        path: '/cookie-policy',
        name: 'Cookie Policy',
        // component: () => import('@/views/AfterTrade/'),
        component: CookiePolicy,
    },

    {
        path: '/terms',
        name: 'Terms of Use',
        component: Terms,
    },

    {
        path: '/ycombinator',
        name: 'Y Combinator',
        component: YCombinator,
    },

    {
        path: '/mission',
        redirect: '/about-us',
    },

    {
        path: '/giveaways',
        name: 'Giveaway Page',
        component: Giveaways,
    },
    {
        path: '/signup',
        name: 'Signup',
        component: Signup,
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
    },
    {
        path: '/orders',
        name: 'Orders',
        component: Orders,
    },

    {
        path: '/trade-offer',
        name: 'TradeOffer',
        component: TradeOffer,
    },
    {
        path: '/update-password',
        name: 'update-password',
        component: UpdatePassword,
    },
    {
        path: '/blog',
        name: 'Blog',
        component: Blog,
    },
    {
        path: '/blog/posts/:slug',
        name: 'Blog Post',
        component: blogPost,
    },
    {
        path: '/sitemap.xml',
        name: 'Sitemap.xml',
        component: blogPost,
    },
    {
        path: '/robots.txt',
        name: 'Robots.txt',
        component: blogPost,
    },
    { path: '*', redirect: '/404', hidden: true },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    let isAuth = store !== undefined && store.getters.token !== undefined && store.getters.token !== '';
    // if(to.path.includes('/shop')) {
    //      console.log('yus')
    //      next({name: '/shop'})
    // }
    // if (to.name === 'Home' && isAuth) next({ name: 'Trade' });
    // else next();
    next();
});

export default router;
