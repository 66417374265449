<template>
    <div>
        <svg
            v-if="kind === 'gold'"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 12.28 13.46"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="2.89"
                    y1="1308.89"
                    x2="9.33"
                    y2="1300.78"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 1312.05)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stop-color="#ffe08d" stop-opacity="0" />
                    <stop offset="0.28" stop-color="#ffdc7d" stop-opacity="0.28" />
                    <stop offset="1" stop-color="#ffd257" />
                </linearGradient>
            </defs>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path
                        class="cls-1"
                        d="M8.84,12.74c-2.7,1-5.24.94-7.31-1.61-2.28-2.91-2-7.48.83-9.72C5.06-.72,9.93-.46,10.13,3.59,11.59,6.4,11.07,11.44,8.84,12.74Z"
                    />
                    <path
                        class="cls-2"
                        d="M10,11.8a4.9,4.9,0,0,1-7.41-.72C.34,8.17.6,3.59,3.45,1.36,6.14-.77,9.62.43,11.22,3.54A7.26,7.26,0,0,1,10,11.8Z"
                    />
                    <path
                        class="cls-3"
                        d="M10,11.8a4.9,4.9,0,0,1-7.41-.72C.34,8.17.6,3.59,3.45,1.36,6.14-.77,9.62.43,11.22,3.54A7.26,7.26,0,0,1,10,11.8Z"
                    />
                    <path
                        class="cls-4"
                        d="M9.67,11.18a4.22,4.22,0,0,1-6.38-.73A6.09,6.09,0,0,1,4,2.24C6.3.43,9.25,1.36,10.65,4A6.23,6.23,0,0,1,9.67,11.18Z"
                    />
                    <path
                        class="cls-5"
                        d="M9.67,11.18a4.22,4.22,0,0,1-6.38-.73A6.09,6.09,0,0,1,4,2.24C6.3.43,9.25,1.36,10.65,4A6.23,6.23,0,0,1,9.67,11.18Z"
                    />
                    <path
                        class="cls-6"
                        d="M4.49,10.19a6.11,6.11,0,0,1,.77-8.1,3.6,3.6,0,0,1,1.56-.78A4.26,4.26,0,0,0,4,2.24a6.1,6.1,0,0,0-.73,8.21A4.25,4.25,0,0,0,8.37,12,4.1,4.1,0,0,1,4.49,10.19Z"
                    />
                    <path
                        v-if="include_logo"
                        class="cls-7"
                        d="M5.66,9.7l.1,0a.82.82,0,0,0,.38-.05l0,0s0,0,0,0h0a1.63,1.63,0,0,0,.43-.08L7,9.42l.07,0s0,0,0,0,0,0,0-.05l.06,0h.13a.2.2,0,0,0,.15-.06s0-.05,0-.05l-.08,0s0,0,0-.05,0,0,0-.05h.32A.12.12,0,0,0,7.7,9a.52.52,0,0,1,.1-.11s0,0,0,0-.06,0-.1.05v0s0,0,0,0l-.06,0s0,0,0-.06a.28.28,0,0,1,.11-.08A.94.94,0,0,0,8,8.56a.44.44,0,0,0,.15-.18L8,8.36a.65.65,0,0,1-.2,0,.17.17,0,0,1-.1-.13.58.58,0,0,1,0-.23.27.27,0,0,1,.13-.13.08.08,0,0,1,.08,0A1.18,1.18,0,0,1,8.16,8l.05,0a.16.16,0,0,0,0-.1.43.43,0,0,0,0,0s0,0,0,0a.31.31,0,0,1,.17.09.47.47,0,0,0,.1.1s0,0,.06,0a.27.27,0,0,0-.07-.26l-.08,0-.11-.06a.05.05,0,0,1,0,0,.2.2,0,0,1,0-.17.08.08,0,0,1,0,0l.11-.06h.05l.2,0a.11.11,0,0,1,.08.08l0,.15v0s0,0,.06,0L9,7.44A.11.11,0,0,0,9,7.3l-.15-.18A1.71,1.71,0,0,0,8.72,7,14.58,14.58,0,0,0,7.2,7H7.14A.32.32,0,0,0,6.91,7l0,0,0,0s0,0,0,0A.28.28,0,0,1,7,7a.66.66,0,0,1,.24,0,.42.42,0,0,1,.18.11l.06.06a.51.51,0,0,1-.06.59.67.67,0,0,1-.16.12A1,1,0,0,1,7,8.05l-.05,0h0a2.94,2.94,0,0,0,0,.51.72.72,0,0,1-.08.32A.76.76,0,0,1,6.72,9s0,0,0,.05l-.13.1a.19.19,0,0,0-.1.1s0,0,0,0a.26.26,0,0,0-.28.13l0,0a.29.29,0,0,0-.18,0,2.66,2.66,0,0,1-.49.1H5.34a.11.11,0,0,0,.09.06Z"
                    />
                    <path
                        v-if="include_logo"
                        class="cls-7"
                        d="M5.62,3.74h.1a.7.7,0,0,1,.38,0s0,0,0,0,0,0,0,0h0a1.61,1.61,0,0,1,.43.07L7,4l.06,0s0,0,0,0,0,0,0,0l.06,0H7.2a.21.21,0,0,1,.16.06s0,0,0,0l-.08,0a.05.05,0,0,0,0,0s0,0,0,0h.32a.12.12,0,0,1,.08,0l.1.12s0,0,0,0-.06,0-.1,0v0h0l-.06,0s0,0,0,.06a.25.25,0,0,0,.12.08A1.08,1.08,0,0,1,8,4.85.41.41,0,0,1,8.14,5L8,5.05a.65.65,0,0,0-.2,0s-.09.07-.09.14a.42.42,0,0,0,0,.22.27.27,0,0,0,.13.13.08.08,0,0,0,.08,0l.19-.11,0,0a.1.1,0,0,1,0,.09s0,0,0,.06,0,0,0,0a.28.28,0,0,0,.17-.09l.1-.1s0,0,0,0a.27.27,0,0,1-.06.26l-.08,0-.11.06a.05.05,0,0,0,0,0,.38.38,0,0,0,0,.18l0,0,.12.06h.05l.2,0A.09.09,0,0,0,8.75,6l0-.15s0,0,0,0,0,0,.06,0L9,6a.1.1,0,0,1,0,.14l-.15.19a1.71,1.71,0,0,0-.11.14,13.63,13.63,0,0,1-1.52,0h0a.34.34,0,0,1-.24-.06h0l0,0h0A.2.2,0,0,0,7,6.4a.66.66,0,0,0,.24,0,.54.54,0,0,0,.18-.11.18.18,0,0,0,0-.07.48.48,0,0,0-.06-.58.74.74,0,0,0-.17-.12A1.05,1.05,0,0,0,7,5.37H6.91a2.92,2.92,0,0,1,0-.5.73.73,0,0,0-.09-.32.44.44,0,0,0-.08-.11s0,0-.05,0a.6.6,0,0,0-.13-.09.29.29,0,0,1-.11-.11s0,0,0,0A.28.28,0,0,1,6.08,4h0a.31.31,0,0,1-.19,0,2,2,0,0,0-.49-.09H5.3a.11.11,0,0,1,.09-.06Z"
                    />
                </g>
            </g>
        </svg>
        <svg v-if="kind === 'silver'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.99 13.27">
            <defs></defs>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path
                        class="cls-1s"
                        d="M8.84,12.65c-2.7,1-5.24.93-7.31-1.61-2.28-2.91-2-7.49.83-9.72C5.06-.81,9.93-.55,10.13,3.5,11.59,6.31,11.07,11.35,8.84,12.65Z"
                    />
                    <path
                        class="cls-2s"
                        d="M10,11.71A4.9,4.9,0,0,1,2.62,11C.34,8.07.6,3.5,3.45,1.27,6.14-.86,9.62.33,11.22,3.45A7.26,7.26,0,0,1,10,11.71Z"
                    />
                    <path
                        class="cls-3s"
                        d="M10,11.71A4.9,4.9,0,0,1,2.62,11C.34,8.07.6,3.5,3.45,1.27,6.14-.86,9.62.33,11.22,3.45A7.26,7.26,0,0,1,10,11.71Z"
                    />
                    <path
                        class="cls-4s"
                        d="M9.67,11.09a4.22,4.22,0,0,1-6.38-.73A6.09,6.09,0,0,1,4,2.15C6.3.33,9.25,1.27,10.65,3.92A6.23,6.23,0,0,1,9.67,11.09Z"
                    />
                    <path
                        class="cls-5s"
                        d="M9.67,11.09a4.22,4.22,0,0,1-6.38-.73A6.09,6.09,0,0,1,4,2.15C6.3.33,9.25,1.27,10.65,3.92A6.23,6.23,0,0,1,9.67,11.09Z"
                    />
                    <path
                        class="cls-5s"
                        d="M4.49,10.1A6.12,6.12,0,0,1,5.26,2a3.69,3.69,0,0,1,1.56-.77A4.2,4.2,0,0,0,4,2.15a6.1,6.1,0,0,0-.73,8.21,4.25,4.25,0,0,0,5.08,1.56A4.13,4.13,0,0,1,4.49,10.1Z"
                    />
                    <path
                        v-if="include_logo"
                        class="cls-6s"
                        d="M5.66,9.61h.1a.71.71,0,0,0,.38,0l0,0s0,0,0,0h0a1.61,1.61,0,0,0,.43-.07L7,9.33a.18.18,0,0,0,.07-.05s0,0,0,0,0,0,0,0l.06,0h.13a.18.18,0,0,0,.15-.07s0,0,0,0H7.3a.06.06,0,0,1,0-.06,0,0,0,0,1,0,0h.32a.12.12,0,0,0,.08,0l.1-.12a0,0,0,0,0,0,0s-.06.05-.1.05v0h0l-.06,0s0,0,0-.06a.28.28,0,0,1,.11-.08A1.25,1.25,0,0,0,8,8.47a.44.44,0,0,0,.15-.18L8,8.27a.46.46,0,0,1-.2,0,.17.17,0,0,1-.1-.13.56.56,0,0,1,0-.22.27.27,0,0,1,.13-.13H8l.19.11.05,0a.16.16,0,0,0,0-.1s0,0,0-.06,0,0,0,0a.26.26,0,0,1,.17.09.94.94,0,0,0,.1.1s0,0,.06,0a.27.27,0,0,0-.07-.26l-.08,0-.11-.05a.05.05,0,0,1,0,0,.23.23,0,0,1,0-.18s0,0,0,0l.11-.06h.05l.2,0a.12.12,0,0,1,.08.09l0,.15v0s0,0,.06,0L9,7.35a.11.11,0,0,0,0-.14L8.83,7l-.11-.13a12.23,12.23,0,0,0-1.52,0H7.14a.32.32,0,0,0-.23.06h0l0,0s0,0,0,0a.28.28,0,0,1,.13,0,.66.66,0,0,1,.24,0,.42.42,0,0,1,.18.11s0,0,.06.06a.5.5,0,0,1-.06.58.51.51,0,0,1-.16.13A1.75,1.75,0,0,1,7,8L6.93,8h0a2.92,2.92,0,0,0,0,.5.72.72,0,0,1-.08.32l-.08.12,0,.05-.13.09a.27.27,0,0,0-.1.11l0,0a.26.26,0,0,0-.28.13l0,0a.29.29,0,0,0-.18,0,2,2,0,0,1-.49.09H5.34a.11.11,0,0,0,.09.07Z"
                    />
                    <path
                        v-if="include_logo"
                        class="cls-6s"
                        d="M5.62,3.65l.1,0a.82.82,0,0,1,.38.05s0,0,0,0,0,0,0,0h0a1.29,1.29,0,0,1,.43.07L7,3.91,7,4s0,0,0,0S7,4,7,4l.06,0H7.2a.22.22,0,0,1,.16.07s0,0,0,0l-.08,0s0,0,0,0,0,0,0,0h.32a.12.12,0,0,1,.08,0,.52.52,0,0,0,.1.11,0,0,0,0,1,0,0s-.06,0-.1,0v0h0l-.06,0s0,0,0,.06a.25.25,0,0,0,.12.08A1.47,1.47,0,0,1,8,4.75a.44.44,0,0,1,.15.18L8,5a.65.65,0,0,0-.2,0,.15.15,0,0,0-.09.13.45.45,0,0,0,0,.23.3.3,0,0,0,.13.12.08.08,0,0,0,.08,0,1.18,1.18,0,0,1,.19-.11l0,0a.12.12,0,0,1,0,.1s0,0,0,.06,0,0,0,0a.28.28,0,0,0,.17-.09l.1-.11s0,0,0,0a.28.28,0,0,1-.06.26l-.08,0-.11.06a.05.05,0,0,0,0,0,.32.32,0,0,0,0,.17l0,0,.12,0h.05l.2,0a.1.1,0,0,0,.07-.09l0-.15v0s0,0,.06,0A1.09,1.09,0,0,1,9,5.86.09.09,0,0,1,9,6l-.15.2-.11.13a13.2,13.2,0,0,1-1.52,0h0a.34.34,0,0,1-.24-.06h0l0,0h0a.28.28,0,0,0,.13,0,.51.51,0,0,0,.24,0,.52.52,0,0,0,.18-.1l0-.07a.48.48,0,0,0-.06-.58.41.41,0,0,0-.17-.12A.84.84,0,0,0,7,5.28l-.06,0h0a2.92,2.92,0,0,1,0-.5.73.73,0,0,0-.09-.32.83.83,0,0,0-.08-.12l-.05,0-.13-.1a.21.21,0,0,1-.11-.1s0,0,0,0a.28.28,0,0,1-.29-.13h0a.31.31,0,0,1-.19,0,2,2,0,0,0-.49-.09H5.3s0,0,.09-.07Z"
                    />
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'coin',
    props: {
        include_logo: Boolean,
        kind: String,
    },
    data: () => {
        return {};
    },
    components: {},
    mounted() {},
    methods: {},
};
</script>

<style scoped>
.cls-1 {
    fill: #ed9d11;
}
.cls-2 {
    fill: #fcd165;
}
.cls-3,
.cls-5 {
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 0.57px;
}
.cls-3 {
    stroke: #fcc938;
}
.cls-4 {
    fill: #fcc938;
}
.cls-5 {
    stroke: #eab328;
}
.cls-6 {
    fill: url(#linear-gradient);
}
.cls-7 {
    fill: #f9f9f9;
}
svg {
    /* padding: 1px 1px 1px 1px; */
    /* margin-right: 5px;
    margin-left: 5px; */
}

.cls-1s {
    fill: #7b898f;
}
.cls-2s {
    fill: #fcd165;
}
.cls-3s {
    fill: #a9b0b5;
}
.cls-4s {
    fill: #a5afb5;
}
.cls-5s {
    fill: #d2dbdb;
}
.cls-6s {
    fill: #f9f9f9;
}
</style>
