<template>
	<div>
		<v-dialog v-model="dialog" persistent max-width="600px">
			<template v-if="!signUpShowPrompt" v-slot:activator="{ on, attrs }" style="line-height: 68px">
				<v-btn
					v-if="!$store.state.auth.user.username"
					class="btn-primary"
					height="44px"
					style="display: block; margin-top: 15px; letter-spacing: 0.5px; font-size: 16px"
					v-bind="attrs"
					v-on="on"
				>
					<span class="fa fa-envelope fa-1x" style="padding-right: 15px"></span>
					EMAIL SIGN UP/LOGIN
				</v-btn>
				<!-- <v-btn class="sign-in-btn" v-else height="44px" v-bind="attrs" v-on="on">
					{{ $store.state.auth.user.username }}
				</v-btn> -->
			</template>
			<div class="signin-card">
				<v-card>
					<v-form ref="signin_form" lazy-validation v-model="valid" @submit.prevent="submit">
						<v-card class="v-card--offset mx-auto" color="primary" elevation="4" dark>
							<v-card-text>
								<img
									src="https://trade-raid-com.mo.cloudinary.net/icons/tr-logo-2022.png"
									height="55px"
									alt="trade-raid.com logo"
									style="display: block; margin-left: auto; margin-right: auto"
								/>
							</v-card-text>
						</v-card>

						<v-card-text>
							<span class="text-h6 font-weight-bold center" style="color: #fff"
								>Sign In to Trade-Raid.com</span
							>
							<v-container>
								<v-row>
									<v-col cols="12" sm="6" md="6">
										<v-text-field
											:name="`username_${Math.random()}`"
											v-model="email"
											clearable
											prepend-icon="mdi-email"
											color="white"
											label="Email*"
											outlined
											height="36px"
											dense
											:rules="[
												rules.required,
												rules.email,
												(value) => {
													return this.is_valid_email || this.email_error_message;
												},
											]"
											@keyup="updateEmailErrorMessage"
											autocomplete="off"
										></v-text-field>
									</v-col>

									<v-col cols="12" sm="6" md="6">
										<v-text-field
											:name="`username_${Math.random()}`"
											v-model="password"
											outlined
											height="36px"
											dense
											clearable
											color="white"
											:type="show3 ? 'text' : 'password'"
											prepend-icon="mdi-lock-outline"
											:append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
											@click:append="show3 = !show3"
											label="Password*"
											:rules="[
												rules.required,
												(value) => {
													return this.is_valid_password || this.password_error_message;
												},
											]"
											@keyup="updatePasswordErrorMessage"
											autocomplete="off"
										></v-text-field>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-row>
								<v-col cols="12" md="6">
									<p style="color: white">
										Don't have a Trade-Raid account ?
										<a
											style="color: #17d0c1; text-decoration: underline"
											href="javascript:void(0)"
											@click="showSignUpModel"
											>Sign Up here</a
										>
										<a
											style="color: #17d0c1; padding-left: 10px; text-decoration: underline"
											href="javascript:void(0)"
											@click="showForgotPasswordModal"
											>Forgot password</a
										>
									</p>
								</v-col>
								<v-col cols="12" md="6">
									<v-btn
										color="#e66767"
										class="justify-center cancel-btn mr-3"
										@click="dialog = false"
									>
										Close
									</v-btn>
									<v-btn
										color="#17D0C1"
										type="submit"
										class="signin-btn mr-3"
										:loading="is_login_submit"
									>
										Sign in
									</v-btn>
								</v-col>
							</v-row>
						</v-card-actions>
					</v-form>
				</v-card>
			</div>
		</v-dialog>
		<v-dialog v-model="forgotpassword_dialog" persistent max-width="600px">
			<div class="signup-card">
				<v-card>
					<v-form ref="signup_form" lazy-validation v-model="valid_sign_up" @submit.prevent="submitSignUp">
						<v-card class="v-card--offset mx-auto" color="primary" elevation="4" dark>
							<v-card-text>
								<img
									src="https://trade-raid-com.mo.cloudinary.net/icons/tr-logo-2022.png"
									height="55px"
									alt="trade-raid.com logo"
									style="display: block; margin-left: auto; margin-right: auto"
								/>
							</v-card-text>
						</v-card>
						<v-card-text>
							<v-container>
								<v-text-field
									:name="`username_${Math.random()}`"
									v-model="email"
									label="Email*"
									type="email"
									outlined
									clearable
									prepend-icon="mdi-email"
									color="white"
									height="36px"
									dense
									@keyup="updateSignupEmailErrorMessage()"
									:rules="[
										rules.required,
										rules.email,
										(value) => {
											return this.is_unique_email || this.email_error_message;
										},
										(value) => {
											return (
												this.is_valid_email_domain ||
												'Email address is not valid. Please use non-disposable email'
											);
										},
									]"
									autocomplete="off"
								></v-text-field>
							</v-container>
							<p style="color: white">
								Submit your email here and we will send you an email with further instructions
							</p>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="#e66767" depressed @click="forgotpassword_dialog = false"> Close </v-btn>
							<v-btn color="#17D0C1" depressed :loading="is_submitting" @click="submitForgottenPassword">
								Submit
							</v-btn>
						</v-card-actions>
					</v-form>
				</v-card>
			</div>
		</v-dialog>
		<v-dialog v-model="mail_feedback" persistent max-width="600px">
			<div class="mail_feedback">
				<p style="color: white">
					We've sent an email to {{ email }} with instructions on how to reset your password
				</p>
				<v-card-actions>
					<v-btn color="#e66767" depressed @click="mail_feedback = false"> Thanks </v-btn>
				</v-card-actions>
			</div>
		</v-dialog>
		<v-dialog v-model="signup_dialog" persistent max-width="600px">
			<div class="signup-card">
				<v-card>
					<v-form ref="signup_form" lazy-validation v-model="valid_sign_up" @submit.prevent="submitSignUp">
						<v-card class="v-card--offset mx-auto" color="primary" elevation="4" dark>
							<v-card-text>
								<img
									src="https://trade-raid-com.mo.cloudinary.net/icons/tr-logo-2022.png"
									height="55px"
									alt="trade-raid.com logo"
									style="display: block; margin-left: auto; margin-right: auto"
								/>
							</v-card-text>
						</v-card>
						<v-card-text>
							<div v-if="signUpShowPromptMsg" class="signup-prompt-msg">
								{{ signUpShowPromptMsg }}
							</div>
							<v-container>
								<v-row>
									<v-col cols="12" sm="6" md="6">
										<v-text-field
											:name="`username_${Math.random()}`"
											label="Trade-Raid User name*"
											v-model="username"
											outlined
											clearable
											prepend-icon="mdi-account"
											height="36px"
											color="white"
											dense
											:rules="[
												rules.required,
												(value) => {
													return this.is_unique_username || this.username_error_message;
												},
											]"
											@keyup="updateUserNameErrorMessage()"
											autocomplete="off"
										></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-text-field
											:name="`username_${Math.random()}`"
											v-model="email"
											label="Email*"
											type="email"
											outlined
											clearable
											prepend-icon="mdi-email"
											color="white"
											height="36px"
											dense
											@keyup="updateSignupEmailErrorMessage()"
											:rules="[
												rules.required,
												rules.email,
												(value) => {
													return this.is_unique_email || this.email_error_message;
												},
												(value) => {
													return (
														this.is_valid_email_domain ||
														'Email address is not valid. Please use non-disposable email'
													);
												},
											]"
											autocomplete="off"
										></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="6" md="6">
										<v-text-field
											:name="`username_${Math.random()}`"
											v-model="password"
											label="Password*"
											outlined
											:type="show4 ? 'text' : 'password'"
											prepend-icon="mdi-lock-outline"
											:append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
											height="36px"
											dense
											@click:append="show4 = !show4"
											color="white"
											:rules="[rules.required]"
											autocomplete="off"
										></v-text-field>
									</v-col>

									<v-col cols="12" sm="6" md="6" class="custom-autocomplete-styler">
										<v-autocomplete
											label="Country*"
											v-model="country"
											:items="countries"
											:name="`country_${Math.random()}`"
											:rules="[rules.required]"
											color="white"
											class="input-country"
											prepend-icon="mdi-map-marker"
											outlined
											height="36px"
											dense
											:menu-props="{ offsetY: true }"
											append-icon=""
											autocomplete="off"
										></v-autocomplete>
									</v-col>
								</v-row>
							</v-container>
							<p style="color: white">
								By signing up for Trade-Raid, you agree to our
								<a
									href="https://trade-raid.com/terms"
									target="_blank"
									style="color: #17d0c1; text-decoration: underline"
									>terms of use</a
								>
								and
								<a
									href="https://trade-raid.com/privacy-policy"
									target="_blank"
									style="color: #17d0c1; text-decoration: underline"
									>privacy policy</a
								>.
							</p>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="#e66767" depressed @click="signup_dialog = false"> Close </v-btn>
							<v-btn color="#17D0C1" depressed type="submit" :loading="is_submitting">
								Create account
							</v-btn>
						</v-card-actions>
					</v-form>
				</v-card>
			</div>
		</v-dialog>
	</div>
</template>

<script>
import axios from 'axios';
import store from '../../store';
import { setToken, setClientToken } from '../../utils/auth';
import Countries from '@/assets/json/countries.json';

export default {
	props: {
		userHasNoEmail: Boolean,
		signUpShowPrompt: Boolean,
		signUpShowPromptMsg: String,
		emailFromOrder: String,
		orderId: Number,
	},
	data() {
		return {
			dialog: false,
			mail_feedback: false,
			signup_dialog: false,
			forgotpassword_dialog: false,
			email: '',
			password: '',
			valid: false,
			orderFromSignUp: null,
			is_valid_email: true,
			email_error_message: '',
			is_valid_password: true,
			is_submitting: false,
			is_login_submit: false,
			password_error_message: '',
			countries: [],
			country: '',
			is_unique_username: true,
			username_error_message: '',
			is_unique_email: true,
			password: '',
			username: '',
			valid_sign_up: false,
			is_valid_email_domain: true,
			show3: false,
			show4: false,
			rules: {
				required: (value) => !!value || 'Input field is required',
				email: (value) => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Invalid e-mail. Please enter valid email.';
				},
			},
		};
	},
	watch: {
		userHasNoEmail(nval, oval) {
			if (nval) {
				this.dialog = true;
			}
		},
	},
	mounted() {
		if (this.$store.state.auth.redirect) {
			this.dialog = true;
		}
		if (this.userHasNoEmail) {
			this.dialog = true;
		}
		this.countries = Countries.map((country) => {
			return country.name;
		});
		if (this.signUpShowPrompt) {
			this.signup_dialog = true;
			this.email = this.emailFromOrder;
			this.orderFromSignUp = this.orderId;
		}
	},
	methods: {
		submit() {
			if (this.$refs.signin_form.validate()) {
				this.is_login_submit = true;

				var data = { email: this.email, password: this.password };

				let url = process.env.VUE_APP_BACKEND + `/exchange/login`;
				let token = process.env.VUE_APP_KEY_BACKEND;
				axios
					.post(url, { data: data, token: token })
					.then((response) => {
						this.is_login_submit = false;
						response = response.data;
						if (response.status == 'error') {
							for (var messageKey in response.message) {
								var message = response.message[messageKey];

								if (messageKey == 'email') {
									this.is_valid_email = false;
									this.email_error_message = message;
								} else if (messageKey == 'password') {
									this.is_valid_password = false;
									this.password_error_message = message;
								}
							}
							this.$refs.signin_form.validate();
						} else if (response.is_banned) {
							this.is_valid_email = false;
							this.email_error_message = 'User is banned from using the service';
						} else {
							this.valid = true;
							store.commit('SET_TOKEN', response.token);
							store.commit('SET_USER', response);
							setClientToken(response._id);
							this.dialog = false;
							setToken(response.token);
							// Made for YCombinator
							if (response.email == 'asger@trade-raid.com') {
								this.$router.push('/trade');
							} else {
								this.$router.push('/exchange');
							}
						}
					})
					.catch((error) => {
						console.log(error);
					});
			}
		},
		updatePasswordErrorMessage() {
			this.is_valid_password = true;
			this.password_error_message = '';

			this.$refs.signin_form.validate();
		},
		updateEmailErrorMessage() {
			this.is_valid_email = true;
			this.email_error_message = '';

			this.$refs.signin_form.validate();
		},
		async submitForgottenPassword() {
			const url = process.env.VUE_APP_BACKEND + '/user/forgot-password/link';
			try {
				const res = await axios.post(url, { email: this.email });
				this.forgotpassword_dialog = false;
				this.mail_feedback = true;
			} catch (error) {
				console.log(error);
			}
		},
		showForgotPasswordModal() {
			this.dialog = false;
			this.forgotpassword_dialog = true;
		},
		showSignUpModel() {
			this.dialog = false;
			this.signup_dialog = true;
		},
		async submitSignUp() {
			if (this.$refs.signup_form.validate()) {
				this.is_submitting = true;
				await axios
					.get(`https://disposable.debounce.io/?email=${this.email}`)
					.then((res) => {
						this.is_submitting = false;
						this.signup_dialog = false;
						if (res.data.disposable && (res.data.disposable == true || res.data.disposable == 'true')) {
							this.is_valid_email_domain = false;
							this.$refs.signup_form.validate();
							return false;
						}
					})
					.catch((err) => {
						console.log(err);
					});

				if (this.is_valid_email_domain) {
					let data = {
						email: this.email,
						password: this.password,
						country: this.country,
						username: this.username,
						orderId: null,
					};

					if (this.signUpShowPrompt && this.orderFromSignUp) {
						data.orderId = this.orderFromSignUp;
					}

					let url = process.env.VUE_APP_BACKEND + '/exchange/registration';
					let token = process.env.VUE_APP_KEY_BACKEND;
					axios
						.post(url, { data: data, token: token })
						.then((response) => {
							this.is_submitting = false;
							response = response.data;

							store.commit('SET_TOKEN', response.token);
							store.commit('SET_USER', response);
							setToken(response.token);
							this.$store.dispatch('getInfo');
							if (response.status == 'error') {
								for (var messageKey in response.message) {
									var message = [...response.message[messageKey]].shift();
									if (messageKey == 'email') {
										this.is_unique_email = false;
										this.email_error_message = message;
									} else if (messageKey == 'username') {
										this.is_unique_username = false;
										this.username_error_message = message;
									}
								}
								this.$refs.signup_form.validate();
							} else {
								store.commit('SET_TOKEN', response.token);
								store.commit('SET_USER', response);
								this.$store.dispatch('getInfo');
								console.log(response, 'REGISTRATION');
								setClientToken(response.id);
								setToken(response.token);
							}
						})
						.catch(function (error) {
							console.log('error: ' + error);
						});
				}
			}
		},
		updateUserNameErrorMessage() {
			this.is_unique_username = true;
			this.username_error_message = '';

			this.$refs.signup_form.validate();
		},
		updateSignupEmailErrorMessage() {
			this.is_unique_email = true;
			this.is_valid_email_domain = true;
			this.email_error_message = '';

			this.$refs.signup_form.validate();
		},
	},
};
</script>
<style>
.mail_feedback {
	background-color: #555c70;
	padding: 25px 25px 25px 25px;
}
.signin-card {
	overflow: hidden !important;
}
.signin-card .theme--light.v-input input,
.theme--light.v-input textarea,
.signup-card .theme--light.v-input input,
.theme--light.v-input textarea {
	color: #fff !important;
}
.signin-card .v-label.theme--light,
.signup-card .v-label.theme--light {
	color: rgba(255, 255, 255, 0.5);
}
.signin-card
	.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
	> .v-input__control
	> .v-input__slot
	fieldset,
.signin-card
	.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
	> .v-input__control
	> .v-input__slot:hover
	fieldset,
.signup-card
	.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
	> .v-input__control
	> .v-input__slot
	fieldset,
.signup-card
	.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
	> .v-input__control
	> .v-input__slot:hover
	fieldset {
	color: #555c70;
}
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
	color: #333 !important;
}
</style>
<style lang="scss" scoped>
.v-autocomplete__content > .theme--light > .theme--light > .v-list-item__content .v-list-item__title {
	color: black;
}
.input-country {
}
.custom-autocomplete-style {
	color: #333;
}

.signup-prompt-msg {
	color: #f1f1f1;
}

.active_list .v-list-group .v-list-item--active {
	color: black !important;
}
.signin-card {
	.v-card {
		background: #383f51;
		border-radius: 3px;
		border-width: 0;
		height: 100%;

		.v-card__title {
			background: #555c70;
			padding: 20px;
			font-size: 16px;
			line-height: 20px;
			color: #ffffff;
		}

		.v-card__text {
			padding: 30px 20px;
			overflow: auto;
		}
		.v-text-field__slot .v-label {
			color: #fff;
		}
		input::placeholder {
			font-size: 13px !important;
			line-height: 18px !important;
			color: rgba(255, 255, 255, 0.5) !important;
		}
		.theme--light.v-input input,
		.theme--light.v-input textarea {
			color: #fff !important;
		}
		label.v-label.v-label--active.theme--light,
		label.v-label.theme--light {
			color: #555c70;
		}
	}
}

.btn-primary {
	background-color: #17d0c1 !important;
	border: 1px solid #17d0c1 !important;
	font-weight: bold;
	color: #ffffff !important;
	box-shadow: 0.15s ease-in-out;
	-webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
	background-color: #2443ac !important;
	border-color: #2443ac !important;
	color: #ffffff !important;
}
.signup-card {
	.v-card {
		background: #383f51;
		border-radius: 3px;
		border-width: 0;
		height: 100%;

		.v-card__title {
			background: #555c70;
			padding: 20px;
			font-size: 16px;
			line-height: 20px;
			color: #ffffff;
		}

		.v-card__text {
			padding: 30px 20px;
			overflow: auto;
		}
	}

	input::placeholder {
		font-size: 13px !important;
		line-height: 18px !important;
		color: rgba(255, 255, 255, 0.5) !important;
	}
	.theme--light.v-list-item:not(.v-list-item--disabled) {
		// color: #fff !important;
	}
	.theme--light.v-select .v-select__selection--comma {
		color: #fff;
	}
	.v-menu__content .v-list {
		background: #555c70;
	}
	.theme--light.v-input input {
		color: #fff;
	}
	label.v-label.v-label--active.theme--light {
		color: #555c70;
	}
	.theme--light.v-label {
		color: #555c70;
	}
}
</style>
