<template>
    <span>{{ formatNumber }}</span>
</template>

<script>
import gsap from 'gsap';
import PriceService from '../../../utils/PriceService.js';
export default {
    name: 'animate-integer',
    props: ['value'],
    components: {},
    data() {
        return {
            displayValue: this.value,
            tweenValue: this.value,
        };
    },
    computed: {
        formatNumber() {
            return PriceService.abbreviateNumber(this.displayValue);
        },
    },
    watch: {
        value() {
            gsap.to(this, {
                tweenValue: this.value,
                onUpdate: () => {
                    this.displayValue = Math.ceil(this.tweenValue);
                },
            });
        },
    },
};
</script>
