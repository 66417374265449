<template>
    <div v-if="trade_amounts">
        <v-dialog v-model="show" persistent width="500">
            <v-card color="#555C70">
                <v-card-title class="headline modal grey text-white"> Gold sum is too low </v-card-title>

                <v-card-text class="modal text-white">
                    Your total gold sum is under Trade-Raid's minimum<br />trade amounts: <br /><br />
                    The War Within: {{ trade_amounts['retail'] }} Gold <br />
                    Cataclysm Classic: {{ trade_amounts['cataclysm classic'] }} Gold<br />
                    Classic Era: {{ trade_amounts.SoM }} <br />
                    Classic Hardcore: {{ trade_amounts['Classic Hardcore'] }} Gold <br />
                    Season of Discovery: {{ trade_amounts['season of discovery'] }} Gold <br />
                    Please add more items to your trade.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed color="#17D0C1" @click="showModal = false"> Okay </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { getTradeAmountTradeService } from '../../../api/services.js';
export default {
    name: 'trade-amount-modal',
    props: {
        showModal: Boolean,
    },
    components: {},
    data() {
        return {
            trade_amounts: null,
        };
    },
    methods: {
        // hideModal() {
        //     this.showModal = false;
        // },
    },
    computed: {
        show() {
            return this.showModal;
        },
    },
    // watch: {
    //     showModal: {
    //         deep: true,
    //         handler(val, oval) {
    //             this.show = val;
    //         }
    //     }
    // },
    mounted: async function () {
        this.trade_amounts = (await getTradeAmountTradeService()).data;
    },
};
</script>
