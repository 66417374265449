import axios from 'axios';
import store from '@/store';
import { getToken } from './auth';

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    timeout: 60000, // request timeout
});

// request interceptor
// const token = getToken()
service.interceptors.request.use(
    (config) => {
        // do something before request is sent

        // if (store.getters.token) {
        //     console.log('store getts token', store.getters.token)
        //     config.headers['Authorization'] = `Bearer ${store.getters.token}`;
        // }
        config.headers['X-Requested-With'] = `XMLHttpRequest`;
        return config;
    },
    (error) => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    },
);

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (response) => {
        const res = response;

        // if the custom code is not 20000, it is judged as an error.

        return res;
    },
    (error) => {
        console.log('err' + error); // for debug
        return Promise.reject(error.response);
    },
);

export default service;
