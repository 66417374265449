<template>
    <button v-if="!user.steam_id" @click="connect" data-v-2f029f00="" type="button" class="connect">
        <span class="v-btn__content"
            ><img
                data-v-2f029f00=""
                src="https://trade-raid-com.mo.cloudinary.net/icons/steam.svg"
                style="display: block; margin-right: 15px"
                alt="Connect with Steam to trade MMO Gold for Steam items on trade-raid.com"
            />
            SIGN IN WITH STEAM
        </span>
    </button>
</template>

<script>
import { getSteamLink } from '../../api/auth';
import { mapGetters } from 'vuex';
export default {
    name: 'ConnectSteam',
    props: {
        userObj: Object,
    },
    components: {},
    data() {
        return {};
    },
    methods: {
        async connect() {
            await getSteamLink()
                .then((res) => {
                    window.open(res.data.url, '_self');
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    computed: {
        ...mapGetters(['user', 'token']),
    },
    mounted: function () {},
};
</script>

<style scoped>
.connect {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #17d0c1 !important;
    font-size: 18px;
    color: #f1f1f1;
    padding: 15px;
    border-radius: 5px;
    height: 45px;
    font-weight: bold;
}
.connect > span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.connect:hover {
    background-color: #2443ac !important;
    border-color: #2443ac !important;
    color: #fff !important;
}
</style>
