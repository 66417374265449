<template>
    <div class="game">
        <img
            :style="`width:  ${images[game].width || icon_width}px;`"
            class="game-icon-mmo"
            :src="getSrc(game)"
            :alt="`${game}`"
        />
    </div>
</template>

<script>
export default {
    /** This is GameIcons for MMO's */
    name: 'MMOGameIcons',
    props: {
        game: String,
        icon_width: {
            type: String,
        },
    },
    data() {
        return {
            images: {
                'new-world': { src: 'https://trade-raid-com.mo.cloudinary.net/game-icons/new-world.png', width: 30 },
                'the-elder-scrolls-online': {
                    src: 'https://trade-raid-com.mo.cloudinary.net/game-icons/teso.png',
                    width: 30,
                },
                'final-fantasy-xiv': {
                    src: 'https://trade-raid-com.mo.cloudinary.net/game-icons/xivlogo.png',
                    width: 60,
                },
                'final fantasy xiv': {
                    src: 'https://trade-raid-com.mo.cloudinary.net/game-icons/xivlogo.png',
                    width: 60,
                },
                'lost ark': { src: 'https://trade-raid-com.mo.cloudinary.net/game-icons/lost-ark.png', width: 30 },
                'lost-ark': { src: 'https://trade-raid-com.mo.cloudinary.net/game-icons/lost-ark.png', width: 30 },
                'wow-retail': {
                    src: 'https://trade-raid-com.mo.cloudinary.net/game-icons/dragonflight-logo-icon.png',
                    width: 30,
                },
                'wow-classic': { src: 'https://trade-raid-com.mo.cloudinary.net/game-icons/wotlk.png', width: 30 },
                'the war within': {
                    src: 'https://trade-raid-com.mo.cloudinary.net/game-icons/dragonflight-logo-icon.png',
                    width: 30,
                },
                'wotlk classic': { src: 'https://trade-raid-com.mo.cloudinary.net/wotlk-logo.png', width: 30 },
                'wow-som': { src: 'https://trade-raid-com.mo.cloudinary.net/game-icons/wow-logo.png', width: 30 },
                'classic era': { src: 'https://trade-raid-com.mo.cloudinary.net/game-icons/wow-logo.png', width: 30 },
                wotlk: { src: 'https://trade-raid-com.mo.cloudinary.net/wotlk-logo.png', width: 30 },
                tbc: { src: 'https://trade-raid-com.mo.cloudinary.net/game-icons/tbc_wow.png', width: 30 },
                all: { src: 'https://trade-raid.com/favicon.ico', width: 30 },
                default: { src: 'https://trade-raid-com.mo.cloudinary.net/tr-logo-icon.png', width: 25 },
                'trade-protection': { src: 'https://trade-raid.com/favicon.ico', width: 30 },
            },
        };
    },
    watch: {},
    computed: {},
    methods: {
        getSrc(game) {
            let g = game.toLowerCase();
            console.log({ g });
            if (this.images[g].src) {
                return this.images[g].src;
            }
            return this.images['default'].src;
        },
        computeWidth(game) {},
    },
    mounted: function () {},
};
</script>

<style scoped>
.game-icon-mmo {
    /* min-width: 30px; */
    /* max-width: 30px; */
    /* transition:all .1; */
}
</style>
