<template>
    <v-container fluid fill-height id="about">
        <!-- Det er dette der afgør hvilken CSS den skal bruge -->
        <h1 class="page-title">Cookie Policy</h1>
        <p class="page-sub-title">
            Last updated: January 20th 2022<br />
            <br />Trade-raid.com ("us", "we", or "our") uses cookies on Trade-raid.com (the "Service"). <br />By using
            the Service, you consent to the use of cookies.<br />
            Our Cookie Policy explains what cookies are, how we use cookies, how third-parties (like Steam) may use
            cookies on the Service, your choices regarding cookies and further information about cookies.<br />
            <br />What are cookies:<br />
            Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in
            your web browser and allows the Service or a third-party to recognize you and make your next visit easier
            <br />and the Service more useful to you.<br />
            Cookies can be "persistent" or "session" cookies.<br />
            <br />How Trade-raid.com uses cookies:<br />
            When you use and access the Service, we may place a number of cookies files in your web browser.<br />
            We use cookies for the following purposes: to enable certain functions of the Service, to provide
            analytics,<br />or to store your preferences.<br />
            We use both session and persistent cookies on the Service and we use different types of cookies to run the
            Service:<br />
            <br />Essential cookies. We may use essential cookies to authenticate users and prevent fraudulent use of
            user accounts.

            <br />Third-party cookies. In addition to our own cookies, we may also use third-parties cookies (like
            Steam) to see your items and deliver our Service.<br />
            <br />Where can your find more information about cookies:<br />
            Disabling cookies may reduce the quality of your experience on the site. If you continue to use
            Trade-raid.com without changing your browser settings, we will assume that you are satisfied with receiving
            all cookies. Most browsers provide the ability to manage cookies through settings.<br />
            Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all
            of the features we offer, you may not be able to store your preferences, and some of our pages might not
            display properly.<br />

            <br />Please follow links below to see articles on cookie management for some browsers:<br />
            <br /><a target="_blank" href="https://support.google.com/chrome/answer/95647?hl=en">Google Chrome</a>
            <br /><a target="_blank" href="https://support.microsoft.com/en-us/kb/260971">Internet Explorer</a> <br /><a
                target="_blank"
                href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                >Mozilla Firefox</a
            >
            <br /><a target="_blank" href="http://www.opera.com/help">Opera</a>
        </p>
    </v-container>
</template>

<script>
export default {
    name: 'Cookie Policy',
    metaInfo: {
        title: 'Cookie Policy',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content: 'Trade-Raids cookie policy',
            },
        ],
    },
};
</script>

<style lang="scss" scoped>
#about {
    padding-top: calc(80px + 100px);
    padding-bottom: 124px;
    flex-direction: column;
    align-content: center;
    background: linear-gradient(
        90deg,
        rgba(0, 14, 25, 0.1) 0%,
        rgba(15, 24, 45, 0.1) 30.7%,
        rgba(62, 0, 108, 0.1) 60.29%,
        rgba(11, 72, 67, 0.1) 79.69%,
        rgba(15, 24, 45, 0.1) 95.48%
    );

    .page-title {
        width: 100%;
        font-weight: 600;
        font-size: 36px;
        line-height: 49px;
        color: #fff;
        text-align: center;
    }

    .page-sub-title {
        text-align: center;
        width: 100%;
        max-width: 963px;
        font-size: 18px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 70px;
    }

    .about-container {
        width: 100%;
        max-width: 1530px;

        .image-container {
            width: 100%;
            height: 100%;
            max-height: 400px;
            min-height: 400px;
            margin-bottom: 80px;
            background: url('https://trade-raid-com.mo.cloudinary.net/dota.jpeg') no-repeat;
            background-size: cover;
            background-position: 0 -108px;
        }

        .info-container {
            h2 {
                font-weight: 600;
                font-size: 36px;
                line-height: 60px;
                color: #fff;
            }

            .desc {
                font-size: 18px;
                line-height: 35px;
                color: rgba(255, 255, 255, 0.7);
                margin-bottom: 30px;
            }

            a {
                border: 1px solid #ffffff;
                box-sizing: border-box;
                border-radius: 3px;
                font-family: Open Sans;

                font-weight: bold;
                font-size: 13px;
                line-height: 18px;
                text-transform: uppercase;

                color: #ffffff;
                text-decoration: none;
                padding: 13px 30px;

                display: inline-block;
            }

            a + a {
                margin-left: 20px;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    #about .about-container .info-container h2,
    #about .about-container .info-container .desc {
        text-align: center;
    }
}
</style>
