<template>
    <v-container fluid fill-height id="about">
        <h1 class="page-title">Thanks for signing up!</h1>
        <button-links></button-links>
        <br /><br />
        <div class="divdiv" style="">
            <img
                class="divimg"
                src="https://trade-raid-com.mo.cloudinary.net/icons/trade_raid_made.png"
                alt="Trade-raid.com Denmark image"
            />
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'Signup Page',
    metaInfo: {
        title: 'Signup Page',
    },
};
</script>

<style lang="scss" scoped>
// * {
// padding: 0px;
// margin: 0px;
// }
// body{
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   min-height: 100vh;
//   background: #00071C;
// }'
a {
    text-decoration: none !important;
    border: none !important;
}
.s-link {
    color: black !important;
}
ul {
    position: relative;
    display: flex;
    // transform: rotate(-8deg) skew(8deg);
    transform-style: preserve-3d;
    margin-top: -100px;
}
ul li {
    position: relative;
    list-style: none;
    width: 60px;
    height: 60px;
    margin: 0px 20px;
    left: 100px;
    top: 100px;
}
ul li:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: -5px;
    width: 100%;
    height: 10px;
    background: #2a2a2a;
    transform-origin: top;
    transform: skewX(-41deg);
}
ul li:after {
    content: '';
    position: absolute;
    top: 5px;
    left: -9px;
    width: 9px;
    height: 100%;
    background: #2a2a2a;
    transform-origin: right;
    transform: skewY(-49deg);
}
ul li span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex !important;
    background: #2a2a2a;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 30px !important;
    transition: 1.5s ease-out;
}
ul li:hover span {
    z-index: 1000;
    transition: 0.3s;
    color: #fff;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.5);
}
ul li:hover span:nth-child(5) {
    transform: translate(40px, -40px);
    opacity: 1;
}
ul li:hover span:nth-child(4) {
    transform: translate(30px, -30px);
    opacity: 0.8;
}
ul li:hover span:nth-child(3) {
    transform: translate(20px, -20px);
    opacity: 0.6;
}
ul li:hover span:nth-child(2) {
    transform: translate(10px, -10px);
    opacity: 0.4;
}
ul li:hover span:nth-child(1) {
    transform: translate(0px, 0px);
    opacity: 0.2;
}
ul li:nth-child(1):hover span {
    background: #2c3456 !important;
}
ul li:nth-child(2):hover span {
    background: #52e19f !important;
}
ul li:nth-child(3):hover span {
    background: #ea6e96 !important;
}
ul li:nth-child(4):hover span {
    background: #fceb00 !important;
}
.post {
    display: grid;
    grid-template-columns:
        minmax(1.2rem, 1fr)
        minmax(auto, 57ch)
        minmax(1.2rem, 1fr);
}
#about {
    padding-top: calc(80px + 100px);
    padding-bottom: 124px;
    flex-direction: column;
    align-content: center;
    background: linear-gradient(
        90deg,
        rgba(0, 14, 25, 0.1) 0%,
        rgba(15, 24, 45, 0.1) 30.7%,
        rgba(62, 0, 108, 0.1) 60.29%,
        rgba(11, 72, 67, 0.1) 79.69%,
        rgba(15, 24, 45, 0.1) 95.48%
    );
    .page-title {
        width: 100%;
        font-weight: 600;
        font-size: 36px;
        line-height: 49px;
        color: #fff;
        text-align: center;
    }
    .button-socials {
        display: inline-block;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        border-radius: 3px;
        color: white;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        text-decoration: none;
        padding: 13px 35px 13px 55px;
        position: relative;
        margin-right: 20px;
    }
    .facebook:before {
        content: '';
        position: absolute;
        top: calc(50% - 10px);
        left: 25px;
        width: 20px;
        height: 20px;
        background: url('https://trade-raid-com.mo.cloudinary.net/home-icons/facebook_icon.png') no-repeat 0 0;
    }
    .steam:before {
        content: '';
        position: absolute;
        top: calc(50% - 10px);
        left: 20px;
        width: 20px;
        height: 20px;
        background: url('https://trade-raid-com.mo.cloudinary.net/home-icons/steam_icon.png');
    }
    h2 {
        width: 100%;
        font-weight: 600;
        font-size: 26px;
        line-height: 49px;
        color: #fff;
        text-align: center;
    }
    .page-sub-title {
        text-align: center;
        width: 100%;
        max-width: 963px;
        font-size: 18px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 70px;
    }
    .divdiv {
        width: 600px;
        height: 250px;
    }
    .divimg {
        display: block;
        width: 100%;
    }
    .about-container {
        width: 100%;
        max-width: 1530px;
        .image-container {
            width: 100%;
            height: auto;
            max-height: 400px;
            min-height: 400px;
            margin-bottom: 80px;
            background: url('https://trade-raid-com.mo.cloudinary.net/dota.jpeg') no-repeat;
            background-size: cover;
            background-position: 0 -108px;
        }
        .info-container {
            h2 {
                font-weight: 600;
                font-size: 36px;
                line-height: 60px;
                color: #fff;
            }
            .desc {
                font-size: 18px;
                line-height: 35px;
                color: rgba(255, 255, 255, 0.7);
                margin-bottom: 30px;
            }
            a {
                border: 1px solid #ffffff;
                box-sizing: border-box;
                border-radius: 3px;
                font-family: Open Sans;
                font-weight: bold;
                font-size: 13px;
                line-height: 18px;
                text-transform: uppercase;
                color: #ffffff;
                text-decoration: none;
                padding: 13px 30px;
                display: inline-block;
            }
            a + a {
                margin-left: 20px;
            }
        }
    }
}
@media screen and (max-width: 960px) {
    #about .about-container .info-container h2,
    #about .about-container .info-container .desc {
        text-align: center;
    }
}
</style>
