<template>
    <v-btn class="shopping-cart disable-button-background static-width-220" v-if="disabled">
        <div class="shopping-cart-content">
            <ShoppingCartIcon />
            <div>Add to Cart</div>
        </div>
    </v-btn>
    <v-btn v-else class="shopping-cart background-color-gradient static-width-220">
        <div class="shopping-cart-content">
            <ShoppingCartIcon />
            <div>Add to Cart</div>
        </div>
    </v-btn>
</template>

<script>
import './utility.styles.css';
export default {
    name: 'shopping-cart-module',
    props: {
        disabled: Boolean,
    },
    data() {
        return {
            aside: false,
        };
    },
    computed: {},
    methods: {},
};
</script>

<style scoped>
* {
    transition: all 1s;
}
</style>
