import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.user.email)?_c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"persistent":"","width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"color":"#555C70"}},[_c(VCardTitle,{staticClass:"headline modal grey text-white"},[_vm._v("Add email and password to secure your account")]),_c(VCardText,{staticClass:"modal text-white"},[_c('p',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.explainer)+" ")]),(_vm.error)?_c('p',{staticClass:"error error-msg"},[_vm._v(_vm._s(_vm.error_msg))]):_vm._e(),_c(VTextField,{attrs:{"name":`username_${Math.random()}`,"label":"Email*","type":"email","outlined":"","clearable":"","prepend-icon":"mdi-email","color":"white","height":"36px","dense":"","autocomplete":"off","rules":[_vm.rules.required, _vm.rules.email]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"name":`username_${Math.random()}`,"label":"Password*","outlined":"","type":_vm.show4 ? 'text' : 'password',"prepend-icon":"mdi-lock-outline","append-icon":_vm.show4 ? 'mdi-eye' : 'mdi-eye-off',"height":"36px","dense":"","color":"white","rules":[_vm.rules.requiredPassword],"autocomplete":"off"},on:{"click:append":function($event){_vm.show4 = !_vm.show4}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCardActions,[_c(VSpacer),(_vm.showDismissable)?_c(VBtn,{staticClass:"signin-btn mr-3",attrs:{"color":"#17D0C1"},on:{"click":function($event){return _vm.dismiss()}}},[_vm._v("Remind me later")]):_vm._e(),_c(VBtn,{staticClass:"signin-btn",attrs:{"color":"#17D0C1"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Secure account")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }