<template>
    <div v-if="$store.state.shop.aside === true" class="shopping-cart-aside-module">
        <div class="title color-white">
            <div class="flex-inline top-container flex-even">
                <div class="justify-content" style="width: 90%">
                    Shopping Cart <span class="color-grey">{{ computedItemsLength }} items</span>
                </div>
                <XIcon class="icon" @click="closeCart()" />
            </div>
        </div>
        <div v-if="computedCartItems.length">
            <div class="cart-container">
                <div class="cart-items">
                    <div
                        class="cart-item background-color-darkgreen"
                        v-for="(item, index) in computedCartItems"
                        :key="index"
                    >
                        <div class="title-medium color-white bold flex-inline">
                            <img class="item-image" :src="item.backgroundImage" alt="" />
                            <div class="item-title"></div>
                            <div class="item-header bold">
                                <div class="item-title flex-inline">
                                    {{ item.title }}
                                </div>
                                <div class="item-price">{{ $store.state.shop.activeCurrency }} {{ item.price }}</div>
                            </div>
                        </div>
                        <div class="trade-info">
                            <div class="flex-inline color-white" v-if="item.type === 'leveling'">
                                Level: {{ item.info.level_proximity }} {{ item.info.faction }} [{{ item.info.region }}]
                                {{ item.info.boost_method }} {{ item.info.execution_speed }}
                            </div>
                            <div
                                class="flex-inline color-white"
                                v-if="item.type === 'gold' && item.info && item.info.gold_receive"
                            >
                                Receive:
                                <span>
                                    {{ item.info.gold_receive }}
                                </span>
                                <Coin
                                    class="cost mr-1"
                                    kind="gold"
                                    style="width: 15px; height: auto"
                                    :include_logo="false"
                                />
                            </div>
                            <div class="flex-inline color-white" v-if="item.type === 'gold'">
                                <span>{{ item.info.server }} [{{ item.info.region }}] {{ item.info.faction }}</span>
                            </div>
                            <div class="flex-inline color-white" v-if="item.type === 'gold'">
                                <span>
                                    {{ item.info.delivery_info.type }}
                                    <span v-if="item.info.delivery_info.item"
                                        >({{ item.info.delivery_info.item }})</span
                                    >
                                    {{ item.info.delivery_info.character_name }}
                                </span>
                            </div>
                            <div v-if="item.info.options" class="flex-inline custom-option-display">
                                <li
                                    class="tooltip custom-list-bullets"
                                    v-for="(item, cidx) of item.info.options"
                                    :key="cidx"
                                >
                                    {{ item.name }}
                                    <span class="tooltiptext"
                                        >+ {{ $store.state.shop.activeCurrency }} {{ item.price_increase }}
                                    </span>
                                </li>
                            </div>
                            <div class="sold-by flex-inline mini-font">
                                <UserIcon class="color-purple" />
                                <span class="color-white">Sold By: Trade-Raid.com</span>
                            </div>
                            <div v-if="item.type === 'gold'" class="eta flex-inline mini-font">
                                <ClockIcon class="color-purple" /><span class="color-white"
                                    >Average Delivery Time: 5 hours</span
                                >
                            </div>
                            <div class="eta flex-inline mini-font">
                                <ShieldIcon class="color-purple" />
                                <span class="color-white delivery-time-bottom"
                                    >Guaranteed Delivery Time: {{ item.delivery_time }} hours</span
                                >
                            </div>
                        </div>
                        <!-- <div class="tag-info">
                    <ul>
                        <li>{{ item.region }}</li>
                    </ul>
                </div> -->
                        <div class="actions">
                            <div class="remove flex-inline" @click="removeItem(index)">
                                <div class="cursor-pointer flex-inline">
                                    <Trash2Icon class="color-grey" />
                                    <span class="color-grey"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cart-functionality">
                <div class="cart-functionality-item item-number color-white flex-even bold">
                    <span>Item(s)</span> <span>{{ computedItemsLength }}</span>
                </div>
                <!-- <div class="cart-functionality-item promo-code">
                <div class="color-white">Promo Code</div>
                <div class="promo-code-entry flex-inline">
                    <input type="text" placeholder="promocode" />
                    <v-btn class="background-color-gradient color-white">Apply</v-btn>
                </div>
            </div> -->
                <div class="cart-functionality-item color-white flex-even bold">
                    <span>Subtotal</span><span>{{ $store.state.shop.activeCurrency }} {{ totalPrice }}</span>
                </div>
                <div class="cart-functionality-item"></div>
                <div class="cart-actions">
                    <div class="terms">
                        <v-checkbox v-model="checkbox_terms" color="success" value="red darken-3" hide-details>
                            <template v-slot:label>
                                <span>
                                    I accept the
                                    <a
                                        href="https://trade-raid.com/terms"
                                        target="_blank"
                                        style="color: #fff"
                                        @click.stop
                                        >Terms and Conditions</a
                                    >
                                </span>
                            </template>
                        </v-checkbox>
                    </div>
                    <v-btn
                        v-if="checkOrderBeforeProceed"
                        @click="secureCheckout()"
                        class="checkout-btn background-color-gradient"
                        >Checkout</v-btn
                    >
                </div>
            </div>
        </div>
        <div v-else class="add-items">Add items to your cart.</div>
    </div>
</template>

<script>
import Coin from '../UI/SVG/coin.vue';
import { OrderabbreviateAmount } from '@/utils/orderService.js';
import { mapGetters } from 'vuex';
import './utility.styles.css';
export default {
    name: 'ShoppingCartAsideModule',
    props: {},
    components: {
        Coin,
    },
    data() {
        return {
            active: true,
            checkbox_terms: false,
            cartItems: [],
        };
    },
    computed: {
        ...mapGetters(['user', 'token']),
        computedItemsLength() {
            return `(${this.$store.state.shop.cartItems.length})`;
        },
        totalPrice() {
            let totalPrice = 0;

            for (const entry of this.$store.state.shop.cartItems) {
                totalPrice += parseFloat(entry.price);
            }
            return totalPrice;
        },
        checkOrderBeforeProceed() {
            if (this.checkbox_terms) {
                return true;
            }
            return false;
        },
        computedCartItems() {
            return this.$store.state.shop.cartItems;
        },
    },
    watch: {
        cartItems: {
            handler(nval, oval) {
                this.$store.state.shop.cartItems = nval;
            },
        },
    },
    methods: {
        closeCart() {
            this.$store.dispatch('toggleAside', false);
        },
        removeItem(index) {
            const shallow_ref = this.$store.state.shop.cartItems.filter((x, idx) => {
                if (idx !== index) {
                    return x;
                }
            });

            this.$store.state.shop.cartItems = shallow_ref;
        },
        abbreviateGold(gold) {
            return OrderabbreviateAmount(gold, 'compact', 'short');
        },
        async secureCheckout() {
            this.$router.push('/checkout');
            this.$store.dispatch('toggleAside', false);
            // let user = null;
            // if(this.user) {
            //     user = this.user;
            // }
            // const items = this.$store.state.shop.cartItems;
            // const checkout_session = (await checkOut(items,user)).data;
            // window.open(checkout_session.url);
        },
    },
    mounted: function () {},
};
</script>

<style scoped lang="scss">
.add-items {
    color: #f1f1f1;
    font-size: 25px;
}

.custom-option-display {
    font-size: 15px;
    display: flex;
    flex-wrap: wrap;
    color: #f1f1f1;
    cursor: pointer;
}
.cart-items {
    padding-top: 10px;
    margin: 0;
}
.cart-container {
    height: 400px; /* set the desired height for your cart container */
    overflow-y: scroll; /* enable vertical scrolling */
}
.cart-container::-webkit-scrollbar {
    width: 10px; /* set the width of the scrollbar */
}

.cart-container::-webkit-scrollbar-thumb {
    background-color: #555; /* set the color of the scrollbar thumb */
    border-radius: 10px; /* set the radius of the scrollbar thumb */
}

.cart-container::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* set the color of the scrollbar track */
    border-radius: 10px; /* set the radius of the scrollbar track */
}

.cart-item {
    padding-block: 15px;
    padding-inline: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.cart-functionality {
    bottom: 0;
    width: 100%;
}
.cart-functionality-item {
    padding-block: 10px;
    border-top: 0.5px solid #4b90ff;
}
.terms {
    padding-bottom: 15px;
}
.v-input--checkbox::v-deep {
    .v-label {
        color: var(--color-grey) !important;
        font-size: 12px;
    }
}
.item-image {
    width: 50px;
}
.item-header {
    width: 100%;
    display: flex;
    gap: 50px;
    justify-content: space-between;
}
.item-price {
    // font-size: 1.3rem;
}

.trade-info {
    padding-top: 15px;
    font-weight: bold;
}
.trade-info > div:nth-child(even) {
    padding-top: 8px;
    padding-bottom: 8px;
}
.shopping-cart-aside-module {
    transition: all 1s;
    min-height: 100vh;
    background-color: #2d2f49;
    position: absolute;
    min-width: 25%;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1002;
    margin-top: 74px;
    padding-block: 15px;
    padding-inline: 35px;
    overflow: auto;
}
.top-container {
    display: flex;
    align-items: center;
}
.icon {
    margin: 0 auto;
    cursor: pointer;
}

.checkout-btn {
    color: white;
}
.actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
</style>
