/* eslint-disable */
import { getSteamLink, getInfo, setTradeOfferInfo } from '../../api/auth';
import { getToken, removeToken, removeClientToken, setClientToken } from '../../utils/auth';
import { userService } from '../../utils/userService';

const state = {
    token: getToken(),
    user: {},
    isMobile: true,
    redirect: false,
};

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    SET_CLIENT_id: (state, token) => {
        state.client_id = token;
    },
    SET_USER: async (state, user) => {
        if (process.env.VUE_APP_TEST_STEAM_ID !== '' && process.env.VUE_APP_TEST_STEAM_ID !== undefined) {
            user.steam_id = process.env.VUE_APP_TEST_STEAM_ID;
        }

        if (user.email && !user.steam_id) {
            setClientToken(user._id);
        }

        const user_info = await userService.info();
        if (user_info && user_info.country) {
            user.client_country = user_info.country;
        } else {
            user.client_country = '';
        }

        if (user_info && user_info.ip) {
            user.ip = user_info.ip;
        } else {
            user.ip = '';
        }

        state.user = user;
    },
    SET_MOBILE: (state, mobile) => {
        state.mobile = mobile;
    },
    SET_USER_TRADE_INFO: (state, payload) => {
        state.user.trade_partner_id = payload.trade_partner_id;
        state.user.trade_token = payload.trade_token;
        state.user = Object.assign({}, state.user);
    },
};

const actions = {
    signIn() {
        return new Promise(() => {
            getSteamLink().then((res) => {
                window.open(res.data.url, '_self');
            });
        });
    },

    getInfo({ commit, state }) {
        return new Promise(async (resolve, reject) => {
            let ua = userService.userAgent();
            const info = await userService.info();
            getInfo({ token: state.token, ip: info.ip, cc: info.country, ua })
                .then((response) => {
                    const { data } = response;
                    if (!data) {
                        reject('Verification failed, please Login again.');
                    }
                    const banned_ids = [
                        '76561198313362742',
                        '76561197974393633',
                        '76561198818452217',
                        '76561198132534131',
                        '76561198373800763',
                        '76561197961573008',
                        '76561198064469448',
                        '76561197960818345',
                        '76561198268988211',
                        '76561198946947682',
                        '76561198066562946',
                        '76561198075968335',
                    ];
                    if (banned_ids.includes(data.steam_id) || data.banned.status) {
                        return;
                    } else {
                        commit('SET_USER', data);
                        resolve(data);
                    }
                })
                .catch((error) => {
                    if (error.status == 401) {
                        commit('SET_TOKEN', '');
                        commit('SET_USER', {});
                        removeToken();
                    }
                    reject(error);
                });
        });
    },

    setTradeOfferInfo({ commit }, url) {
        return new Promise((resolve, reject) => {
            setTradeOfferInfo(state.user._id, { link: url })
                .then((response) => {
                    const { data } = response;

                    if (!data) {
                        reject('Verification failed, please Login again.');
                    }

                    commit('SET_USER_TRADE_INFO', data);

                    resolve(data);
                })
                .catch((error) => {
                    if (error.status == 401) {
                    }
                    reject(error);
                });
        });
    },

    // remove token
    resetToken({ commit }) {
        return new Promise((resolve) => {
            commit('SET_TOKEN', '');
            commit('SET_USER', {});
            removeClientToken();
            removeToken();
            resolve();
        });
    },
};

export default {
    state,
    mutations,
    actions,
};
