<template>
    <div class="tag-wrapper">
        <div class="tag">
            {{ text }}
            <div class="pl-2"></div>
            <span class="info-icon-spec">&#8505;</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: String,
    },
};
</script>

<style scoped>
.tag {
    height: auto;
    color: white;
    font-size: 14px !important;
    font-weight: bold;
    background-image: linear-gradient(#5761ff, #4b90ff);
    height: auto;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
    padding: 4px;
    font-weight: bold;
    line-height: 1;
    border-radius: 5px;
    font-family: Apercu Pro, system-ui;
    justify-content: center;
    align-items: center;
}
.info-icon-spec {
    background-image: linear-gradient(rgba(255, 0, 0, 0), rgba(255, 0, 0, 0)) !important;
    text-align: center;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    border: 1px solid #fff;
    text-align: center;
}
</style>
