<template>
    <div class="transactions-wrapper" v-if="orders">
        <vue-good-table
            :columns="columns"
            :rows="orders"
            :pagination="true"
            :search="true"
            :line-numbers="true"
            theme="nocturnal"
            :search-options="{
                enabled: true,
                placeholder: 'Search for an Order',
            }"
            :pagination-options="{
                enabled: true,
                perPage: 10,
            }"
        >
            <div slot="emptystate">You have no Orders yet</div>
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'game_info.game'">
                    <div style="display: flex; align-items: center">
                        <MMOGameIcon
                            v-if="gameType(props.row.game_info.game)"
                            class="game-icon-mmo"
                            :game="gameType(props.row.game_info.game)"
                            style="margin-right: 5px"
                        />
                        {{ props.row.game_info.game }}
                    </div>
                </span>
                <span v-if="props.column.field == 'id'">
                    {{ props.row.id }}
                </span>
                <span v-if="props.column.field == 'items'">
                    <div style="display: flex; align-items: center">
                        <img
                            class="order-img"
                            v-for="(orderItem, i) in getOrderItems(props.row.items, 1)"
                            :key="i"
                            :src="orderItem.image"
                            alt="Order item image"
                            style="margin-right: 5px"
                        />
                        {{ props.row.items.length }} item(s) <ArrowRightIcon />
                        {{ abbreviateAmount(props.row.amount, 'compact', 'short') }}
                        <Coin
                            class="cost mr-1"
                            kind="gold"
                            style="width: 15px; height: auto; margin-left: 10px"
                            :include_logo="false"
                        />
                    </div>
                </span>
                <span v-if="props.column.field == 'status'">
                    <div class="order-status" :class="statusController(props.row.status).toLowerCase()">
                        {{ statusController(props.row.status) }}
                    </div>
                </span>
                <span v-if="props.column.field == 'created_at'">
                    {{ new Date(props.row.created_at).toLocaleDateString() }}
                    {{
                        new Date(props.row.created_at).toLocaleTimeString(language, {
                            hour: 'numeric',
                            minute: 'numeric',
                        })
                    }}
                </span>
                <span v-if="props.column.field == 'show_more'">
                    <div @click="showMore(props.row)" class="interactive-btn interactive">show more</div>
                </span>
            </template>
        </vue-good-table>
        <v-dialog v-model="modal" width="500" class="custom-modal-config">
            <TradeOrderModal :order="current_order" />
        </v-dialog>
    </div>
    <div v-else class="loader">
        <Loader class="load-spinner" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { orderHistory } from '../../api/auth';
import { ArrowRightIcon, CornerDownRightIcon, SearchIcon } from 'vue-feather-icons';
import Coin from '../UI/SVG/coin.vue';
import MMOGameIcon from '../UI/GameIcons/mmo-icons.vue';
import TradeOrderModal from './tradeOrderModal.vue';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import {
    MMOGameAlias,
    OrderStatusController,
    OrderabbreviateAmount,
    getOrderItemsAmount,
} from '../../utils/orderService.js';
import Loader from '../UI/loader.vue';

export default {
    name: 'TradeOrders',
    props: {
        type: String,
    },
    components: {
        ArrowRightIcon,
        CornerDownRightIcon,
        Coin,
        SearchIcon,
        MMOGameIcon,
        TradeOrderModal,
        Loader,
        VueGoodTable,
    },
    data() {
        return {
            type: 'ts',
            orders: null,
            columns: [
                {
                    label: 'Game',
                    field: 'game_info.game',
                    slot: 'game',
                },
                {
                    label: 'ID',
                    field: 'id',
                },
                {
                    label: 'Status',
                    field: 'status',
                },
                {
                    label: 'Details',
                    field: 'items',
                    width: '400px',
                },
                {
                    label: 'Date',
                    field: 'created_at',
                    type: 'date',
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
                    dateOutputFormat: 'yyyy-MM-dd HH:mm:ss', // outputs 2023-01-02 16:25:40
                },
                {
                    label: '',
                    field: 'show_more',
                },
            ],
            rows: this.orders,
            curr_order: null,
            language: null,
            searchQuery: '',
            modal: false,
            current_order: null,
        };
    },
    methods: {
        getOrderItems(items, n) {
            return getOrderItemsAmount(items, n);
        },
        statusController(status) {
            return OrderStatusController(status);
        },
        abbreviateAmount(number, notation, compactDisplay) {
            return OrderabbreviateAmount(number, notation, compactDisplay);
        },
        showMore(order) {
            this.current_order = order;
            this.modal = true;
        },
        filteredList() {
            return this.orders.filter((x) => {
                const string = JSON.stringify(x.id);
                return string.includes(this.searchQuery);
            });
        },
        gameType(game) {
            return MMOGameAlias(game);
        },
    },
    computed: {
        ...mapGetters(['user']),
    },
    mounted: async function () {
        this.language = navigator.language;
        if (this.user._id && this.user.access_token) {
            this.orders = (await orderHistory('ts', this.user._id, this.user.access_token)).data.data;
        }
    },
    watch: {
        user: {
            async handler() {
                if (this.user._id && this.user.access_token) {
                    this.orders = (await orderHistory('ts', this.user._id, this.user.access_token)).data.data;
                }
            },
            immediate: true,
        },
    },
};
</script>

<style scoped>
.transactions-wrapper {
    width: 100%;
}
.search-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    border: 2px solid hsla(0, 0%, 100%, 0.3);
    padding: 5px 5px 5px 5px;
    width: 35vmin;
    border-radius: 5px;
}
.search {
    color: #f1f1f1;
    font-size: 1rem;
    padding: 5px 10px 5px 10px;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}
.search-icon {
    color: hsla(0, 0%, 100%, 0.3);
    min-width: 25px;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
}

th,
td {
    text-align: left;
    padding: 15px;
    justify-content: center;
}
td {
    display: flex;
}
.table-header {
    color: hsla(0, 0%, 100%, 0.3);
    display: flex;
    width: auto;
}

.loader {
    margin: auto;
    background-color: #383f51;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.game-header {
    width: 24.12%;
}

.order-id-header {
    width: 11.49%;
}

.status-header {
    width: 14.68%;
}

.details-header {
    width: 23.89%;
}

.created-at-header {
    width: 15.8035%;
}

.table-header > th > .game {
    min-width: 30% !important;
}

.accepted {
    color: #79c175;
    background-color: #3d4442;
}
.pending {
    color: #fad303;
    background-color: #a59233;
    height: 50%;
}
.declined {
    color: #dc6e6f;
    background-color: #53484e;
}
.on-hold-by-steam {
    color: #5a96c7;
    background-color: #3357a5;
}
.order-status {
    padding: 5px;
    border-radius: 5px;
    min-width: 85px;
    display: flex;
    justify-content: center;
    font-size: 0.7rem;
}
.order-row {
    display: flex;
    background-color: hsla(0, 0%, 100%, 0.3);
    color: #f1f1f1;
    align-items: flex-end;
    font-size: 0.9rem;
    margin: 10px 0;
    border-radius: 5px;
    justify-content: space-between;
}

.order-row:hover {
    opacity: 0.9;
}

.order-row > td {
    display: flex;
}
.interactive {
    color: #17d0c1;
    cursor: pointer;
}
.interactive:hover {
    border-radius: 5px;
}
.order-game {
    min-width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}
.order-items {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-end;
    min-width: 250px;
}
.order-img {
    width: 40px;
    background-color: hsla(0, 0%, 100%, 0.3);
    padding: 5px;
    border-radius: 5px;
}

@media screen and (max-width: 1000px) {
    .order-game,
    .order-items > img {
        display: none;
    }

    .order-row {
        font-size: 0.7rem;
    }

    .order-status {
        width: 40px;
    }

    .profile-item-counter {
        justify-content: flex-start !important;
        gap: 15px;
    }
}

@media screen and (max-width: 1263px) {
    .order-row {
        display: flex;
        justify-content: flex-end !important;
        width: fit-content;
    }
    .order-items {
        display: none !important;
    }
    .details-header {
        display: none !important;
    }
    .game-header {
        width: calc(42.12%);
    }

    .order-id-header {
        width: 7.49%;
    }

    .status-header {
        width: 17.68%;
    }
    .created-at-header {
        width: 15.8035%;
    }
}

@media screen and (max-width: 760px) {
    .order-expand {
        width: fit-content;
    }
    .order-row {
        width: fit-content;
    }
}

@media screen and (max-width: 640px) {
    .order-items {
        display: none !important;
    }
    .details-header {
        display: none !important;
    }
    .order-game {
        min-width: 125px;
    }
    .game-header {
        width: calc(29.12%);
    }

    .order-id-header {
        width: 12.49%;
    }

    .status-header {
        width: 23.68%;
    }
    .created-at-header {
        width: 15.8035%;
    }
}
</style>
