<template>
    <div class="payment-flow-wrapper">
        <div class="payment-flow">
            <div class="title padding-bottom">Secure Checkout</div>
            <div class="flex-inline payment-steps">
                <li
                    :class="[{ activeStep: step.number === $store.state.shop.currentCheckoutStep.number }]"
                    class="step cursor-pointer"
                    v-for="(step, index) in steps"
                    :key="index"
                    @click="handleStep(step)"
                >
                    {{ step.number }}. {{ step.title }}
                </li>
            </div>

            <div class="flex-inline">
                <Steps :activeStep="active_step" />
            </div>
        </div>
    </div>
</template>

<script>
import '../Shop/utility.styles.css';
import './payment.styles.css';
import Steps from './steps.vue';
import { toastConfig } from '../../utils/toastConfig.js';
import { paymentInfo } from '../../api/shop.js';
export default {
    name: 'payment-flow',
    components: {
        Steps,
    },
    props: {},
    data() {
        return {
            steps: [
                { title: 'Payment Method', number: 1 },
                { title: 'Review Order', number: 2 },
                { title: 'Payment Gateway', number: 3 },
            ],
            completed_steps: [],
            active_step: {
                title: 'Payment Method',
                number: 1,
            },
        };
    },
    computed: {
        getOrder() {
            return this.$store.state.shop.cartItems;
        },
    },
    watch: {},
    methods: {
        handleStep(step) {
            if (step.number === 3) {
                return;
            }
            if (!this.$store.state.shop.activePaymentMethod) {
                const t = toastConfig('You must select a payment method to proceed.', 5000);
                this.$toast.error(t.msg, t.options);
                return;
            }
            this.active_step = step;
            this.completed_steps.push(step.number);
            // commit step to store so it can be handled in other components
            this.$store.commit('SET_KEY_VALUE_PAIR', ['currentCheckoutStep', step]);
        },
    },
    mounted: async function () {
        paymentInfo().then((res) => {
            this.$store.commit('SET_KEY_VALUE_PAIR', ['paymentInfo', res.data]);
        });

        if (!this.$store.state.shop.cartItems.length || !this.$store.state.shop.cartItems) {
            this.$router.push('/shop');
            const t = toastConfig('You must add some items to your cart to continue to checkout', 5000);
            this.$toast.error(t.msg, t.options);
        }
    },
};
</script>

<style scoped>
.step {
    border-bottom: solid 3px #383f51;
}

.activeStep {
    border-bottom: solid 3px #4b90ff;
    transition: border-color 250ms linear;
}
.activeStep::before {
    -webkit-transition: border-color 250ms linear;
    transition: border-color 250ms linear;
}

.payment-flow-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 125px;
    min-height: 875px;
}

.stepCompleted {
    border-bottom: 3px solid rgb(150, 212, 115);
}

.payment-steps {
    color: #f1f1f1;
    gap: 30px;
}
.payment-steps > li {
    list-style-type: none;
    font-weight: bold;
}
@media screen and (min-width: 300px) {
    .payment-steps {
        padding: 25px;
    }
}
</style>
