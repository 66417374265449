<template>
    <section class="collapse-container">
        <v-expansion-panels flat>
            <v-expansion-panel v-for="(faq, index) in faqs" :key="index">
                <v-expansion-panel-header expand-icon="mdi-chevron-down">{{ faq.title }}</v-expansion-panel-header>
                <v-expansion-panel-content class="flex-column">
                    <div v-html="faq.ans"></div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </section>
</template>
<script>
export default {
    data: () => ({
        faqs: [
            {
                title: 'What is Trade-Raid?',
                ans: '<p>Trade-Raid is a cross-game trading service that performs one-way trades between your Steam Items (CSGO, DOTA2, RUST, TF2, Steam Community Items) and our EU/US The War Within Retail or Wrath of the Lich King Classic Gold.</p>',
            },
            {
                title: 'Can I get gold in all regions and servers?',
                ans: `
                    <p>We supply gold on the most popular servers within the EU and US/OCE Region.</p>
                    <p>We have plans to expand to more servers and realms in the future.</p>
                `,
            },

            {
                title: 'How much gold do you have at Trade-Raid?',
                ans: `
                    <p>Trade-Raid collaborates with boosting communities and known AH item flippers.</p>
                    <p>We have 60M Gold in circulation in EU and 25M Gold in the US.</p>
                    <p>We exchange our The War Within Retail Gold for Wrath of the Lich King classic Gold when a trader request Classic Gold for his or her Steam Items which means our Wrath of the Lich King classic or The War Within Retail gold stocks are technically the same.</p>
                    <p>We maintain our gold stocks and we always have gold ready.</p>
                `,
            },
        ],
    }),
};
</script>

<style>
#faq .v-expansion-panel-content p {
    margin-bottom: 0;
}
</style>

<style lang="scss" scoped>
#faq {
    padding-top: calc(80px + 100px);
    padding-bottom: 124px;
    flex-direction: column;
    align-content: center;
    h1 {
        width: 100%;
        font-weight: 600;
        font-size: 36px;
        line-height: 49px;
        color: #fff;
        text-align: center;
    }
    h2 {
        width: 100%;
        font-weight: 400;
        font-size: 26px;
        line-height: 39px;
        color: #fff;
        text-align: center;
    }
    .spec-list {
        list-style: circle;
    }
    p {
        text-align: center;
        font-size: 18px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 70px;
        a {
            color: #fff;
        }
    }
    .collapse-container {
        width: 50%;
        max-width: 1170px;
        background: linear-gradient(
            90deg,
            rgba(0, 14, 25, 0.1) 0%,
            rgba(15, 24, 45, 0.1) 30.7%,
            rgba(62, 0, 108, 0.1) 60.29%,
            rgba(11, 72, 67, 0.1) 79.69%,
            rgba(15, 24, 45, 0.1) 95.48%
        );
        .v-expansion-panel {
            border-radius: 2px;
            background: #383f51;
            margin-top: 0;
            margin-bottom: 20px;
        }
        .v-expansion-panel-header {
            padding: 25px 30px;
            font-size: 18px;
            line-height: 24px;
            color: #fff;
            &::v-deep .v-expansion-panel-header__icon i {
                color: #fff;
            }
            &.v-expansion-panel-header--active,
            &.v-expansion-panel-header--active::v-deep .v-expansion-panel-header__icon i {
                color: #17d0c1;
            }
        }
        .v-expansion-panel-content {
            padding-left: 30px;
            padding-right: 30px;
            font-size: 16px;
            line-height: 30px;
            color: rgba(255, 255, 255, 0.7);
            div {
                padding-top: 0;
                padding-bottom: 25px;
            }
        }
        .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
            padding: 0;
            font-size: 16px;
            line-height: 30px;
            color: rgba(255, 255, 255, 0.7);
        }
    }
}
</style>
