import axios from 'axios';

export const userService = {
    /** IP, country */
    info: async () => {
        try {
            return (await axios.get('https://hutils.loxal.net/whois')).data;
        } catch (error) {
            return {
                country: '',
                ip: '',
                error: true,
            };
        }
    },
    userAgent: () => navigator.userAgent,
};
