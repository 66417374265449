import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.userObj.steam_id)?_c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"persistent":"","width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"color":"#555C70"}},[_c(VCardTitle,{staticClass:"headline modal grey text-white"},[_vm._v(" Sign in with Steam ")]),_c(VCardText,{staticClass:"modal text-white"},[_vm._v(" Our Trade Service use Steam to provide the value of your inventory. Please sign in with Steam below to use our Trade Service. ")]),_c(VCardActions,[_c(VSpacer),_c('ConnectSteam')],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }