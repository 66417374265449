export const toastConfig = (msg, timeout) => {
    return {
        msg,
        options: {
            position: 'bottom-center',
            timeout,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
        },
    };
};
