<template>
    <v-container fluid fill-height id="about">
        <!-- Det er dette der afgør hvilken CSS den skal bruge -->
        <h1 class="page-title">Privacy Policy</h1>
        <p class="page-sub-title">
            Last updated: January 20th 2022<br />
            <br />At Trade-Raid, accessible from https://trade-raid.com, one of our main priorities is the privacy of
            our visitors. This Privacy Policy document contains types of information that is collected and recorded by
            Trade-Raid and how we use it.<br />If you have additional questions or require more information about our
            Privacy Policy, do not hesitate to contact us.<br />
            This Privacy Policy applies only to our online activities and is valid for visitors to our website with
            regards to the information that they shared and/or collect in Trade-Raid. This policy is not applicable to
            any information collected offline or via channels other than this website.<br />
            <br />Consent<br />
            By using our website, you hereby consent to our Privacy Policy and agree to its terms. For our Terms and
            Conditions, please visit our terms of use in the footer of our site or at https://trade-raid.com/terms
            <br />Information we collect<br />
            The personal information that you are asked to provide, and the reasons why you are asked to provide it,
            will be made clear to you at the point we ask you to provide your personal information.<br />
            If you contact us directly, we may receive additional information about you such as your name, email
            address, the contents of the message and/or attachments you may send us, and any other information you may
            choose to provide.<br />
            When you register for an Account, we may ask for your contact information, including items such as name,
            company name, address, email address, and telephone number.<br />
            We use cookies for the following purposes: to enable certain functions of the Service, to provide
            analytics,<br />or to store your preferences.<br />
            <br />How we use your information<br />
            <br />We use the information we collect in various ways, including to:<br />
            <br />Provide, operate, and maintain our website<br />
            <br />Improve, personalize, and expand our webste<br />
            <br />Understand and analyze how you use our webste<br />
            <br />Develop new products, services, features, and functionality<br />
            <br />Communicate with you, either directly or through one of our partners, including for customer service,
            to provide you with updates and other information relating to the webste, and for marketing and promotional
            purposes<br />
            <br />Send you emails<br />
            <br />Find and prevent fraud<br />

            <br />Log Files<br />
            Trade-Raid follows a standard procedure of using log files. These files log visitors when they visit
            websites. All hosting companies do this and a part of hosting services' analytics. The information collected
            by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date
            and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any
            information that is personally identifiable. The purpose of the information is for analyzing trends,
            administering the site, tracking users' movement on the website, and gathering demographic information.<br />
            <br />Cookies and Web Beacons<br />
            Like any other website, Trade-Raid uses 'cookies'. These cookies are used to store information including
            visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is
            used to optimize the users' experience by customizing our web page content based on visitors' browser type
            and/or other information.<br />
            <br />For more general information on cookies, please read "What Are Cookies".<br />

            <br />CCPA Privacy Rights (Do Not Sell My Personal Information)<br />
            <br />Under the CCPA, among other rights, California consumers have the right to: <br />Request that a
            business that collects a consumer's personal data disclose the categories and specific pieces of personal
            data that a business has collected about consumers. <br />Request that a business delete any personal data
            about the consumer that a business has collected. <br />Request that a business that sells a consumer's
            personal data, not sell the consumer's personal data. <br />If you make a request, we have one month to
            respond to you. If you would like to exercise any of these rights, please contact us.<br />
            <br />GDPR Data Protection Rights<br />
            <br />We would like to make sure you are fully aware of all of your data protection rights. Every user is
            entitled to the following: <br />The right to access – You have the right to request copies of your personal
            data. We may charge you a small fee for this service. <br />The right to rectification – You have the right
            to request that we correct any information you believe is inaccurate. You also have the right to request
            that we complete the information you believe is incomplete. <br />The right to erasure – You have the right
            to request that we erase your personal data, under certain conditions. <br />The right to restrict
            processing – You have the right to request that we restrict the processing of your personal data, under
            certain conditions. <br />The right to object to processing – You have the right to object to our processing
            of your personal data, under certain conditions. <br />The right to data portability – You have the right to
            request that we transfer the data that we have collected to another organization, or directly to you, under
            certain conditions. <br />If you make a request, we have one month to respond to you. If you would like to
            exercise any of these rights, please contact us.<br />
            <br />Children's Information<br />
            <br />Another part of our priority is adding protection for children while using the internet. We encourage
            parents and guardians to observe, participate in, and/or monitor and guide their online activity.
            <br />Trade-Raid does not knowingly collect any Personal Identifiable Information from children under the
            age of 13. If you think that your child provided this kind of information on our website, we strongly
            encourage you to contact us immediately and we will do our best efforts to promptly remove such information
            from our records.<br />
        </p>
    </v-container>
</template>

<script>
export default {
    name: 'Privacy Policy',
    metaInfo: {
        title: 'Privacy Policy',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content: 'Trade-Raids privacy policy',
            },
        ],
    },
};
</script>

<style lang="scss" scoped>
#about {
    padding-top: calc(80px + 100px);
    padding-bottom: 124px;
    flex-direction: column;
    align-content: center;
    background: linear-gradient(
        90deg,
        rgba(0, 14, 25, 0.1) 0%,
        rgba(15, 24, 45, 0.1) 30.7%,
        rgba(62, 0, 108, 0.1) 60.29%,
        rgba(11, 72, 67, 0.1) 79.69%,
        rgba(15, 24, 45, 0.1) 95.48%
    );

    .page-title {
        width: 100%;
        font-weight: 600;
        font-size: 36px;
        line-height: 49px;
        color: #fff;
        text-align: center;
    }

    .page-sub-title {
        text-align: center;
        width: 100%;
        max-width: 963px;
        font-size: 18px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 70px;
    }

    .about-container {
        width: 100%;
        max-width: 1530px;

        .image-container {
            width: 100%;
            height: 100%;
            max-height: 400px;
            min-height: 400px;
            margin-bottom: 80px;
            background: url('https://trade-raid-com.mo.cloudinary.net/dota.jpeg') no-repeat;
            background-size: cover;
            background-position: 0 -108px;
        }

        .info-container {
            h2 {
                font-weight: 600;
                font-size: 36px;
                line-height: 60px;
                color: #fff;
            }

            .desc {
                font-size: 18px;
                line-height: 35px;
                color: rgba(255, 255, 255, 0.7);
                margin-bottom: 30px;
            }

            a {
                border: 1px solid #ffffff;
                box-sizing: border-box;
                border-radius: 3px;
                font-family: Open Sans;

                font-weight: bold;
                font-size: 13px;
                line-height: 18px;
                text-transform: uppercase;

                color: #ffffff;
                text-decoration: none;
                padding: 13px 30px;

                display: inline-block;
            }

            a + a {
                margin-left: 20px;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    #about .about-container .info-container h2,
    #about .about-container .info-container .desc {
        text-align: center;
    }
}
</style>
