import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLazy,{attrs:{"options":{
        threshold: 0.5,
    },"min-height":"200","transition":"fade-transition"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c(VContainer,{staticClass:"service-wrapper"},[_c(VRow,{staticClass:"service-row"},[_c('v-cols',{attrs:{"cols":"12"}},[_c('div',{staticClass:"tp-widget-mobile"},[_c('trustpilot-horizontal')],1),_c('Products',{staticClass:"service-cards",attrs:{"propProducts":_vm.products}}),_c('div',{staticClass:"tp-widget-pc"},[_c('trustPilot',{attrs:{"templateId":'5419b6ffb0d04a076446a9af'}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }