<template>
    <div class="text-center">
        <v-dialog persistent v-model="dialog" width="500">
            <v-card color="#555C70">
                <v-card-title class="headline modal grey text-white"> SIGN IN WITH STEAM </v-card-title>
                <v-card-text class="modal text-white">
                    <p class="text-center">
                        To use our Trade Service, you need to Sign in with Steam otherwise we are unable to calculate
                        your Steam Inventory.<br />
                        Start by logging out of your E-mail profile in the top right corner. Then sign in again with
                        Steam.
                        <br />
                    </p>
                </v-card-text>
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        text
                        @click="hideModal()"
                    >
                        Save
                    </v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        dialog: Boolean,
        user: Object,
    },
    data() {
        return {
            url: '',
        };
    },
    methods: {
        hideModal() {
            this.$emit('closeSteamLoginModal');
        },
    },
};
</script>
<style lang="scss" scoped>
.v-dialog .modal.grey {
    background: #555c70;
}
.text-white {
    color: #fff !important;
}
.v-dialog .modal.text-white {
    color: #fff;
    padding: 10px 20px;
    font-size: 17px;
    line-height: 35px;
    font-family: 'Roboto';
}
.v-dialog .text-white >>> .v-label {
    color: #fff;
}
.v-dialog .text-white >>> input {
    color: #fff;
}
.menu-container {
    background: #51586c !important;
    border-radius: 2px !important;
}
.v-list-item__title {
    color: #fff;
}
</style>

<style lang="scss">
.glow-on-hover {
    width: 125px;
    height: 44px;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}
.glow-on-hover:before {
    content: '';
    // background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #ff0000, #ff7300, #fffb00, #ff0000, #ff7300);
    position: absolute;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 3px;
}
.glow-on-hover:active:after {
    background: transparent;
}
.glow-on-hover:hover:before {
    opacity: 1;
}
.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}
@keyframes glowing {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
}
.sign-button svg {
    vertical-align: middle;
    margin-right: 10px;
}
.sign-button {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 13px !important;
    line-height: 18px !important;
    text-align: center !important;
    text-transform: uppercase !important;
    text-decoration: none !important;
    color: #ffffff !important;
    background: #17d0c1 !important;
    box-shadow: 0px 0px 20px rgba(31, 223, 206, 0.4) !important;
    border-radius: 3px !important;
    padding: 0 30px !important;
    margin-right: 55px !important;
}

.sign-in-btn,
.sign-up-btn {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 13px !important;
    line-height: 18px !important;
    text-align: center !important;
    text-transform: uppercase !important;
    text-decoration: none !important;
    color: #ffffff !important;
    background: #17d0c1 !important;
    box-shadow: 0px 0px 20px rgba(31, 223, 206, 0.4) !important;
    border-radius: 3px !important;
    padding: 0 30px !important;
    margin-right: 55px !important;
}
.sign-button.user {
    color: #ffffff !important;
    background: transparent !important;
    padding: 0 15px !important;
    box-shadow: unset !important;
    border: 1px solid rgba(255, 255, 255, 0.15);
    width: auto;
}
</style>
