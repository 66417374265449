<template>
    <v-lazy
        v-model="isActive"
        :options="{
            threshold: 0.5,
        }"
        min-height="200"
        transition="fade-transition"
    >
        <div class="about-us-page">
            <v-container>
                <h1 class="main-header">About Us</h1>
                <div class="sub-header">
                    The story behind Trade-Raid.com goes back to our co-founder Shakiel, who had since 2015, traded
                    Steam Items for WoW Gold on Sylvanas Alliance EU. Over the years, he quickly realised how big an
                    issue it was for gamers to move the value of their digital inventories across games. After
                    conducting more than 2000 trades and 110.000 market transactions on Steam, he decided to move his
                    personal trading concept into a 24/7 live service. In October 2020, he got together with his
                    childhood friend Asger to build and launch a safe place to trade virtual items within and across
                    games: Trade-Raid.com.
                </div>
                <div class="mission-vision">
                    <div class="mission">
                        <div class="sub-header">
                            <h2>Mission</h2>
                            <p>
                                Our mission is to help gamers move the value of their digital inventories across games
                                with zero risk of losing their value or getting scammed.
                            </p>
                        </div>
                    </div>
                    <div class="vision">
                        <div class="sub-header">
                            <h2>Vision</h2>
                            <p>
                                We want to make trading across games common sense and accessible for all gamers so they
                                can have their digital inventory where it actually matters.
                            </p>
                        </div>
                    </div>
                    <div class="sub-header">
                        <h2>The Team</h2>
                        <p>
                            The people behind Trade-Raid are all veteran gamers with experience with trading both within
                            and across games. We all still play World of Warcraft today and have done so consecutively
                            for 17 years. We engage with our users on an eye-to-eye level because we're gamers
                            ourselves.
                        </p>
                    </div>
                </div>
                <div class="team-members">
                    <div class="team-member">
                        <div class="team-member-image">
                            <img
                                src="https://trade-raid-com.mo.cloudinary.net/employees/shak.png"
                                alt="Team member 1"
                            />
                        </div>
                        <h3>Shakiel</h3>
                        <p>
                            Shakiel comes from a trading background and has conducted thousands of cross-game trades.
                            His responsibilities are business and in-game operations, strategy, marketing, accounting
                            and customer service. <br /><br />Games that Shakiel plays: <br />World of Warcraft,
                            Hearthstone, CSGO, New World
                        </p>
                        <br />
                        <a href="https://www.linkedin.com/in/shakiel-raja-157023101/" target="_blank"
                            ><LinkedinIcon icon="linkedin" size="24" style="color: #17d0c1"
                        /></a>
                        <br />
                        <a href="https://steamcommunity.com/id/wowgoldtrader/" target="_blank" style="color: #17d0c1"
                            >Steam Profile</a
                        >
                    </div>
                    <div class="team-member">
                        <div class="team-member-image">
                            <img
                                src="https://trade-raid-com.mo.cloudinary.net/employees/asger.jpeg"
                                alt="Team member 2"
                            />
                        </div>
                        <h3>Asger</h3>
                        <p>
                            Asger, a former DevOps consultant, co-founded Trade-Raid with Shakiel in 2020, and is
                            responsible for IT, development, scalability, marketing, graphics, SEO and business
                            operations.
                            <br /><br />Games that Asger plays: <br />World of Warcraft, Hearthstone, CSGO, TEKKEN 7
                        </p>
                        <br />
                        <a href="https://www.linkedin.com/in/asger-larsen/" target="_blank"
                            ><LinkedinIcon icon="linkedin" size="24" style="color: #17d0c1"
                        /></a>
                        <br />
                        <a href="https://steamcommunity.com/id/trade-raid_dev/" target="_blank" style="color: #17d0c1"
                            >Steam Profile</a
                        >
                    </div>
                    <div class="team-member">
                        <div class="team-member-image">
                            <img
                                src="https://trade-raid-com.mo.cloudinary.net/employees/pete.jpg"
                                alt="Team member 3"
                            />
                        </div>
                        <h3>Peter</h3>
                        <p>
                            Peter is a backend developer. He met Shakiel through World of Warcraft back in 2009 and have
                            been close friends since then. He is responsibilities lies within IT, development of new
                            projects and features and graphic design. <br /><br />Games that Peter plays: <br />World of
                            Warcraft, Rust, CSGO
                        </p>
                        <br />
                        <a href="https://www.linkedin.com/in/peter-h%C3%B8jer-jespersen-630037107/" target="_blank"
                            ><LinkedinIcon icon="linkedin" size="24" style="color: #17d0c1"
                        /></a>
                        <br />
                        <a href="https://steamcommunity.com/id/cultmanager/" target="_blank" style="color: #17d0c1"
                            >Steam Profile</a
                        >
                    </div>
                </div>
            </v-container>
        </div>
    </v-lazy>
</template>

<script>
import { LinkedinIcon } from 'vue-feather-icons';

export default {
    name: 'About Us - The Team behind',
    metaInfo: {
        title: 'About Us - The Team behind',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content:
                    'Trade-Raid is on a mission to change the gaming industry by making it easier for gamers to trade their value across games. Read about the team here.',
            },
        ],
    },
    components: {
        LinkedinIcon,
    },
    data: () => ({
        isActive: false,
    }),
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');
.about-us-page {
    background-color: linear-gradient(
        90deg,
        rgba(0, 14, 25, 0.1) 0%,
        rgba(15, 24, 45, 0.1) 30.7%,
        rgba(62, 0, 108, 0.1) 60.29%,
        rgba(11, 72, 67, 0.1) 79.69%,
        rgba(15, 24, 45, 0.1) 95.48%
    );
    color: #ffffff;
    padding: 50px 0;
}

.mission-vision {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.mission,
.vision {
    flex-basis: 45%;
    margin-bottom: 30px;
}

@media (max-width: 600px) {
    .mission,
    .vision {
        flex-basis: 100%;
    }
}

.header {
    height: 70px;
    background-color: #17d0c1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

.page-sub-title {
    max-width: 963px;
    font-size: 18px;
    line-height: 30px;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    margin-bottom: 30px;
}

.header img {
    height: 50px;
}

.header-nav {
    display: flex;
}

.header-nav a {
    color: #ffffff;
    text-decoration: none;
    margin-left: 30px;
    font-size: 18px;
}

.header-nav a:hover {
    text-decoration: underline;
}

.main-header {
    width: 100%;
    font-weight: 600;
    font-size: 36px;
    line-height: 49px;
    color: #fff;
    text-align: center;
    padding-top: calc(80px + 100px);
}

.sub-header {
    text-align: center;
    margin-bottom: 30px;
    max-width: 900px;
    margin: 0 auto;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 50px;
    font-size: 18px;
}

.sub-header h2 {
    color: #fff;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 1.2;
    font-weight: bold;
}

.team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
}

.team-member {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 18px;
}

.team-member h3 {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 10px;
    color: #fff;
    font-weight: bold;
}

.team-member-image {
    width: 150px;
    height: 150px;
    overflow: hidden;
    margin: 0 auto 10px;
}

.team-member-image img {
    display: block;
    width: 100%;
    height: auto;
}

.team-member p {
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 0;
}
</style>
