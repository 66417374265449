<template>
    <v-container fluid fill-height id="about">
        <!-- Det er dette der afgør hvilken CSS den skal bruge -->
        <h1 class="page-title">Influencers</h1>
        <p>
            Are you a content creator/influencer or do you follow/support a content creator we should collaborate with?
            Then we would love to hear from you.
        </p>
        <p>
            We want to reach out to more gamers that are stuck with their digital value on game(s) that they do not play
            anymore.
        </p>
        <p>
            You must provide content that is related to one or more MMO game(s) such as WoW, New World, Lost Ark, FFXIV,
            or be related to the MMO genre in general.
        </p>
        <p>
            We’ve done more than 100 collaborations with more different influencers succesfully and we only look for
            long term partnerships. We can pay our influencers with Gold, Skins, PayPal, Bank Wire Transfer or Crypto.
        </p>
        <p>
            Add Trade-Raid on Discord: TradeRaid#7595, send a mail to admin@trade-raid.com or Telegram: @TradeRaidDK to
            get in touch with us.
        </p>
    </v-container>
</template>

<script>
export default {
    name: 'Work with us!',
    metaInfo: {
        title: 'Work with us!',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content:
                    'At Trade-Raid we are always looking for new people to collaborate with. We strive to create innovative services that are smart and exciting for our users to use. Trade-Raid’s: core values are innovation, premium service, and humility.',
            },
        ],
    },
};
</script>

<style lang="scss" scoped>
#about {
    padding-top: calc(80px + 100px);
    padding-bottom: 124px;
    flex-direction: column;
    align-content: center;
    background: linear-gradient(
        90deg,
        rgba(0, 14, 25, 0.1) 0%,
        rgba(15, 24, 45, 0.1) 30.7%,
        rgba(62, 0, 108, 0.1) 60.29%,
        rgba(11, 72, 67, 0.1) 79.69%,
        rgba(15, 24, 45, 0.1) 95.48%
    );
    .page-title {
        width: 100%;
        font-weight: 600;
        font-size: 36px;
        line-height: 49px;
        color: #fff;
        text-align: center;
    }
    .page-sub-title {
        text-align: center;
        width: 100%;
        max-width: 963px;
        font-size: 18px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 70px;
    }
    p {
        text-align: center;
        width: 100%;
        max-width: 963px;
        font-size: 18px;
        line-height: 26px;
        color: rgba(255, 255, 255, 0.7);
        margin-top: 3px;
        a {
            color: #fff;
        }
    }
    .about-container {
        width: 100%;
        max-width: 1530px;
        .image-container {
            width: 100%;
            height: 100%;
            max-height: 400px;
            min-height: 400px;
            margin-bottom: 80px;
            background: url('https://trade-raid-com.mo.cloudinary.net/dota.jpeg') no-repeat;
            background-size: cover;
            background-position: 0 -108px;
        }
        .info-container {
            h2 {
                font-weight: 600;
                font-size: 36px;
                line-height: 60px;
                color: #fff;
            }
            .desc {
                font-size: 18px;
                line-height: 35px;
                color: rgba(255, 255, 255, 0.7);
                margin-bottom: 30px;
            }
            a {
                border: 1px solid #ffffff;
                box-sizing: border-box;
                border-radius: 3px;
                font-family: Open Sans;
                font-weight: bold;
                font-size: 13px;
                line-height: 18px;
                text-transform: uppercase;
                color: #ffffff;
                text-decoration: none;
                padding: 13px 30px;
                display: inline-block;
            }
            a + a {
                margin-left: 20px;
            }
        }
    }
}
@media screen and (max-width: 960px) {
    #about .about-container .info-container h2,
    #about .about-container .info-container .desc {
        text-align: center;
    }
}
</style>
