<template>
    <form class="contact-form" @submit.prevent="sendEmail">
        <label style="color: white">CharacterName-Server-Faction</label>
        <input style="color: white" type="text" name="user_name" required placeholder="Character Name" />
        <label style="color: white">(Optional) Email</label>
        <input style="color: white" type="email" name="user_email" placeholder="Email" />
        <label style="color: white">Feedback</label>
        <textarea style="color: white" name="message" required placeholder="Feedback"></textarea>
        <input type="submit" value="Send" />
    </form>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
    methods: {
        sendEmail: (e) => {
            emailjs.sendForm('service_fwoaryk', 'template_tzg3iib', e.target, 'user_t1QhNaPK4GivANXVxuQCJ').then(
                (result) => {
                    window.location.reload();
                },
                (error) => {
                    // console.log('FAILED...', error);
                },
            );
        },
    },
};
</script>

<style scoped>
* {
    box-sizing: border-box;
}

.contact-form {
    background-color: #2a344b;
    -webkit-box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
    padding: 100px;
}

label {
    float: left;
}

input[type='text'],
[type='email'],
textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

input[type='submit'] {
    background-color: #17d0c1;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

input[type='submit']:hover {
    background-color: #1752d0;
}
</style>
