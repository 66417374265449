import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Confirmation")]),_c(VCardText,[_vm._v("Are you sure you want to remove this item from your cart?")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.confirm}},[_vm._v("Yes")]),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":_vm.closeModal}},[_vm._v("No")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }