<template>
    <v-lazy
        v-model="isActive"
        :options="{
            threshold: 0.5,
        }"
        min-height="200"
        transition="fade-transition"
    >
        <v-container>
            <div class="miniBlog">
                <div class="card blog rounded border-0 shadow overflow-hidden" style="flex: 1; padding: 5px; margin: 0">
                    <div class="hover-wrapper">
                        <a href="/blog/posts/the-current-state-of-the-gaming-industry">
                            <v-img
                                height="250"
                                eager
                                position="center center"
                                src="https://blog1.trade-raid.com/wp-content/uploads/2022/09/current_state_of_gaming_industry.png"
                                href="/blog/posts/the-current-state-of-the-gaming-industry"
                                alt="Current state of the gaming industry"
                            >
                            </v-img>
                        </a>
                    </div>
                    <div class="card-body content">
                        <small
                            ><a href="/blog/posts/the-current-state-of-the-gaming-industry" class="text-primary h6"
                                >History</a
                            ></small
                        >
                        <h5 class="mt-2">
                            <a href="/blog/posts/the-current-state-of-the-gaming-industry" class="title text-dark"
                                >The current state of the gaming industry</a
                            >
                        </h5>
                        <p class="text-muted" style="padding-bottom: 30px">
                            Games are locked behind their own platforms/universes. Moving back and forth between
                            different games can only mean one thing: starting over.
                        </p>
                        <a href="/blog/posts/the-current-state-of-the-gaming-industry" class="text-primary omg-asger"
                            >Read More <chevron-right-icon class="fea icon-sm"></chevron-right-icon
                        ></a>
                        <ul
                            class="list-unstyled d-flex justify-content-between border-top mt-5 pt-3 mb-0 text-muted"
                            style="font-size: 14px"
                        >
                            <calendar-icon class="fea icon-sm text-primary" size="1x"></calendar-icon
                            >28 sep 2022
                            <clock-icon class="fea icon-sm text-primary" size="1x"></clock-icon>
                            14m
                        </ul>
                    </div>
                </div>
                <div class="card blog rounded border-0 shadow overflow-hidden" style="flex: 1; padding: 5px; margin: 0">
                    <div class="hover-wrapper">
                        <a href="/blog/posts/the-history-and-origins-of-trade-raid">
                            <v-img
                                height="250"
                                eager
                                position="center center"
                                src="https://blog1.trade-raid.com/wp-content/uploads/2022/04/steam_banner_glow-1.png"
                                a
                                href="/blog/posts/the-history-and-origins-of-trade-raid"
                                alt="The History and Origins of Trade Raid"
                            >
                            </v-img>
                        </a>
                    </div>
                    <div class="card-body content">
                        <small
                            ><a href="/blog/posts/the-history-and-origins-of-trade-raid" class="text-primary h6"
                                >History</a
                            ></small
                        >
                        <h5 class="mt-2">
                            <a href="/blog/posts/the-history-and-origins-of-trade-raid" class="title text-dark"
                                >The History and Origins of Trade-Raid.com</a
                            >
                        </h5>
                        <p class="text-muted" style="padding-bottom: 30px">
                            Read about the story behind Trade-Raid and how we are trying to captivate the gaming
                            industry with our cross-game trading concept.
                        </p>
                        <a href="/blog/posts/the-history-and-origins-of-trade-raid" class="text-primary omg-asger"
                            >Read More <chevron-right-icon class="fea icon-sm"></chevron-right-icon
                        ></a>
                        <ul
                            class="list-unstyled d-flex justify-content-between border-top mt-5 pt-3 mb-0 text-muted"
                            style="font-size: 14px"
                        >
                            <calendar-icon class="fea icon-sm text-primary" size="1x"></calendar-icon
                            >17 april 2022
                            <clock-icon class="fea icon-sm text-primary" size="1x"></clock-icon>
                            10m
                        </ul>
                    </div>
                </div>
                <div class="card blog rounded border-0 shadow overflow-hidden" style="flex: 1; padding: 5px; margin: 0">
                    <div class="hover-wrapper">
                        <a href="/blog/posts/trade-raids-trade-service">
                            <v-img
                                height="250"
                                eager
                                position="center center"
                                src="https://blog1.trade-raid.com/wp-content/uploads/2022/04/world-of-warcraft-classic_6014061.jpg"
                                a
                                href="/blog/posts/trade-raids-trade-service"
                                alt="Trade-Raid’s Trade Service"
                            >
                            </v-img>
                        </a>
                    </div>
                    <div class="card-body content">
                        <small
                            ><a href="/blog/posts/trade-raids-trade-service" class="text-primary h6">Services</a></small
                        >
                        <h5 class="mt-2">
                            <a href="/blog/posts/trade-raids-trade-service" class="title text-dark"
                                >Trade-Raid’s Trade Service</a
                            >
                        </h5>
                        <p class="text-muted" style="padding-bottom: 30px">
                            Learn more of how we are breaking the artificial barriers between gaming platforms by
                            trading Steam Items for MMO Gold.
                        </p>
                        <a href="/blog/posts/trade-raids-trade-service" class="text-primary omg-asger"
                            >Read More <chevron-right-icon class="fea icon-sm"></chevron-right-icon
                        ></a>
                        <ul
                            class="list-unstyled d-flex justify-content-between border-top mt-5 pt-3 mb-0 text-muted"
                            style="font-size: 14px"
                        >
                            <calendar-icon class="text-primary" size="1x"></calendar-icon
                            >2022-04-29
                            <clock-icon class="fea icon-sm text-primary" size="1x"></clock-icon>
                            6m
                        </ul>
                    </div>
                </div>
            </div>
        </v-container>
    </v-lazy>
</template>
<script>
import { ClockIcon, EyeIcon, CalendarIcon } from 'vue-feather-icons';
export default {
    components: {
        ClockIcon,
        EyeIcon,
        CalendarIcon,
    },
    data: () => ({
        isActive: false,
    }),
    methods: {
        share(id) {
            alert('share id: ' + id);
        },
    },
};
</script>
<style scoped>
.omg-asger {
    display: flex;
}
@media (min-width: 768px) {
    .miniBlog {
        display: flex;
        flex-flow: row wrap;
    }
}
.hover-wrapper {
    display: block;
    position: relative;
    overflow: hidden;
}

.hover-wrapper .v-image {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.hover-wrapper:hover .v-image {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}
.col-12,
.col-lg-6,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
@media (min-width: 768px) {
    .col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-md-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-md-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-md-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
}
@media (min-width: 992px) {
    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}
.rounded {
    border-radius: 0.25rem !important;
}
.rounded-circle {
    border-radius: 50% !important;
}
.border {
    border: 1px solid #dee2e6 !important;
}
.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}
.border-0 {
    border: 0 !important;
}
.border-light {
    border-color: #f8f9fa !important;
}
.overflow-hidden {
    overflow: hidden !important;
}
.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}
.card .card-body {
    padding: 1.5rem;
}
.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}
.header .container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.modal-content {
    background: #1f2d3d;
}
.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}
.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-evenly !important;
}
.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

.position-relative {
    position: relative !important;
}
.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow {
    -webkit-box-shadow: 0 0 3px rgba(255, 255, 255, 0.15) !important;
    box-shadow: 0 0 3px rgba(255, 255, 255, 0.15) !important;
}

#clock {
    color: #ffffff;
}

#clock p {
    width: 80px;
}

#clock p span {
    font-size: 40px;
}

.footer {
    background: #202942;
    padding: 60px 0;
    position: relative;
    color: #adb5bd;
}

.bg-white {
    background-color: #1f2d3d !important;
}

.text-primary {
    color: #17d0c1 !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #284bc1 !important;
}

.bg-success {
    background-color: #2eca8b !important;
}

.text-dark {
    color: #ffffff !important;
}

a.text-dark:hover,
a.text-dark:focus {
    color: #f2f2f2 !important;
}

.text-muted {
    color: #9bacc4 !important;
}

a.text-muted:hover,
a.text-muted:focus {
    color: #8b9fbb !important;
}

.bg-light {
    background-color: #253649 !important;
}

.text-light {
    color: #253649 !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #1c2938 !important;
}

.card {
    background-color: #1f2d3d;
}

.border {
    border: 1px solid #495057 !important;
}

.border.border-light {
    border-color: #ffffff !important;
}

.border-bottom {
    border-bottom: 1px solid #495057 !important;
}
</style>
