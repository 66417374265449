import request from '../utils/request';

export function getSteamLink() {
    return request({
        url: `/auth`,
        method: 'get',
    });
}

export function getInfo(data) {
    return request({
        url: `/user`,
        method: 'post',
        data: data,
    });
}

export function checkUserName(steam_id) {
    return request({
        url: `/user-info/${steam_id}`,
        method: 'GET',
    });
}

export function connectSteam(combine_id, user, token) {
    return request({
        url: `/user/connect/steam?token=${token}`,
        method: 'POST',
        data: {
            combine_id,
            user,
        },
    });
}

export function setEmailOnSteamUser(user_id, data, token) {
    return request({
        url: `/user/connect/trade-raid/${user_id}?token=${token}`,
        method: 'POST',
        data,
    });
}

export function setTradeOfferInfo(user_id, data, token) {
    return request({
        url: `/user/${user_id}/trade-info?token=${token}`,
        method: 'post',
        data,
    });
}

export function setUserCountry(user_id, data, token) {
    return request({
        url: `/user/${user_id}/country?token=${token}`,
        method: 'POST',
        data,
    });
}
export function setUserEmail(user_id, data, token) {
    return request({
        url: `/user/${user_id}/email?token=${token}`,
        method: 'POST',
        data,
    });
}

// export function updateUserName(user_id) {
//     return request({
//         url: `/api/user/${user_id}/trade-info`,
//         method: 'put'
//     })
// }

export function orderCount(user_id, type, token) {
    return request({
        url: `/user/orders/count/${type}/${user_id}?token=${token}`,
        method: 'GET',
    });
}

export function orderHistory(type, user_id, token) {
    return request({
        url: `/user/orders/history/${user_id}/${type}?token=${token}`,
        method: 'GET',
    });
}

export function getAccessToken(token) {
    return request({
        url: `/steam-auth/confirm`,
        method: 'post',
        data: { token: token },
    });
}
