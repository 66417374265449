<template>
    <v-container>
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card color="#383F51">
                <v-card-title class="white--text">
                    <span class="text-h5">Exchange Service Maintenance</span>
                </v-card-title>
                <v-card-text class="white--text">
                    We are updating our Exchange Service. <br />As a result, the service has been temporarily taken down
                    for maintenance.
                    <br />
                    It will return the 5th of may. <br />
                    In the meantime Trade Service and Reward Activities are unaffected and operating normally.
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            dialog: true,
        };
    },
};
</script>
