import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.trade_amounts)?_c('div',[_c(VDialog,{attrs:{"persistent":"","width":"500"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{attrs:{"color":"#555C70"}},[_c(VCardTitle,{staticClass:"headline modal grey text-white"},[_vm._v(" Gold sum is too low ")]),_c(VCardText,{staticClass:"modal text-white"},[_vm._v(" Your total gold sum is under Trade-Raid's minimum"),_c('br'),_vm._v("trade amounts: "),_c('br'),_c('br'),_vm._v(" The War Within: "+_vm._s(_vm.trade_amounts['retail'])+" Gold "),_c('br'),_vm._v(" Cataclysm Classic: "+_vm._s(_vm.trade_amounts['cataclysm classic'])+" Gold"),_c('br'),_vm._v(" Classic Era: "+_vm._s(_vm.trade_amounts.SoM)+" "),_c('br'),_vm._v(" Classic Hardcore: "+_vm._s(_vm.trade_amounts['Classic Hardcore'])+" Gold "),_c('br'),_vm._v(" Season of Discovery: "+_vm._s(_vm.trade_amounts['season of discovery'])+" Gold "),_c('br'),_vm._v(" Please add more items to your trade. ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"depressed":"","color":"#17D0C1"},on:{"click":function($event){_vm.showModal = false}}},[_vm._v(" Okay ")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }