import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"update-password-container",attrs:{"fill-height":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,[_c(VForm,{ref:"update_password_form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCardTitle,[_c('span',{staticClass:"text-h5 card-title"},[_vm._v("Update Password")])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VTextField,{attrs:{"label":"New Password*","type":"password","outlined":"","height":"36px","dense":"","rules":[
                                            _vm.rules.required,
                                            (value) => {
                                                return this.is_password_match || this.password_mismatch_error;
                                            },
                                        ]},on:{"keyup":_vm.checkPasswordMatch},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VTextField,{attrs:{"label":"Confirm New Password*","type":"password","outlined":"","height":"36px","dense":"","rules":[
                                            _vm.rules.required,
                                            (value) => {
                                                return this.is_password_match || this.password_mismatch_error;
                                            },
                                        ]},on:{"keyup":_vm.checkPasswordMatch},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"update-password-btn","type":"submit","loading":_vm.is_submitting}},[_vm._v(" Update Password ")])],1)],1)],1)],1)],1),[_c('div',{staticClass:"text-center"},[_c(VSnackbar,{attrs:{"multi-line":true,"timeout":-1,"color":"primary","absolute":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"teal accent-2","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)],_c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.msg_dialog),callback:function ($$v) {_vm.msg_dialog=$$v},expression:"msg_dialog"}},[_c('div',{staticClass:"mail_feedback"},[_c('p',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.msg))]),_c(VCardActions,[_c(VBtn,{attrs:{"color":"#e66767","depressed":""},on:{"click":function($event){_vm.msg_dialog = false}}},[_vm._v(" Close ")])],1)],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }