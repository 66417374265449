<template>
    <v-lazy
        v-model="isActive"
        :options="{
            threshold: 0.5,
        }"
        min-height="200"
        transition="fade-transition"
    >
        <div>
            <!-- Footer Start -->
            <footer class="footer">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                            <a href="#" class="logo-footer">
                                <img
                                    src="https://trade-raid-com.mo.cloudinary.net/icons/tr-logo-2022.png"
                                    height="42"
                                    alt="trade-raid.com logo"
                                />
                            </a>
                            <p class="mt-4">
                                Trade-Raid is a cross-game trading website where you can trade your Steam Items for WoW
                                Gold and exchange your own Gold between Retail and Classic.
                            </p>
                            <ul class="list-unstyled social-icon social mb-0 mt-4">
                                <li class="list-inline-item">
                                    <a href="https://www.facebook.com/TradeRaid" target="_blank"
                                        ><span class="fa fa-facebook"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item ml-1">
                                    <a href="https://www.instagram.com/trade_raid/" target="_blank"
                                        ><span class="fa fa-instagram"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item ml-1">
                                    <a href="https://twitter.com/teamtraderaid" target="_blank"
                                        ><span class="fa fa-twitter"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item ml-1">
                                    <a href="https://steamcommunity.com/id/wowgoldtrader/" target="_blank"
                                        ><span class="fa fa-steam"></span
                                    ></a>
                                </li>
                                <li class="list-inline-item ml-1">
                                    <a href="https://discord.gg/4evrM735xN" target="_blank">
                                        <svg
                                            style="width: 70%"
                                            fill="#adb5bd"
                                            role="img"
                                            viewBox="0 0 24 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <title>Discord</title>
                                            <path
                                                d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z"
                                            />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                            <!--end icon-->
                        </div>
                        <!--end col-->

                        <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <ul class="list-unstyled footer-list mt-4">
                                <li>
                                    <router-link to="/about-us" class="text-foot"
                                        ><i class="mdi mdi-chevron-right mr-1"></i> About us</router-link
                                    >
                                </li>
                                <li>
                                    <router-link to="/influencers" class="text-foot"
                                        ><i class="mdi mdi-chevron-right mr-1"></i> Influencers</router-link
                                    >
                                </li>
                                <li>
                                    <router-link to="/business" class="text-foot"
                                        ><i class="mdi mdi-chevron-right mr-1"></i> Work with Us</router-link
                                    >
                                </li>
                                <li>
                                    <a
                                        href="https://steamcommunity.com/id/wowgoldtrader"
                                        class="text-foot"
                                        target="_blank"
                                        ><i class="mdi mdi-chevron-right mr-1"></i> Steam Page</a
                                    >
                                </li>
                            </ul>
                        </div>
                        <!--end col-->

                        <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <ul class="list-unstyled footer-list mt-4">
                                <li>
                                    <router-link to="/support" class="text-foot"
                                        ><i class="mdi mdi-chevron-right mr-1"></i> Support
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/terms" class="text-foot"
                                        ><i class="mdi mdi-chevron-right mr-1"></i> Terms of Use</router-link
                                    >
                                </li>
                                <li>
                                    <router-link to="/privacy-policy" class="text-foot"
                                        ><i class="mdi mdi-chevron-right mr-1"></i> Privacy Policy</router-link
                                    >
                                </li>
                                <li>
                                    <router-link to="/cookie-policy" class="text-foot"
                                        ><i class="mdi mdi-chevron-right mr-1"></i> Cookie Policy</router-link
                                    >
                                </li>
                            </ul>
                        </div>
                        <!--end col-->

                        <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h5 class="text-light footer-head">Newsletter</h5>
                            <p class="mt-4">
                                Receive <bold style="font-weight: bold; color: white">10% extra gold</bold> on your next
                                Trade Service order as a new subscriber.
                            </p>
                            <form
                                name="myform"
                                action="https://trade-raid.us7.list-manage.com/subscribe/post?u=d717ab197117c22f26fd9508c&amp;id=6eb2ec1e27"
                                method="POST"
                            >
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="foot-subscribe form-group">
                                            <label class="label1" for="MERGE0"
                                                ><br />Write your email <span class="text-danger">*</span></label
                                            >
                                            <div class="position-relative">
                                                <mail-icon class="fea icon-sm icons"></mail-icon>
                                                <input
                                                    type="email"
                                                    name="MERGE0"
                                                    id="MERGE0"
                                                    class="form-control text-light pl-5 rounded"
                                                    placeholder="Your email : "
                                                    v-model="email"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12" @click="submitEvent()">
                                        <input
                                            type="submit"
                                            id="submitsubscribe"
                                            name="send"
                                            class="btn btn-soft-primary btn-block"
                                            onclick="document.getElementById('id01').style.display='block'"
                                            value="Subscribe"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
                <!--end container-->
            </footer>
            <!--end footer-->
            <footer class="footer footer-bar">
                <div class="container text-center">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <div class="text-sm-left">
                                <p class="mb-0" style="font-size: 15px">
                                    © 2019 - {{ new Date().getFullYear() }} Trade-Raid.com - Made in
                                    <img
                                        src="https://trade-raid-com.mo.cloudinary.net/danish_flag.png"
                                        alt="Trade-Raid.com Made in Denmark"
                                        height="15"
                                    />
                                    <br />This site is not associated with Valve Corp. Amazon.com, Inc. or Blizzard
                                    Entertainment.
                                </p>
                            </div>
                        </div>
                        <!--end col-->

                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
                <!--end container-->
            </footer>
            <!--end footer-->
        </div>
    </v-lazy>
</template>

<script>
import { FacebookIcon, InstagramIcon, TwitterIcon, LinkedinIcon, MailIcon } from 'vue-feather-icons';
import { userService } from '../../utils/userService';
import axios from 'axios';
export default {
    data() {
        return {
            isActive: false,
            email: null,
            cc: null,
            ip: null,
            ua: null,
        };
    },
    components: {
        FacebookIcon,
        InstagramIcon,
        TwitterIcon,
        LinkedinIcon,
        MailIcon,
    },
    methods: {
        async submitEvent() {
            const data = {
                cc: this.cc,
                ua: this.ua,
                email: this.email,
                event_source_url: window.location.href,
                event_name: 'Subscribe',
            };
            await axios.post(`${process.env.VUE_APP_BACKEND}/submit-event/newsletter`, data);
        },
    },
    async mounted() {
        this.ua = userService.userAgent();
        const info = await userService.info();
        this.cc = info.country;
        this.ip = info.ip;
    },
};
</script>

<style lang="scss" scoped>
/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}
*,
::after,
::before {
    box-sizing: border-box;
}
footer {
    display: block;
}
h5 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}
ul ul {
    margin-bottom: 0;
}
a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
a:hover {
    color: #0056b3;
    text-decoration: underline;
}
a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}
img {
    vertical-align: middle;
    border-style: none;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
input {
    overflow: visible;
}
[type='submit'] {
    -webkit-appearance: button;
}
[type='submit']:not(:disabled) {
    cursor: pointer;
}
[type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
}
::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}
template {
    display: none;
}
.h5,
h5 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
.h5,
h5 {
    font-size: 1.25rem;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
}
.list-inline-item {
    display: inline-block;
}
.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col-12,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-md-4,
.col-sm-6 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
@media (min-width: 576px) {
    .col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}
@media (min-width: 768px) {
    .col-md-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}
@media (min-width: 992px) {
    .col-lg-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-lg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-lg-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}
.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}
.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
}
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control:disabled {
    background-color: #e9ecef;
    opacity: 1;
}
.form-group {
    margin-bottom: 1rem;
}
.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}
.btn:hover {
    color: #212529;
    text-decoration: none;
}
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn:disabled {
    opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn-block {
    display: block;
    width: 100%;
}
.btn-block + .btn-block {
    margin-top: 0.5rem;
}
input[type='submit'].btn-block {
    width: 100%;
}
@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}
@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}
@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
.rounded {
    border-radius: 0.25rem !important;
}
.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}
.position-relative {
    position: relative !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.mr-1 {
    margin-right: 0.25rem !important;
}
.ml-1 {
    margin-left: 0.25rem !important;
}
.mt-4 {
    margin-top: 1.5rem !important;
}
.pb-0 {
    padding-bottom: 0 !important;
}
.pt-2 {
    padding-top: 0.5rem !important;
}
.pl-5 {
    padding-left: 3rem !important;
}
@media (min-width: 576px) {
    .mt-sm-0 {
        margin-top: 0 !important;
    }
    .pt-sm-0 {
        padding-top: 0 !important;
    }
}
@media (min-width: 768px) {
    .mb-md-4 {
        margin-bottom: 1.5rem !important;
    }
    .pb-md-2 {
        padding-bottom: 0.5rem !important;
    }
}
.text-center {
    text-align: center !important;
}
@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }
}
.text-danger {
    color: #dc3545 !important;
}
a.text-danger:focus,
a.text-danger:hover {
    color: #a71d2a !important;
}
.text-light {
    color: #f8f9fa !important;
}
a.text-light:focus,
a.text-light:hover {
    color: #cbd3da !important;
}
@media print {
    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }
    a:not(.btn) {
        text-decoration: underline;
    }
    img {
        page-break-inside: avoid;
    }
    p {
        orphans: 3;
        widows: 3;
    }
    @page {
        size: a3;
    }
    .container {
        min-width: 992px !important;
    }
}
/*# sourceMappingURL=bootstrap.min.css.map */
/* Template Name: Landrick - Saas & Software Landing Page Template
   Author: Themesbrand
   E-mail: themesbrand@gmail.com
   Version: 2.6
   Updated: November 2020
   File Description: Main Css file of the template
*/
/*********************************/
/*         INDEX                 */
/*================================
 *     01.  Variables            *
 *     02.  Bootstrap Custom     *
 *     03.  Components           *
 *     04.  General              *
 *     05.  Helper               *
 *     06.  Menu                 *
 *     07.  Home / Landing       *
 *     08.  Features / Services  *
 *     09.  Testimonial          *
 *     10.  Price                *
 *     11.  C.T.A                *
 *     12.  Blog / Careers / Job *
 *     13.  Work / Projects      *
 *     14.  Team                 *
 *     15.  User Pages           *
 *     16.  Countdown            *
 *     17.  Contact              *
 *     18.  Footer               *
 *********************************
 
 *********************************
 *         Dark SCSS             *
 *********************************
 *     01.  Variables            *
 *     02.  Bootstrap Custom     *
 *     03.  Components           *
 *     04.  General              *
 *     05.  Helper               *
 *     06.  Menu                 *
 ================================*/
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');

h5 {
    font-size: 20px !important;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.text-danger {
    color: #e43f52 !important;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #e1293e !important;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.text-light {
    color: #f8f9fc !important;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a.text-light:hover,
a.text-light:focus {
    color: #e6eaf4 !important;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.list-inline-item:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 5px;
}

.rounded {
    border-radius: 6px !important;
}

.btn-soft-primary {
    background-color: rgba(47, 85, 212, 0.1) !important;
    border: 1px solid rgba(47, 85, 212, 0.1) !important;
    color: #17d0c1 !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
    box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-soft-primary:hover,
.btn-soft-primary:focus,
.btn-soft-primary:active {
    background-color: #17d0c1 !important;
    border-color: #17d0c1 !important;
    color: #ffffff !important;
}

.btn {
    padding: 8px 20px;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-weight: 600;
    border-radius: 6px;
}

.btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

@-webkit-keyframes animate-positive {
    0% {
        width: 0;
    }
}

@keyframes animate-positive {
    0% {
        width: 0;
    }
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    font-size: 14px;
    font-weight: 700;
}

.form-group .form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    color: #3c4858;
    height: 42px;
    font-size: 13px;
    border-radius: 6px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.form-group .form-control:focus {
    border-color: #17d0c1;
}

.form-control:disabled {
    background-color: transparent;
    opacity: 1;
}

.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #17d0c1;
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.social-icon li a {
    color: #3c4858;
    border: 1px solid #3c4858;
    display: inline-block;
    height: 32px;
    width: 32px;
    line-height: 28px;
    text-align: center;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    overflow: hidden;
    position: relative;
}

.social-icon li a .fea-social {
    stroke-width: 2;
}

.social-icon li a:hover {
    background-color: #17d0c1;
    border-color: #17d0c1 !important;
    color: #ffffff !important;
}

.social-icon li a:hover .fea-social {
    fill: #17d0c1;
}

.social-icon.social li a {
    color: #adb5bd;
    border-color: #adb5bd;
}

.fea {
    stroke-width: 1.8;
}

.fea.icon-sm {
    height: 16px;
    width: 16px;
}

h5,
.h5 {
    font-family: 'Nunito', sans-serif;
    line-height: 1.5;
    font-weight: 600;
}

::-moz-selection {
    background: rgba(47, 85, 212, 0.9);
    color: #ffffff;
}

::selection {
    background: rgba(47, 85, 212, 0.9);
    color: #ffffff;
}

a {
    text-decoration: none !important;
}

p {
    line-height: 1.6;
}

@-webkit-keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
}

@keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
}

@-webkit-keyframes spinner-animation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-animation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.logo {
    font-weight: 700;
    font-size: 24px;
    padding: 0 0 6px;
    letter-spacing: 1px;
    line-height: 68px;
}

.form-group .icons {
    position: absolute;
    top: 13px;
    left: 18px;
}

.footer {
    background: #202942;
    padding: 60px 0;
    position: relative;
    color: #adb5bd;
}

.footer .logo-footer {
    font-size: 22px;
}

.footer .footer-head {
    letter-spacing: 1px;
    font-weight: 500;
}

.footer .foot-subscribe .form-control {
    background-color: #27314f;
    border: 1px solid #27314f;
    color: #f8f9fc;
}

.footer .foot-subscribe .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.footer .foot-subscribe input::-webkit-input-placeholder {
    color: #adb5bd;
}

.footer .foot-subscribe input:-ms-input-placeholder {
    color: #adb5bd;
}

.footer .foot-subscribe input::-ms-input-placeholder {
    color: #adb5bd;
}

.footer .foot-subscribe input::placeholder {
    color: #adb5bd;
}

.footer .text-foot {
    color: #adb5bd;
}

.footer .footer-list {
    margin-bottom: 0;
}

.footer .footer-list li {
    margin-bottom: 10px;
}

.footer .footer-list li a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.footer .footer-list li a:hover {
    color: #e6e8ea;
}

.footer .footer-list li:last-child {
    margin-bottom: 0;
}

.footer.footer-bar {
    border-top: 1px solid #283353;
}

.footer.footer-bar {
    padding: 30px 0;
}

.text-danger {
    color: #e43f52 !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #e1293e !important;
}

.text-light {
    color: #253649 !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #1c2938 !important;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    font-size: 14px;
    font-weight: 700;
}

.form-group .form-control {
    background-color: #1f2d3d;
    border: 1px solid #6c757d;
    color: #adb5bd;
}

.form-group .form-control::-webkit-input-placeholder {
    color: #adb5bd;
}

.form-group .form-control:-ms-input-placeholder {
    color: #adb5bd;
}

.form-group .form-control::-ms-input-placeholder {
    color: #adb5bd;
}

.form-group .form-control::placeholder {
    color: #adb5bd;
}

.social-icon li a,
.social-icon .social li a {
    color: #adb5bd;
    border: 1px solid #adb5bd;
}

.social-icon li a:hover,
.social-icon .social li a:hover {
    color: #ffffff !important;
}

.footer {
    background: #243447;
    color: #adb5bd;
}

.footer .logo-footer {
    color: #ffffff;
}

.footer .footer-head {
    color: #ffffff !important;
}

.footer .foot-subscribe .form-control {
    background-color: #354d69;
    border: 1px solid #354d69;
    color: #253649;
}

.footer .foot-subscribe input::-webkit-input-placeholder {
    color: #adb5bd;
}

.footer .foot-subscribe input:-ms-input-placeholder {
    color: #adb5bd;
}

.footer .foot-subscribe input::-ms-input-placeholder {
    color: #adb5bd;
}

.footer .foot-subscribe input::placeholder {
    color: #adb5bd;
}

.footer .text-foot {
    color: #adb5bd;
}

.footer .footer-list li a:hover {
    color: #e6e8ea;
}

.footer.footer-bar {
    border-top: 1px solid #2d4158;
}

::-moz-selection {
    color: #ffffff;
}

::selection {
    color: #ffffff;
}
/*# sourceMappingURL=style-dark.css.map */
.flag-icon {
    width: 8%;
    height: auto;
}
</style>
