<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="500">
            <v-card color="#555C70">
                <v-card-title class="headline modal grey text-white"> NOTE </v-card-title>

                <v-card-text class="modal text-white">
                    To offer you the best prices and the most gold for your items, start by filling out your Game
                    Version, Region, Server and Faction in the middle of the site before trading
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#17D0C1" class="signin-btn mr-3" @click="hideModal()"> Okay </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        dialog: Boolean,
    },
    methods: {
        hideModal() {
            this.$emit('closeInfoModal');
        },
    },
};
</script>
<style scoped>
.v-dialog .modal.grey {
    background: #555c70;
}
.v-dialog .modal.text-white {
    color: #fff;
    padding: 10px 20px;
    font-size: 17px;
    line-height: 35px;
    font-family: 'Roboto';
}
</style>
