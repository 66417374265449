<template>
    <div class="buckets-wrapper">
        <div v-if="goldBuckets.length" class="breakdown">Trade Breakdown</div>
        <div class="buckets flex-inline">
            <div class="bucket" v-for="(bucket, bidx) in goldBuckets" :key="bidx">
                <div class="bold flex-inline bucket-game gap-5">
                    <img class="game-img" :src="getGameImg(bucket.game)" alt="" />
                    <span class="game">{{ bucket.game }}</span>
                    <div class="flex-inline gap-5">
                        <span class="price">{{ bucket.amount }} G</span>
                        <span class="bucket-bonus bold flex-inline" v-if="bucket.bonus">
                            <span class="extra-gold">+ {{ bucket.extra_gold }} G </span>

                            <div class="tooltip">
                                <MiniTag class="interactive" text="+10%" />
                                <span class="tooltiptext">Select All Items +10% Gold Bonus ({{ bucket.game }})</span>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PriceService from '../../utils/PriceService.js';
import AnimateInteger from '../UI/animateInteger';
import Coin from '../UI/SVG/coin.vue';
import csgo from '../../assets/steam-game-images/csgo.png';
import tf2 from '../../assets/steam-game-images/tf2.png';
import dota2 from '../../assets/steam-game-images/dota2.png';
import rust from '../../assets/steam-game-images/rust.png';
import steam from '../../assets/steam-game-images/steam.png';
import MiniTag from '../UI/mini-tag.vue';
export default {
    name: 'ts-gold-buckets',
    props: {},
    components: {
        Coin,
        AnimateInteger,
        MiniTag,
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(['offerItems', 'errors']),
        goldBuckets() {
            const buckets = {};

            for (const item of this.offerItems) {
                if (!buckets[item.game]) {
                    buckets[item.game] = {
                        gold: 0,
                        bucketBonus: false,
                    };
                }

                let EXACT_PRICE = item.steam_price;

                // if (typeof EXACT_PRICE === 'string') {
                //     const number = item.price.split('.').join('');
                //     // EXACT_PRICE = parseInt(number, 10);
                //     console.log(item.steam_price)
                //     EXACT_PRICE = item.steam_price // parseInt(item.steam_price, 10);
                // }

                buckets[item.game].gold += EXACT_PRICE; // parseInt(EXACT_PRICE, 10);

                if (this.$store.state.trade.bucketBonus[item.game.toLowerCase()]) {
                    buckets[item.game].bucketBonus = true;
                }
            }

            const formatBuckets = [];

            for (const key of Object.keys(buckets)) {
                const val = buckets[key];
                let goldAmount = val.gold;

                let EXACT_PRICE = val.gold;
                if (typeof EXACT_PRICE === 'string') {
                    const number = val.gold.split('.').join('');
                    goldAmount = parseInt(number);
                }
                const exact_gold_amount = goldAmount;
                let extra_gold = null;
                if (val.bucketBonus) {
                    goldAmount *= 1.1;
                    extra_gold = PriceService.calculatePrice(
                        val.gold * 0.1,
                        this.$store.state.trade.country,
                        this.$store.state.trade.extra_gold[1],
                    ).gold;
                }

                let formatPrice = PriceService.calculatePrice(
                    goldAmount,
                    this.$store.state.trade.country,
                    this.$store.state.trade.extra_gold[1],
                );

                const alias = {
                    cs: 'csgo',
                    tf: 'tf2',
                    dota: 'dota2',
                };

                formatBuckets.push({
                    exact_gold_amount: exact_gold_amount,
                    amount: parseInt(exact_gold_amount, 10),
                    extra_gold: extra_gold,
                    game: alias[key] ? alias[key].toUpperCase() : key.toUpperCase(),
                    price: formatPrice.gold,
                    bonus: val.bucketBonus,
                });
            }
            return formatBuckets;
        },
    },
    methods: {
        getGameImg(title) {
            const img = {
                csgo,
                dota2,
                rust,
                steam,
                tf2,
            };
            return img[title.toLowerCase()];
        },
    },
    watch: {
        goldBuckets: {
            handler(n_val, o_val) {
                this.$store.commit('SET_GOLD_BUCKETS', n_val);
                if (!n_val.length) {
                    this.$store.state.trade.bucketBonus = {
                        steam: false,
                        dota: false,
                        cs: false,
                        rust: false,
                        tf: false,
                    };
                }
            },
            immediate: true,
        },
    },
    mounted: function () {
        //
    },
};
</script>

<style scoped lang="scss">
.interactive {
    cursor: pointer;
}
.breakdown {
    color: #f1f1f1;
    font-weight: bold;
    padding-bottom: 5px;
}
.buckets-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.buckets {
    color: #f1f1f1;
    max-width: 719px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    // align-content: center;
    // justify-content: center;
}

.bucket {
    min-width: 320px;
    display: flex;
    gap: 5px;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    // padding-block: 15px;
}

.price {
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    color: #fcc938;
}

.extra-gold {
    color: #fcc938;
}

.game {
    color: #f1f1f1;
}

.game-img {
    max-height: 25px;
}
.bucket-bonus {
    color: #17d0c1 !important;
}

.white-color {
    color: #f1f1f1 !important;
}

.gap-5 {
    gap: 5px !important;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 240px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}
</style>
