<template>
    <v-lazy
        v-model="isActive"
        :options="{
            threshold: 0.5,
        }"
        min-height="200"
        transition="fade-transition"
    >
        <v-container class="service-wrapper">
            <v-row class="service-row">
                <v-cols cols="12">
                    <div class="tp-widget-mobile">
                        <trustpilot-horizontal></trustpilot-horizontal>
                    </div>
                    <Products :propProducts="products" class="service-cards" />
                    <div class="tp-widget-pc">
                        <!-- <trustpilot-horizontal></trustpilot-horizontal> -->
                        <trustPilot :templateId="'5419b6ffb0d04a076446a9af'"></trustPilot>
                    </div>
                </v-cols>
            </v-row>
        </v-container>
    </v-lazy>
</template>

<script>
import { services } from '../../utils/productService';
import Products from '../../components/Shop/products.vue';
export default {
    name: 'Trade Steam Skins to WoW Gold',
    metaInfo: {
        title: 'Trade Steam Skins to WoW Gold',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content:
                    'Get started using Trade-Raids MMO Gold trading Services. Use our Trade Service to Trade your Steam Items to MMO gold or Exchange Service to Trade Gold Between Servers, Retail and WOTLK.',
            },
        ],
    },
    components: {
        Products,
    },
    data() {
        return {
            products: null,
        };
    },
    mounted: function () {
        this.products = services;
    },
};
</script>

<style lang="scss" scoped>
.description_short {
    font-size: 1.2rem;
}
.service-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto !important;
    margin-top: 10rem;
}
.service-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 75px;
    flex: 1 0 auto !important;
}
.service-cards {
    // min-width: 50vw;
    display: flex;
    justify-content: center;
}

.tp-widget-pc {
    height: 50px;
    width: 100%;
    padding-top: 50px;
}

// norm screens
@media (min-width: 1281px) {
    .tp-widget-mobile {
        display: none;
    }
}

// norm screens
@media (min-width: 501px) {
    .tp-widget-mobile {
        display: none;
    }
}

@media (max-width: 500px) {
    .tp-widget-mobile {
        max-height: 50px;
        width: 100%;
        z-index: 1;
    }
    .tp-widget-pc {
        display: none;
    }
}

@media (max-width: 540px) {
    .service-cards {
        min-width: 100%;
    }
}
</style>
