<template>
    <v-btn class="shopping-cart background-color-gradient" @click="setAsideState()">
        <div class="shopping-cart-content">
            <ShoppingCartIcon />
            <div>{{ computedItemsLength }}</div>
        </div>
    </v-btn>
</template>

<script>
import './utility.styles.css';
export default {
    name: 'shopping-cart-module',
    data() {
        return {
            aside: false,
        };
    },
    computed: {
        computedItemsLength() {
            return `(${this.$store.state.shop.cartItems.length})`;
        },
    },
    methods: {
        setAsideState() {
            if (this.$store.state.shop.aside) {
                this.$store.dispatch('toggleAside', false);
            } else {
                this.$store.dispatch('toggleAside', true);
            }
        },
    },
};
</script>
