import request from '../utils/request';

export function getShop() {
    return request({
        url: '/all/products',
        method: 'GET',
    });
}

export function getUnitCost(game) {
    return request({
        url: `/shop/gold-units/${game}`,
        method: 'GET',
    });
}

export function checkOut(items, user, paymentInfo, discord) {
    return request({
        url: `/shop/create-checkout-session`,
        method: 'POST',
        data: {
            items,
            user,
            paymentInfo,
            discord,
        },
    });
}

export function orderController(token, status) {
    return request({
        url: `/shop/order-controller`,
        method: 'POST',
        data: {
            token,
            status,
        },
    });
}

export function paymentInfo() {
    return request({
        url: '/shop/payment-info',
        method: 'GET',
    });
}
