import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"500"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{attrs:{"color":"#555C70"}},[_c(VCardTitle,{staticClass:"headline modal grey text-white"},[_vm._v("Note")]),_c(VCardText,{staticClass:"modal text-white"},[_vm._v(" Need more gold than what your skins offer? Buy your gold at our Shop to the most competitive prices on the market. ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"#17D0C1"},on:{"click":function($event){return _vm.handleClick('navigation')}}},[_vm._v(" GO TO SHOP ")]),_c(VBtn,{attrs:{"color":"#17D0C1"},on:{"click":function($event){return _vm.handleClick('stay')}}},[_vm._v(" STAY ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }