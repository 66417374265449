<template>
    <div>
        <a class="e-widget no-button" href="https://gleam.io/Lrpd5/patch-101-wow-gold-giveaway" rel="nofollow"
            >Dragonflight Launch Giveaway</a
        >
    </div>
</template>
<script>
export default {
    created() {
        var scripts = ['https://widget.gleamjs.io/e.js'];
        scripts.forEach((script) => {
            let tag = document.createElement('script');
            tag.setAttribute('src', script);
            document.head.appendChild(tag);
        });
    },
};
</script>
