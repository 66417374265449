<template>
    <v-btn class="vault dark-grey-bg" @click="vault = true">
        <div class="vault-content">
            <div class="vault-info">
                {{ vaultBalance }}
                <img src="./vault_icon_medium.png" />
            </div>
            <div class="icon-wrap">+</div>
        </div>
    </v-btn>
</template>

<script>
export default {
    name: 'VaultModule',
    data() {
        return {};
    },
    computed: {
        vaultBalance() {
            return '775k';
        },
    },
};
</script>

<style scoped>
.icon-wrap {
    background-image: linear-gradient(#5761ff, #4b90ff);
    padding-block: 2px;
    padding-inline: 5px;
    border-radius: 5px;
    display: flex;
    gap: 5px;
    font-size: 16px;
}
.vault-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.vault-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 16px;
}
.vault {
    background-color: #383f51 !important;
    margin-top: 15px;
    height: 45px !important;
    display: flex;
    flex-direction: row;
    height: 45px;
    color: #f1f1f1 !important;
}
</style>
