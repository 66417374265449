<template>
    <v-container fluid fill-height id="after-trade">
        <section style="background-color: #20283c">
            <div class="help-container">
                <h2 style="font-size: 200%; color: white; font-weight: bold" class="text-center">
                    Thank you for your order request!
                </h2>
                <h2 style="font-size: 150%; color: white" class="text-center">Cancel or Start your trade here</h2>
                <p class="process-sub-txt text-center">
                    Are the trade details below incorrect? Do you want to edit the server or the trade amount? <br />
                    Press the ''CANCEL TRADE'' button to cancel your current trade and return to the trade page.<br /><br />
                    Are you ready to in-game mail the Deposit Gold Amount to Trade-Raid's Gold Recipient? <br />
                    Press the ''READY TO TRADE'' button to signal Trade-Raid and start your order.
                </p>
                <!-- <p class="process-sub-txt text-center">Start new trade here</p>.  -->
                <div class="text-center">
                    <v-btn color="error" class="ma-2 white--text" x-large @click="navigateToTradePage"
                        >Cancel Trade</v-btn
                    >
                    <v-btn color="success" class="ma-2 white--text" x-large @click="openReadyDialog"
                        >Ready to trade</v-btn
                    >
                </div>
                <br />
                .
            </div>
        </section>
        <section style="background-color: #383f51">
            <div class="banner-container justify-center align-center">
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="3" xl="3">
                        <img
                            src="https://trade-raid-com.mo.cloudinary.net/icons/received.svg"
                            width="100%"
                            height="auto"
                            alt="received"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="6" offset-md="1" xl="6" offset-xl="1" class="top-main-banner-txt">
                        <v-card-text class="top-main-sub-txt"
                            >Press the ''READY TO TRADE'' Button Above when you are ready to in-game mail your Deposit
                            Gold Amount to Trade-Raid's recipient:</v-card-text
                        >

                        <v-row>
                            <v-col cols="12" sm="12" md="8">
                                <div class="text-center">
                                    <span class="dtl-left">Character Name: </span>
                                    <span class="dtl-right">{{ this.receiver_character_name }}</span>
                                </div>
                                <div class="text-center">
                                    <span class="dtl-left">Server: </span>
                                    <span class="dtl-right">{{ this.receiver_server }}</span>
                                </div>
                                <div class="text-center">
                                    <span class="dtl-left">Faction: </span>
                                    <span class="dtl-right">{{ this.receiver_faction }}</span>
                                </div>
                            </v-col>

                            <!-- <v-col cols="12" sm="12" md="6">
								<div class="text-center">
									<span class="dtl-left">From Character Name: </span>
									<span class="dtl-right">{{ this.trade_details ? this.trade_details.deposit_character_name : null }}</span>
								</div>
								<div class="text-center">
									<span class="dtl-left">To Character Name: </span>
									<span class="dtl-right">{{ this.trade_details ? this.trade_details.exchange_character_name : null }}</span>
								</div>
							</v-col> -->
                        </v-row>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" md="12">
                        <h2 class="order-header text-center">Order Information:</h2>
                        <h2 class="order-header text-center">
                            Your Email: <span class="email-text">{{ this.trade_email ? this.trade_email : null }}</span>
                        </h2>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" md="6">
                        <div class="trade-deposit-container">
                            <div class="trade-deposit-inner">
                                <div class="text-center py-2">
                                    <span class="dtl-left">I have gold on:</span>
                                </div>

                                <div class="text-center py-2">
                                    <span class="dtl-left">Version: </span>
                                    <span class="dtl-right">{{
                                        this.trade_details ? this.trade_details.deposit_version : null
                                    }}</span>
                                </div>

                                <div class="text-center py-2">
                                    <span class="dtl-left">Region: </span>
                                    <span class="dtl-right">{{
                                        this.trade_details ? this.trade_details.deposit_region : null
                                    }}</span>
                                </div>

                                <div class="text-center py-2">
                                    <span class="dtl-left">Server-Faction:</span>
                                    <span class="dtl-right">{{
                                        this.trade_details ? this.trade_details.deposit_server : null
                                    }}</span>
                                </div>

                                <div class="text-center py-2">
                                    <span class="dtl-left">Deposit Amount: </span>
                                    <span class="dtl-right"
                                        >{{ this.trade_details ? this.trade_details.deposit_amount : null }} Gold</span
                                    >
                                </div>

                                <div class="text-center py-2">
                                    <span class="dtl-left">Gold Delivery Method: </span>
                                    <span class="dtl-right">{{
                                        this.trade_details ? this.trade_details.deposit_delivery_method : null
                                    }}</span>
                                </div>

                                <div class="text-center py-2">
                                    <span class="dtl-left">Character Name: </span>
                                    <span class="dtl-right">{{
                                        this.trade_details ? this.trade_details.deposit_character_name : null
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="trade-exchange-container">
                            <div class="trade-exchange-inner align-center">
                                <div class="text-center py-2">
                                    <span class="dtl-left">I want to receive gold on:</span>
                                </div>

                                <div class="text-center py-2">
                                    <span class="dtl-left">Version: </span>
                                    <span class="dtl-right">{{
                                        this.trade_details ? this.trade_details.exchange_version : null
                                    }}</span>
                                </div>

                                <div class="text-center py-2">
                                    <span class="dtl-left">Region: </span>
                                    <span class="dtl-right">{{
                                        this.trade_details ? this.trade_details.exchange_region : null
                                    }}</span>
                                </div>

                                <div class="text-center py-2">
                                    <span class="dtl-left">Server-Faction:</span>
                                    <span class="dtl-right">{{
                                        this.trade_details ? this.trade_details.exchange_server : null
                                    }}</span>
                                </div>

                                <div class="text-center py-2">
                                    <span class="dtl-left">Exchange Amount: </span>
                                    <span class="dtl-right"
                                        >{{ this.trade_details ? this.trade_details.exchange_amount : null }} Gold</span
                                    >
                                </div>

                                <div class="text-center py-2">
                                    <span class="dtl-left">Gold Delivery Method: </span>
                                    <span class="dtl-right">{{
                                        this.trade_details ? this.trade_details.exchange_delivery_method : null
                                    }}</span>
                                </div>

                                <div class="text-center py-2">
                                    <span class="dtl-left">Character Name: </span>
                                    <span class="dtl-right">{{
                                        this.trade_details ? this.trade_details.exchange_character_name : null
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </section>

        <section id="cta" class="wrapper" style="background-color: #20283c">
            <!-- <div class="help-container">
				<h2 style="font-size:200%; color:white;" class="text-center">Trade Complete?</h2>
				<p class="process-sub-txt text-center">Have you sent the gold to our recipient above and are you ready to trade?</p>
				<p class="process-sub-txt text-center">Press the button below</p>. 
				<div class="text-center">
					<v-btn color="blue-grey" class="ma-2 white--text" x-large @click="acceptTradeToTradePage" >Trade Again</v-btn>
				</div>
				<br>
			</div> -->
            <div>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="12" xl="12">
                        <p class="process-main-txt">The trade & gold delivery process is shown here</p>
                        <p class="process-sub-txt">
                            The Gold delivery proces will only begin once you in-game mail the gold to Trade-Raid’s gold
                            recipient found at the top of this page.<br />
                        </p>
                    </v-col>
                </v-row>
            </div>

            <div class="flex-column flex-md-row align-center" id="process">
                <div class="icon-block">
                    <div class="svg">
                        <img src="https://trade-raid-com.mo.cloudinary.net/after-trade/step_1.png" alt="Step image" />
                    </div>
                    <h3>Step 1</h3>
                    <p>You in-game mail the gold to Trade-Raid</p>
                </div>
                <div class="icon-block">
                    <div class="svg">
                        <img src="https://trade-raid-com.mo.cloudinary.net/after-trade/step_2.png" alt="Step image" />
                    </div>
                    <h3>Step 2</h3>
                    <p>We confirm that we have received your gold in-game</p>
                </div>
                <div class="icon-block">
                    <div class="svg">
                        <img src="https://trade-raid-com.mo.cloudinary.net/after-trade/step_3.png" alt="Step image" />
                    </div>
                    <h3>Step 3</h3>
                    <p>Trade-Raid is preparing your gold</p>
                </div>
                <div class="icon-block">
                    <div class="svg">
                        <img src="https://trade-raid-com.mo.cloudinary.net/after-trade/step_4.png" alt="Step image" />
                    </div>
                    <h3>Step 4</h3>
                    <p>Trade-Raid will deliver the gold to you in-game</p>
                </div>
                <div class="icon-block">
                    <div class="svg">
                        <img src="https://trade-raid-com.mo.cloudinary.net/after-trade/step_5.png" alt="Step image" />
                    </div>
                    <h3>Step 5</h3>
                    <p>Review us on Trustpilot!</p>
                </div>
            </div>
        </section>
        <div></div>
        <!-- <section class="collapse-container">
			<v-expansion-panels flat>
				<v-expansion-panel
					v-for="(faq, index) in faqs2"
					:key="index"
					>
					<v-expansion-panel-header expand-icon="mdi-chevron-down">{{faq.title}}</v-expansion-panel-header>
					<v-expansion-panel-content
						class="flex-column"
						>
						<div v-html="faq.ans"></div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</section> -->
        <template>
            <v-dialog v-model="popupDialog" persistent max-width="600">
                <v-card color="#383F51">
                    <v-card-title class="white--text">
                        <span class="text-h5"> I HAVE SENT THE GOLD TO TRADE-RAID </span>
                    </v-card-title>
                    <v-card-text class="white--text">
                        You are about to signal Trade-Raid that you have mailed the in-game gold to Trade-Raid's
                        recipient: {{ this.receiver_character_name }} on {{ this.receiver_server }}
                        {{ this.receiver_faction }}. <br />
                        Please ensure that you have sent the gold to the Trade-Raid recipient correctly.
                    </v-card-text>
                    <v-container fluid style="margin-top: 1px">
                        <v-checkbox
                            v-model="ex4"
                            label="success"
                            color="success"
                            hide-details
                            @click="toggleAcceptTrade"
                        >
                            <template v-slot:label>
                                <div style="color: #fff">
                                    I agree that I have sent the gold to Trade-Raid's recipient correctly.
                                </div>
                            </template>
                        </v-checkbox>
                    </v-container>
                    <v-card-actions>
                        <v-col cols="6" sm="300" class="text-left">
                            <v-btn depressed color="error" @click="popupDialog = false" text-left> GO BACK </v-btn>
                        </v-col>
                        <v-col cols="12" sm="6" class="text-right">
                            <v-btn
                                v-if="acceptTrade"
                                elevation="2"
                                color="success"
                                :loading="loading"
                                @click="remove"
                                text-right
                                >I HAVE SENT MY GOLD</v-btn
                            >
                            <v-btn v-else elevation="2" color="success" disabled text-right>I HAVE SENT MY GOLD</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        <template>
            <v-dialog v-model="depositDialog" persistent max-width="600">
                <v-card color="#383F51">
                    <v-card-title class="white--text justify-center"> Notification received! </v-card-title>
                    <v-card-text class="white--text text-center">
                        You have now notified our system that you have correctly in-game mailed the Gold amount to our
                        gold recipient!<br />
                        We will now begin confirming and preparing your Gold order!<br />
                        Thank you for using Trade-Raid.com
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            block
                            color="info"
                            elevation="2"
                            raised
                            rounded
                            @click="depositDialog = false"
                            href="/exchange"
                        >
                            Great - back to Exchange page
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
    name: 'Exchange Success',
    metaInfo: {
        title: 'Exchange Success',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content: 'Exchange completed and ready to be delivered',
            },
        ],
    },
    computed: {
        ...mapGetters(['trade_details', 'trade_email', 'token']),
    },
    props: ['userData', 'deposit_character_name', 'exchange_character_name'],
    data: () => ({
        faqs2: [
            {
                title: 'Refer your friends! (Up to 550.000G The War Within, 5000G Wrath of the Lich King Classic or 2500 Season of Mastery Gold)',
                ans: `
					<p>Recruit your friends to use Trade-Raid's Steam to MMO gold Service and earn gold for each friend you refer!
					Get started here: <a target="_blank" href="https://trade-raid.com/giveaways" style="color:#fff">Giveaways Page</a>
					</p>
				`,
            },
            {
                title: 'Share your feedback with Trade-Raid (+5000 The War Within, 25 Wrath of the Lich King Classic Gold or 10 Classic Season of Mastery Gold)',
                ans: `
                    <p>Answer our short Feedback Survey and choose between to receive 5000 The War Within Gold, 25 Wrath of the Lich King Classic Gold or 10 Classic Season of Mastery Gold! All you need to do is share your first impressions and opinions of using Trade-Raid's services!
                    <br>Get started here: <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdnkhfGqQgBHbGGgornL5r4aGJNysObFpGXJaVGeNZuXrGj6A/viewform" style="color:#fff">Trade-Raid’s Feedback Survey</a>
                    <br>All users are encouraged to participate in our survey whether you have used the Trade-Raid service or not. This reward can be received in combination with other Rewards or Trade-Raid orders.
                </p>
                `,
            },
            {
                title: 'Why did I not receive a trade-offer from Trade-Raid?',
                ans: `
					<p>If you did not receive your trade offer it is because:
					<br>1) You are trade-locked (You have reset your password, your Steam Mobile Guard etc.) Try to send a trade offer to a friend on Steam and see if that is possible.
					<br>2) You are trying to trade items from a game(s) that you are VAC-banned from. It is not possible to trade Steam Items from a game Steam has VAC banned you from playing. Try to create a new trade-offer with items from games you are not VAC banned from.
					<br>3) Our trade-bot(s) are undergoing maintenance. Please contact Trade-Raid <a target="_blank" href="https://trade-raid.com/support" style="color:#fff">Support</a>.
					</p>
				`,
            },
            {
                title: 'My initial trade offer was declined, and I have received a new offer, what do I do?',
                ans: `
					<p>Always double check the Trade-bot's Steam Level and Registration Date both when you accept the trade offer and when you confirm the offer on your phone. They must match. If not, please decline your trade immediately without hesitation.</p>
					<p>If the first trade offer you created was declined automatically, and you receive a new offer that you have not created (a second trade offer) please also decline that trade immediately without hesitation. You may be API scammed.</p>
					<p>Please read more about API scams here: https://dmarket.com/blog/steam-api-key-scam/</p>
					<p>For Danish Users: https://scammed.dk/api-scam/</p>
					<p>You can always reach out for Trade-Raid support if you need further assistance.</p>
				`,
            },
        ],
        receiver_character_name: null,
        receiver_server: null,
        receiver_faction: null,
        loading: false,
        popupDialog: false,
        depositDialog: false,
        ex4: ['success'],
        acceptTrade: false,
    }),
    methods: {
        toggleAcceptTrade() {
            this.acceptTrade = !this.acceptTrade;
        },
        async remove() {
            this.loading = true;
            await new Promise((resolve) => setTimeout(resolve, 3000));
            this.loading = false;
            this.popupDialog = false;
            this.depositDialog = true;
            // location.href = "#process"
            if (this.trade_email) {
                let url = `${process.env.VUE_APP_BACKEND}/exchange/accept-trade`;
                axios
                    .post(
                        url,
                        { email: this.trade_email, order_id: this.trade_details.order_id },
                        {
                            headers: { Authorization: `Bearer ${this.token}` },
                        },
                    )
                    .catch((response) => {
                        console.log(error);
                    });
            }
        },
        navigateToTradePage() {
            if (this.trade_email) {
                let url = `${process.env.VUE_APP_BACKEND}/exchange/decline-trade`;
                axios
                    .post(
                        url,
                        { email: this.trade_email, order_id: this.trade_details.order_id },
                        {
                            headers: { Authorization: `Bearer ${this.token}` },
                        },
                    )
                    .then((response) => {
                        this.$router.push({ name: 'Exchange' });
                    })
                    .catch((response) => {
                        console.log(error);
                    });
            } else {
                this.$router.push({ name: 'Exchange' });
            }
        },
        openReadyDialog(data) {
            this.popupDialog = true;
        },
        openDepositDialog(data) {
            this.depositDialog = true;
        },
        acceptTradeToTradePage() {
            if (this.trade_email) {
                let url = `${process.env.VUE_APP_BACKEND}/api/accept-trade`;
                axios
                    .post(
                        url,
                        { email: this.trade_email },
                        {
                            headers: { Authorization: `Bearer ${this.token}` },
                        },
                    )
                    .catch((response) => {
                        console.log(error);
                    });
            }
        },
    },
    mounted: function () {
        var deposit_server = this.trade_details ? this.trade_details.deposit_server : null;
        if (deposit_server) {
            deposit_server = deposit_server.split(' - ');
            this.receiver_server = deposit_server[0];
            this.receiver_faction = deposit_server[1];
            this.receiver_character_name = this.trade_details.receiver_character_name;
        }
    },
};
</script>

<style>
.order-header span.email-text {
    color: #17d0c1;
}
#faq .v-expansion-panel-content p {
    margin-bottom: 0;
}
.v-stepper--alt-labels .v-stepper__header .v-divider {
    margin: 48px -62px 0 !important;
    align-self: flex-start;
}
.v-stepper__step__step {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    font-size: 0.75rem;
    justify-content: center;
    height: 50px !important;
    min-width: 50px !important;
    width: 50px !important;
}
.order-header {
    color: #fff;
}
</style>

<style lang="scss" scoped>
.banner-container img {
    margin-right: 100px;
}
#after-trade {
    padding-top: calc(80px + 100px);
    padding-bottom: 124px;
    flex-direction: column;
    align-content: center;
    background: linear-gradient(
        90deg,
        rgba(0, 14, 25, 0.1) 0%,
        rgba(15, 24, 45, 0.1) 30.7%,
        rgba(62, 0, 108, 0.1) 60.29%,
        rgba(11, 72, 67, 0.1) 79.69%,
        rgba(15, 24, 45, 0.1) 95.48%
    );

    .trade-deposit-container {
        .trade-deposit-inner {
            border: 1px solid rgba(255, 255, 255);
            margin: 30px;
            p {
                text-align: center;
                margin-top: 5px;
            }
        }
    }

    .trade-exchange-container {
        .trade-exchange-inner {
            border: 1px solid rgba(255, 255, 255);
            margin: 30px;
            p {
                text-align: center;
                margin-top: 15px;
            }
        }
    }
    .collapse-container {
        width: 100%;
        max-width: 1170px;
        .v-expansion-panel {
            border-radius: 2px;
            background: #383f51;
            margin-top: 0;
            margin-bottom: 20px;
        }
        .v-expansion-panel-header {
            padding: 25px 30px;
            font-size: 18px;
            line-height: 24px;
            color: #fff;
            &::v-deep .v-expansion-panel-header__icon i {
                color: #fff;
            }
            &.v-expansion-panel-header--active,
            &.v-expansion-panel-header--active::v-deep .v-expansion-panel-header__icon i {
                color: #17d0c1;
            }
        }
        .v-expansion-panel-content {
            padding-left: 30px;
            padding-right: 30px;
            font-size: 16px;
            line-height: 30px;
            color: rgba(255, 255, 255, 1);
            div {
                padding-top: 0;
                padding-bottom: 25px;
            }
        }
        .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
            padding: 0;
            font-size: 16px;
            line-height: 30px;
            color: rgba(255, 255, 255, 0.7);
        }
        table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
        }

        td,
        th {
            border: 2px solid black;
            text-align: left;
            padding: 10px;
        }

        tr:nth-child(even) {
            background-color: #99929273;
        }
    }
    section {
        width: 80%;
        max-width: 1530px;
        padding: 25px 24px;
        &:first-child {
            padding: 60px 24px;
        }
        &:nth-child(2) {
            padding: 50px 24px 100px;
        }
        &:nth-child(3) {
            padding: 80px 24px;
        }
        &:nth-child(2n + 1) {
            background: #383f51;
        }
        .receive-msg {
            font-weight: 600;
            font-size: 36px;
            line-height: 75px;
            color: #ffffff;
            margin: 0;
        }
        .steam-ref {
            text-decoration: none;
            background-color: #17d0c1;
            color: #ffffff;
            padding: 10px 10px 10px 10px;
            border-radius: 2px;
        }
        .steam-ref:hover {
            opacity: 0.3;
        }
        .icon-block p {
            margin-bottom: 0;
        }
        &#cta .inner > p {
            max-width: 56%;
        }
        .svg img {
            width: 70px;
            height: 70px;
        }
        &:last-child {
            text-align: center;
            h3 {
                font-weight: 600;
                font-size: 36px;
                line-height: 60px;
                color: #ffffff;
                margin-bottom: 20px;
            }
            p {
                font-size: 18px;
                line-height: 35px;
                display: flex;
                align-items: center;
                color: rgba(255, 255, 255, 0.7);
                margin-bottom: 30px;
                text-align: center;
                display: inline-block;
                width: 100%;
            }
        }
    }
    #cta,
    .help-container {
        .process-main-txt {
            font-size: 20px;
            color: #fff;
            font-weight: bold;
            padding-bottom: 10px;
        }
        .process-sub-txt {
            font-size: 16px;
            color: #fff;
        }
    }
    .banner-container {
        .top-main-banner-txt h2 {
            color: #fff;
            font-weight: bold;
        }
        .top-main-sub-txt {
            color: #fff;
            font-size: 25px;
            line-height: 30px;
        }
        .dtl-left {
            color: #fff;
            font-size: 20px;
            font-weight: bold;
        }
        .dtl-right {
            color: #17d0c1;
            font-size: 20px;
            font-weight: bold;
        }
    }
}
@media screen and (max-width: 1264px) {
    .banner-container img {
        margin-right: 0;
    }
    #cta .inner {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        max-width: unset;
    }
    #cta .inner:first-child {
        flex-direction: column;
        align-items: center;
    }
    #cta .icon-block:not(:last-child):after {
        display: none;
    }
}
</style>
