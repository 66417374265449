import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLazy,{attrs:{"options":{
        threshold: 0.5,
    },"min-height":"200","transition":"fade-transition"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c(VContainer,{attrs:{"fluid":"","fill-height":"","id":"blog"}},[[_c(VContainer,{staticClass:"backgroundImage",staticStyle:{"background-image":"url('https://blog1.trade-raid.com/wp-content/uploads/2021/07/home-hero-new@2x-1.jpg')"},attrs:{"fluid":"","id":"blog"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('h1',{staticClass:"blogTitle"},[_vm._v("The Trade-Raid.com Blog")]),_c('h2',{staticClass:"blogSubtext"},[_vm._v(" Read here to learn more about Trade-Raid.com, "),_c('br'),_vm._v("the team behind, our vision and how we're creating the cross game trading concept. ")])])],1)],1),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c(VProgressCircular,{attrs:{"size":70,"width":7,"color":"#fff","indeterminate":""}})],1):_vm._e(),[_c(VRow,{attrs:{"dense":"","no-gutters":"","align-content":"end"}},_vm._l((_vm.posts),function(blogPost){return _c(VCol,{key:blogPost.slug,staticStyle:{"height":"800px","width":"400px"},attrs:{"cols":"12","md":"6","lg":"4"}},[_c(VCard,{staticClass:"mx-auto my-12",staticStyle:{"background-color":"#20283c","color":"#fff"},attrs:{"elevation":"20","outlined":"","shaped":"","max-width":"460"}},[_c(VCardTitle,{staticStyle:{"height":"100px"}},[_c('router-link',{staticStyle:{"font-size":"20px","font-weight":"bold","color":"#fff"},attrs:{"to":{ path: '/blog/posts/' + blogPost.slug }}},[_vm._v(_vm._s(blogPost.title.rendered))])],1),_c('div',{staticClass:"categories-container"},_vm._l((blogPost._embedded['wp:term'][0]),function(category){return _c(VChip,{key:category.slug,staticClass:"ma-2 category-chip",attrs:{"color":"primary","small":""}},[_vm._v(_vm._s(category.name))])}),1),_c('div',{staticClass:"img-wrapper"},[_c('router-link',{attrs:{"to":{ path: '/blog/posts/' + blogPost.slug }}},[_c(VImg,{staticClass:"blog-overview-image",style:({
                                            backgroundImage: `url(${
                                                blogPost._embedded &&
                                                blogPost._embedded['wp:featuredmedia'][0].source_url
                                            })`,
                                        })})],1)],1),_c(VCardText,{staticStyle:{"font-size":"16px","color":"#fff","height":"120px"},domProps:{"innerHTML":_vm._s(blogPost.excerpt.rendered)}}),_c(VSpacer),_c(VRow,{staticStyle:{"padding-bottom":"20px"},attrs:{"justify":"space-around"}},[_c(VCardText,{staticStyle:{"font-size":"16px","color":"#fff"}},[_vm._v("Published: "+_vm._s(blogPost.date.split('T')[0]))]),_c(VBtn,{attrs:{"color":"teal accent-4","outlined":""},on:{"click":function($event){return _vm.$router.push({ path: '/blog/posts/' + blogPost.slug })}}},[_vm._v(" Read More ")])],1)],1)],1)}),1)]]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }