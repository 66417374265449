<template>
    <div>
        <!-- <a class="e-widget no-button" href="https://gleam.io/WolxR/traderaid-retail-mount-giveaway" rel="nofollow"
            >Trade-Raid WOTLK Gold Giveaway!</a
        > -->
    </div>
</template>
<script>
export default {
    created() {
        var scripts = ['https://widget.gleamjs.io/e.js'];
        scripts.forEach((script) => {
            let tag = document.createElement('script');
            tag.setAttribute('src', script);
            document.head.appendChild(tag);
        });
    },
};
</script>
