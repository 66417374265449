<template>
    <vue-trustpilot
        identifier="horizontal"
        data-locale="en-GB"
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="602b33e15092110001846808"
        data-style-height="140px"
        data-style-width="100%"
        data-theme="dark"
        data-stars="4,5"
    />
</template>

<script>
export default {
    created() {
        var scripts = ['https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'];
        scripts.forEach((script) => {
            let tag = document.createElement('script');
            tag.setAttribute('src', script);
            document.head.appendChild(tag);
        });
    },
};
</script>
