<template>
    <div class="profile-sidebar">
        <ul>
            <v-list-item class="list-item" :to="'/profile'">
                <UserIcon class="profile-icon" />Profile Info
            </v-list-item>
            <v-list-item class="list-item" :to="'/orders#ts'">
                <ZapIcon class="profile-icon" />Your Orders
            </v-list-item>
            <v-list-item class="list-item" :to="'/update-password'">
                <ShieldIcon class="profile-icon" />Security
            </v-list-item>
        </ul>
    </div>
</template>

<script>
import { ShieldIcon, UserIcon, ZapIcon } from 'vue-feather-icons';
export default {
    name: 'sidebarNav',
    props: {},
    components: {
        ShieldIcon,
        UserIcon,
        ZapIcon,
    },
    data() {
        return {};
    },
    methods: {},
    computed: {},
    mounted: function () {},
    watch: {},
};
</script>

<style scoped>
.profile-sidebar {
    width: 150px;
    height: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 65px;
    min-height: 100%;
}
.profile-sidebar > ul {
    list-style-type: none;
    padding-left: -24px;
    padding-right: 15px;
}
.profile-sidebar > ul > .list-item {
    color: #f1f1f1 !important;
    cursor: pointer;
    padding: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.profile-sidebar > ul > .list-item:hover {
    color: #17d0c1;
    background-color: hsl(220, 5%, 35%);
    border-radius: 5px;
}
.profile-icon {
    color: #17d0c1;
    margin-right: 15px;
}
h4 {
    color: #f1f1f1;
}
</style>
