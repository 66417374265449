<template>
    <v-lazy
        v-model="isActive"
        :options="{
            threshold: 1,
        }"
        min-height="200"
        transition="fade-transition"
    >
        <div class="header-slider">
            <h1 class="header-title" :class="{ 'fade-out': fadingOut, 'fade-in': fadingIn }">
                <span v-html="currentTitle"></span>
            </h1>
        </div>
    </v-lazy>
</template>

<script>
export default {
    name: 'HeaderSlider',
    data() {
        return {
            headerTitles: [
                "Trade Steam Items for <span class='highlighted-words'>WoW Gold</span>",
                "Exchange Retail Gold to <span class='highlighted-words'>Classic Gold</span>",
                "Trade CSGO Skins for WoW <span class='highlighted-words'>The War Within Gold</span>",
                "Trade CSGO Skins For <span class='highlighted-words'>WoW Classic Gold</span>",
                "Trade DOTA2 Skins for <span class='highlighted-words'>WoW The War Within Gold</span>",
                "Exchange Classic Gold to <span class='highlighted-words'>Retail Gold</span>",
                "Trade DOTA2 Skins for <span class='highlighted-words'>WoW Classic Gold</span>",
                "Trade RUST Skins for WoW <span class='highlighted-words'>The War Within Gold</span>",
                "Trade RUST Skins for WoW <span class='highlighted-words'>Classic Gold</span>",
                "Trade Gold Between Retail <span class='highlighted-words'>and Classic</span>",
            ],
            currentIndex: 0,
            fadingOut: false,
            fadingIn: false,
        };
    },
    computed: {
        currentTitle() {
            const title = this.headerTitles[this.currentIndex];
            const titleWithStyles = title
                .replace(/WoW Gold/g, '<span class="highlighted-words">WoW Gold</span><br>')
                .replace(/Buy WOTLK Gold/g, '<span class="highlighted-words">Buy WOTLK Gold</span><br>')
                .replace(/Exchange Gold/g, 'Exchange Gold<br>')
                .replace(
                    /across Retail and WOTLK/g,
                    '<span class="highlighted-words">across Retail and WOTLK</span><br>',
                )
                .replace(
                    /across Servers & Factions/g,
                    '<span class="highlighted-words">across Servers & Factions</span><br>',
                )
                .replace(/Buy FFXIV Gil/g, '<span class="highlighted-words">Buy FFXIV Gil</span><br>')
                .replace(/Buy Retail Gold/g, '<span class="highlighted-words">Buy Retail Gold</span><br>')
                .replace(/Buy Level boosts/g, '<span class="highlighted-words">Buy Level boosts</span><br>');
            return titleWithStyles;
        },
    },
    mounted() {
        this.startLoop();
    },
    methods: {
        startLoop() {
            setInterval(() => {
                this.fadingOut = true;
                setTimeout(
                    () => {
                        this.currentIndex = (this.currentIndex + 1) % this.headerTitles.length;
                        this.currentTitle = this.headerTitles[this.currentIndex];
                        this.fadingOut = false;
                        this.fadingIn = true;
                        setTimeout(() => {
                            this.fadingIn = false;
                        }, 500);
                    },
                    this.currentIndex === 0 ? 1500 : 2000,
                );
            }, 5000);
        },
    },
};
</script>

<style>
.header-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
}

.header-title {
    color: #fff;
    width: 100%;
    height: 10px;
    display: flex;
    align-items: left;
    justify-content: left;
    font-size: 40px !important;
    letter-spacing: 1px;
    text-align: left;
    font-family: Nunito, sans-serif;
    font-weight: 600;
    @media screen and (max-width: 768px) {
        height: 60%;
        height: 100px;
    }
}

.fade-out {
    opacity: 0;
    transition: opacity 1s ease-out;
}

.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in;
}

.highlighted-words {
    color: #17d0c1;
    font-weight: bold;
}
</style>
