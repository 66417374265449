import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"color":"#555C70"}},[_c(VCardTitle,{staticClass:"headline modal grey text-white"},[_vm._v(" NOTE ")]),_c(VCardText,{staticClass:"modal text-white"},[_vm._v(" To offer you the best prices and the most gold for your items, start by filling out your Game Version, Region, Server and Faction in the middle of the site before trading ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"signin-btn mr-3",attrs:{"color":"#17D0C1"},on:{"click":function($event){return _vm.hideModal()}}},[_vm._v(" Okay ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }