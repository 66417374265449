<template>
    <v-dialog v-model="showModal" max-width="500">
        <v-card>
            <v-card-title class="headline">Confirmation</v-card-title>
            <v-card-text>Are you sure you want to remove this item from your cart?</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="confirm">Yes</v-btn>
                <v-btn color="secondary" text @click="closeModal">No</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        showModal: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        confirm() {
            this.$emit('confirm');
            this.closeModal();
        },
        closeModal() {
            this.$emit('close');
        },
    },
    mounted() {
        document.addEventListener('keydown', this.closeOnEscape);
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.closeOnEscape);
    },
    watch: {
        showModal(value) {
            if (value) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
        },
    },
};
</script>

<style scoped>
.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header h4 {
    margin: 0;
}

.modal-header span {
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.modal-body p {
    margin: 0;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.modal-footer button {
    margin-left: 10px;
}
</style>
