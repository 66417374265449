import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","fill-height":"","id":"blogPost"}},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c(VProgressCircular,{attrs:{"size":70,"width":7,"color":"#fff","indeterminate":""}})],1):[_c('h1',{staticStyle:{"font-size":"40px","font-weight":"bold","color":"#fff"}},[_vm._v(_vm._s(_vm.post.title.rendered))]),_c(VCard,{staticClass:"blog-post",staticStyle:{"background-color":"#20283c","color":"#fff"}},[_c('div',{staticClass:"img-wrapper",staticStyle:{"position":"relative"}},[_c(VImg,{staticClass:"blog-overview-image",attrs:{"src":_vm.featuredImage},on:{"load":_vm.onFeaturedImageLoad}}),(!_vm.isFeaturedImageLoaded)?_c(VProgressCircular,{staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)"},attrs:{"size":70,"width":7,"color":"#fff","indeterminate":""}}):_vm._e()],1),_c(VContainer,{staticClass:"post-container"},[_c('p',{staticClass:"post-content",staticStyle:{"color":"#f0f0f0"}},[_vm._v("Post views: "+_vm._s(this.view_count))]),_c('div',{staticClass:"post-content",domProps:{"innerHTML":_vm._s(_vm.post.content.rendered)}})])],1),_c(VBtn,{staticStyle:{"margin-top":"50px"},attrs:{"color":"teal accent-4"},on:{"click":function($event){return _vm.$router.push({ path: '/blog' })}}},[_vm._v(" Back to Posts ")])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }