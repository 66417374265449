// fiat-payment-cards.png
export const fiatPaymentImage = 'https://trade-raid-com.mo.cloudinary.net/fiat-payment-cards.png';
/**
 * @note raw data for services, used for displaying information using the products component in /components/Shop/products.vue
 * @note used in /services to display es/ts/shop and in /shop to display es/ts
 */
export const services = [
    {
        active: true,
        backgroundImage: '/ts-service-img.png',
        boost_method: null,
        custom_options_button: null,
        delivery_time: 24,
        description_list: [],
        description_long: null,
        tags: [
            {
                title: 'service',
                color: '#19bc8b',
            },
            {
                title: 'steam skins to MMO-gold',
                color: '',
            },
        ],
        description_short:
            'Trade your Steam Items (CSGO/DOTA2/RUST/TF2) to WoW Retail, Cataclysm Classic, Classic Era or Season of Discovery Gold',
        execution_speed: null,
        game: 'All',
        price: 0,
        regions: ['EU', 'US'],
        reward_list: [],
        title: 'Skins to WoW Gold',
        type: 'service',
        edge: '/trade',
    },
    {
        active: true,
        backgroundImage: '/es-service-img.png',
        boost_method: null,
        custom_options_button: null,
        delivery_time: 24,
        description_list: [],
        tags: [
            {
                title: 'service',
                color: '#19bc8b',
            },
            {
                title: 'swap gold',
                color: '',
            },
        ],
        description_long: null,
        description_short: 'Swap your gold across Retail and Classic (Cataclysm & Season of Discovery) both ways',
        execution_speed: null,
        game: 'All',
        price: 0,
        regions: ['EU', 'US'],
        reward_list: [],
        title: 'Gold to Gold',
        type: 'service',
        edge: '/exchange',
    },
    // {
    //     active: false,
    //     backgroundImage: 'https://trade-raid-com.mo.cloudinary.net/service_store.png',
    //     boost_method: null,
    //     custom_options_button: null,
    //     delivery_time: 24,
    //     description_list: [],
    //     tags: [
    //         {
    //             title: 'shop',
    //             color: '#19bc8b',
    //         },
    //         // {
    //         //     title: 'boosts',
    //         //     color: '',
    //         // },
    //         // {
    //         //     title: 'mmo-gold',
    //         //     color: '',
    //         // }
    //     ],
    //     description_long: null,
    //     description_short: 'Returns 1st of October 2023', // 'Buy Boosts and MMO Gold',
    //     execution_speed: null,
    //     game: 'All',
    //     price: 0,
    //     regions: ['EU', 'US'],
    //     reward_list: ['Dragonflight Gold', 'Classic Gold', 'Nokhudon level Boost', 'FFIXV Gil', 'New World Gold'],
    //     title: 'Shop',
    //     type: 'service',
    //     edge: '/shop',
    // },
    // {
    //     active: false,
    //     backgroundImage: 'https://trade-raid-com.mo.cloudinary.net/gold_for_skins.png',
    //     boost_method: null,
    //     custom_options_button: null,
    //     delivery_time: 24,
    //     description_list: [],
    //     tags: [
    //         {
    //             title: 'service',
    //             color: '#19bc8b',
    //         },
    //         {
    //             title: '⏳Coming soon',
    //             color: 'red',
    //         },
    //     ],
    //     description_long: null,
    //     description_short: 'Trade your WoW Gold to CSGO, DOTA2 and Rust Skins',
    //     execution_speed: null,
    //     game: 'All',
    //     price: 0,
    //     regions: ['EU', 'US'],
    //     reward_list: ['In Development - Coming June 2023!'],
    //     title: 'Gold to Skins',
    //     type: 'service',
    //     edge: null,
    // },
];

export const shopItemDisplay = {
    amount: 1,
    app_id: 1,
    asset_id: 1,
    context_id: 1,
    currency: '$',
    exterior: null,
    hash: 'Dragonflight Gold',
    image: 'https://trade-raid-com.mo.cloudinary.net/fiat-payment-stacked.png',
    market_hash_name: 'Dragonflight Gold',
    marketable: true,
    name: 'Dragonflight Gold',
    price: 200,
    steam_price: 200,
    tradable: true,
    edge: '/shop',
    type: 'display-item',
};
