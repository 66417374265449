<template>
    <v-container>
        <v-dialog v-model="dialog" persistent max-width="600px" class="rtets">
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="sign-in-btn" height="44px" v-bind="attrs" v-on="on"> Sign Up </v-btn>
            </template>
            <div class="signup-card">
                <v-card>
                    <v-form ref="signup_form" lazy-validation v-model="valid" @submit.prevent="submit">
                        <v-card-title>
                            <span class="text-h5 card-title">Sign Up</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            label="User name*"
                                            v-model="username"
                                            outlined
                                            height="36px"
                                            dense
                                            :rules="[
                                                rules.required,
                                                (value) => {
                                                    return this.is_unique_username || this.username_error_message;
                                                },
                                            ]"
                                            @keyup="updateUserNameErrorMessage()"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            v-model="email"
                                            label="Email*"
                                            type="email"
                                            outlined
                                            height="36px"
                                            dense
                                            @keyup="updateEmailErrorMessage()"
                                            :rules="[
                                                rules.required,
                                                rules.email,
                                                (value) => {
                                                    return this.is_unique_email || this.email_error_message;
                                                },
                                                (value) => {
                                                    return (
                                                        this.is_valid_email_domain ||
                                                        'Email address is not valid. Please use non-disposable email'
                                                    );
                                                },
                                            ]"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            v-model="password"
                                            label="Password*"
                                            type="password"
                                            outlined
                                            height="36px"
                                            dense
                                            :rules="[rules.required]"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-autocomplete
                                            label="Country*"
                                            v-model="country"
                                            :items="countries"
                                            :rules="[rules.required]"
                                            color="primary"
                                            outlined
                                            height="36px"
                                            dense
                                            :menu-props="{ offsetY: true }"
                                            append-icon=""
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="cancel-btn" @click="dialog = false"> Close </v-btn>
                            <v-btn class="signup-btn" type="submit" :loading="is_submitting"> Sign Up </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </div>
        </v-dialog>
    </v-container>
</template>

<script>
import Countries from '@/assets/json/countries.json';
import axios from 'axios';
import store from '../../store';
import { setToken } from '../../utils/auth';

export default {
    data() {
        return {
            dialog: false,
            countries: [],
            country: '',
            is_unique_username: true,
            username_error_message: '',
            is_unique_email: true,
            email_error_message: '',
            password: '',
            username: '',
            valid: false,
            is_valid_email_domain: true,
            is_submitting: false,
            email: '',
            rules: {
                required: (value) => !!value || 'Input field is required!',
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Invalid e-mail. Please enter valid email.';
                },
                min: (value) => (value && value.length >= 8) || 'Please enter minimum 8 character',
            },
        };
    },
    mounted() {
        this.countries = Countries.map((country) => {
            return country.name;
        });
    },
    methods: {
        async submit() {
            if (this.$refs.signup_form.validate()) {
                this.is_submitting = true;
                await axios
                    .get(`https://disposable.debounce.io/?email=${this.email}`)
                    .then((res) => {
                        if (res.data.disposable && (res.data.disposable == true || res.data.disposable == 'true')) {
                            this.is_valid_email_domain = false;
                            this.is_submitting = false;
                            this.$refs.signup_form.validate();
                            return false;
                        }
                    })
                    .catch((err) => {});

                if (this.is_valid_email_domain) {
                    var data = {
                        email: this.email,
                        password: this.password,
                        country: this.country,
                        username: this.username,
                    };

                    let url = process.env.VUE_APP_BACKEND + '/api/registration';
                    let token = process.env.VUE_APP_KEY_BACKEND;

                    axios
                        .post(url, { data: data, token: token })
                        .then((response) => {
                            this.is_submitting = false;
                            response = response.data;

                            if (response.status == 'error') {
                                for (var messageKey in response.message) {
                                    var message = [...response.message[messageKey]].shift();

                                    if (messageKey == 'email') {
                                        this.is_unique_email = false;
                                        this.email_error_message = message;
                                    } else if (messageKey == 'username') {
                                        this.is_unique_username = false;
                                        this.username_error_message = message;
                                    }
                                }
                                this.$refs.signup_form.validate();
                            } else {
                                store.commit('SET_TOKEN', response.access_token);
                                store.commit('SET_USER', response);
                                this.dialog = false;

                                setToken(response.access_token);
                            }
                        })
                        .catch(function (error) {
                            console.log('error: ' + error);
                        });
                }
            }
        },
        updateUserNameErrorMessage() {
            this.is_unique_username = true;
            this.username_error_message = '';

            this.$refs.signup_form.validate();
        },
        updateEmailErrorMessage() {
            this.is_unique_email = true;
            this.is_valid_email_domain = true;
            this.email_error_message = '';

            this.$refs.signup_form.validate();
        },
    },
};
</script>
<style>
.signup-card .theme--light.v-input input,
.theme--light.v-input textarea {
    color: #fff !important;
}
.signup-card .v-label.theme--light {
    color: rgba(255, 255, 255, 0.5);
}
.signup-card
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
    > .v-input__control
    > .v-input__slot
    fieldset,
.signup-card
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
    > .v-input__control
    > .v-input__slot:hover
    fieldset {
    color: #555c70;
}
</style>
<style lang="scss" scoped>
.signup-card {
    .v-card {
        background: #383f51;
        border-radius: 3px;
        border-width: 0;
        height: 100%;

        .v-card__title {
            background: #555c70;
            padding: 20px;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
        }

        .v-card__text {
            padding: 30px 20px;
            overflow: auto;
        }

        .signup-btn,
        .cancel-btn {
            font-family: Open Sans !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 13px !important;
            line-height: 18px !important;
            text-align: center !important;
            text-transform: uppercase !important;
            text-decoration: none !important;
            color: #ffffff !important;
            background: #17d0c1 !important;
            box-shadow: 0px 0px 20px rgba(31, 223, 206, 0.4) !important;
            border-radius: 3px !important;
            padding: 0 30px !important;
        }
    }

    input::placeholder {
        font-size: 13px !important;
        line-height: 18px !important;
        color: rgba(255, 255, 255, 0.5) !important;
    }
    .theme--light.v-list-item:not(.v-list-item--disabled) {
        color: #fff !important;
    }
    .theme--light.v-select .v-select__selection--comma {
        color: #fff;
    }
    .v-menu__content .v-list {
        background: #555c70;
    }
    .theme--light.v-input input {
        color: #fff;
    }
    label.v-label.v-label--active.theme--light {
        color: #555c70;
    }
    .theme--light.v-label {
        color: #555c70;
    }
}
</style>
