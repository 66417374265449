<template>
    <div>
        <v-container fluid fill-height id="trade">
            <div v-if="!$store.state.auth.mobile" class="desktop-view">
                <v-row class="trade-container" style="width: 100%">
                    <v-col class="user-offer-container" cols="12" sm="12" md="12" lg="5" xl="5">
                        <v-row class="flex-column flex-nowrap">
                            <user-offer />
                            <user-items />
                        </v-row>
                    </v-col>

                    <v-col class="user-offer-container" cols="12" sm="12" md="12" lg="2" xl="2">
                        <v-row>
                            <trade-info />
                        </v-row>
                    </v-col>

                    <v-col class="user-offer-container" cols="12" sm="6" md="6" lg="5" xl="5">
                        <v-row>
                            <user-receive />
                        </v-row>
                    </v-col>
                </v-row>
            </div>
            <login-modal :dialog="showInfoTradeModal" @closeInfoModal="hideInfoModal()" />

            <trade-link-modal
                :user="this.user"
                :dialog="showOfferLinkModal"
                @closeOfferLinkModal="hideOfferLinkModal()"
            />

            <steam-login-modal :dialog="showSteamLoginModal" @closeSteamLoginModal="hideSteamLoginModal()" />

            <div class="mobile-view">
                <ProgressNavigation />
                <StepsWrapper />
            </div>

            <steam-login-modal v-if="showSteamLoginModal" @closeSteamLoginModal="hideSteamLoginModal()" />
            <SignInModal :userObj="this.user" :dialog="steamNotSignedIn" />
            <!-- <maintenance-dialog></maintenance-dialog>  -->
            <!-- <v-dialog v-model="dialog" persistent max-width="600px">
                <v-card color="#383F51">
                    <v-card-title class="white--text">
                        <span class="text-h5"> Trade Service Maintenance</span>
                    </v-card-title>
                    <v-card-text class="white--text">
                        Our Trade-Service is currently undergoing maintenance. The service is not available during this
                        time. During this maintenance, users that seek to trade their Steam Items to Gold are
                        recommended to add Trade-Raid on Steam.
                    </v-card-text>
                </v-card>
            </v-dialog> -->
        </v-container>
    </div>
</template>

<script>
import UserOffer from '../../components/UserOffer/';
import UserItems from '../../components/UserItems/';
import TradeInfo from '../../components/TradeInfo/';
import UserReceive from '../../components/UserReceive/';
import LoginModal from '../../components/LoginModal/';
import TradeLinkModal from '../../components/TradeLinkModal/';
import SteamLoginModal from '../../components/SteamLoginModal/';
import SignInModal from '../../components/SignInModal/steamNotSignedIn.vue';
import ProgressNavigation from '../../mobile/ProgressNavigation/';
import StepsWrapper from '../../mobile/StepsWrapper';
import { mapGetters } from 'vuex';
import { toastConfig } from '../../utils/toastConfig.js';
export default {
    name: 'Trade CSGO/DOTA2/RUST Items for WoW Retail/Classic Gold',
    metaInfo: {
        title: 'Trade CSGO/DOTA2/RUST Items for WoW Retail/Classic Gold',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content: 'Trade WOW gold for Steam Items',
            },
        ],
    },
    components: {
        UserOffer,
        UserItems,
        TradeInfo,
        UserReceive,
        LoginModal,
        TradeLinkModal,
        SteamLoginModal,
        SignInModal,
        ProgressNavigation,
        StepsWrapper,
    },
    computed: {
        ...mapGetters(['showInfoTradeModal', 'user']),
    },
    data() {
        return {
            show: false,
            showOfferLinkModal: false,
            showSteamLoginModal: false,
            interval_check: false,
            steamNotSignedIn: false,
            // dialog: true,
        };
    },
    methods: {
        hideInfoModal() {
            this.$store.commit('SHOW_INFO_TRADE_MODAL', false);
        },
        hideOfferLinkModal() {
            this.showOfferLinkModal = false;
            this.$store.dispatch('getItems', 'steam');
        },
        hideSteamLoginModal() {
            this.showSteamLoginModal = false;
        },
    },
    watch: {
        user(newValue, oldValue) {
            if (!newValue.trade_partner_id || (!newValue.trade_partner_id && newValue.steam_id)) {
                this.showOfferLinkModal = true;
            }
            if (!newValue.steam_id) {
                this.steamNotSignedIn = true;
            }
        },
    },
    mounted() {
        const t = toastConfig(`Get +10% gold when selecting all your items per game!`, 5000);
        this.$toast.info(t.msg, t.options);
        if (Object.keys(this.user).length !== 0) {
            if (this.user.steam_id) {
                this.$store.dispatch('getItems', 'steam');
                this.$store.commit('SHOW_INFO_TRADE_MODAL', true);
            }
            if (!this.user.steam_id && this.user.username) {
                this.steamNotSignedIn = true;
            }
            if (!this.user.trade_partner_id && this.user.steam_id) {
                this.showOfferLinkModal = true;
            }
        } else {
            // this.$store.commit('SHOW_INFO_TRADE_MODAL', true);
        }
    },
};
</script>

<style lang="scss" scoped>
#trade {
    padding-top: calc(80px + 40px);
    padding-bottom: 75px;
    flex-direction: column;
    align-content: center;
    background: linear-gradient(
        90deg,
        rgba(0, 14, 25, 0.1) 0%,
        rgba(15, 24, 45, 0.1) 30.7%,
        rgba(62, 0, 108, 0.1) 60.29%,
        rgba(11, 72, 67, 0.1) 79.69%,
        rgba(15, 24, 45, 0.1) 95.48%
    );
    // show mobile view
    @media screen and (max-width: 820px) {
        .desktop-view {
            display: none;
        }
        .mobile-view {
            display: inline-block;
        }
    }
    // show desktop view
    @media screen and (min-width: 821px) {
        .mobile-view {
            display: none;
        }
        .desktop-view {
            display: inline-block;
        }
    }
    .test-step {
        color: #f1f1f1;
    }
    .row {
        height: 100%;
    }
    .mobile-view {
        width: 100%;
        margin-top: -34px;
    }
}
</style>
