<template>
    <v-container fluid fill-height id="about">
        <h1 class="page-title">Ooops...!! this page no longer exist</h1>
        <p class="page-sub-title">
            404 not found :( Check out our front page here:
            <a href="https://trade-raid.com" target="_blank">Front page</a>
        </p>
    </v-container>
</template>

<script>
export default {
    name: '404 Error Page',
    metaInfo: {
        title: 'Error Page',
    },
};
</script>

<style lang="scss" scoped>
#about {
    padding-top: calc(80px + 100px);
    padding-bottom: 124px;
    flex-direction: column;
    align-content: center;
    background: linear-gradient(
        90deg,
        rgba(0, 14, 25, 0.1) 0%,
        rgba(15, 24, 45, 0.1) 30.7%,
        rgba(62, 0, 108, 0.1) 60.29%,
        rgba(11, 72, 67, 0.1) 79.69%,
        rgba(15, 24, 45, 0.1) 95.48%
    );

    .page-title {
        width: 100%;
        font-weight: 600;
        font-size: 36px;
        line-height: 49px;
        color: #fff;
        text-align: center;
    }

    .page-sub-title {
        text-align: center;
        width: 100%;
        max-width: 963px;
        font-size: 18px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 70px;
    }

    .about-container {
        width: 100%;
        max-width: 1530px;

        .image-container {
            width: 100%;
            height: 100%;
            max-height: 400px;
            min-height: 400px;
            margin-bottom: 80px;
            background: url('https://trade-raid-com.mo.cloudinary.net/dota.jpeg') no-repeat;
            background-size: cover;
            background-position: 0 -108px;
        }

        .info-container {
            h2 {
                font-weight: 600;
                font-size: 36px;
                line-height: 60px;
                color: #fff;
            }

            .desc {
                font-size: 18px;
                line-height: 35px;
                color: rgba(255, 255, 255, 0.7);
                margin-bottom: 30px;
            }

            a {
                border: 1px solid #ffffff;
                box-sizing: border-box;
                border-radius: 3px;
                font-family: Open Sans;

                font-weight: bold;
                font-size: 13px;
                line-height: 18px;
                text-transform: uppercase;

                color: #ffffff;
                text-decoration: none;
                padding: 13px 30px;

                display: inline-block;
            }

            a + a {
                margin-left: 20px;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    #about .about-container .info-container h2,
    #about .about-container .info-container .desc {
        text-align: center;
    }
}
</style>
