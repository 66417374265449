<template>
    <div class="payment-methods">
        <div
            class="payment-method flex-inline flex-end"
            v-for="(method, index) in getPaymentMethods"
            :key="index"
            @click="method.active && handleClick(method.key)"
            :class="{ disabled: !method.active }"
        >
            <div class="flex-column">
                <div class="method-action flex-inline">
                    <div class="radio-btn"></div>
                    <span class="method-title">{{ method.display_name }}</span>
                    <span class="comingSoon" v-if="!method.active">Coming Soon!</span>
                    <span class="extraTooltip">{{ method.extraTooltip }}</span>
                </div>
                <span class="fee"
                    >+ {{ $store.state.shop.activeCurrency }} {{ getPaymentInfo(method.key, 'fee') }}</span
                >
            </div>
            <img class="margin-auto" :src="getPaymentInfo(method.key, 'image_path')" />
            <step-payment-skins-modal :visible="showStepPaymentSkinsModal" @close="showStepPaymentSkinsModal = false" />
        </div>
    </div>
</template>

<script>
import '../Shop/utility.styles.css';
import stepPaymentSkinsModal from './stepPaymentSkinsModal.vue';

export default {
    name: 'steps',
    components: {
        stepPaymentSkinsModal,
    },
    props: {
        order: Object,
    },
    data() {
        return {
            activePaymentMethod: null,
            completed_steps: [],
            showStepPaymentSkinsModal: false,
            info: {
                'credit-card': {
                    fee: 9.99,
                    active: true,
                    display_name: 'Debit/Credit Card',
                    image_path: '/images/fiat_payment.png',
                    tooltip: 'Pay with your debit/credit card',
                },
                // steam: {
                //     fee: 0,
                //     active: true,
                //     display_name: 'Pay with Skins',
                //     image_path: '/images/skins_payment.png',
                //     tooltip: 'Finalize your order by paying with skins',
                //     extraTooltip: 'Only for Gold Orders',
                // },
                // 'game-currency': {
                //     fee: 0,
                //     active: false,
                //     display_name: 'Pay with MMO Gold',
                //     image_path: '/images/mmo_payment_2.png',
                //     tooltip: 'Finalize your order by paying with MMO Gold from popular game studios',
                // },
                balance: {
                    fee: 0,
                    active: false,
                    display_name: 'Trade Raid Balance',
                    image_path: '/images/diamond_icon-medium.png',
                    tooltip: 'Using our trade-vault you can pay with your balance from multiple sources',
                },
                crypto: {
                    fee: 0,
                    active: false,
                    display_name: 'Crypto',
                    image_path: '/images/crypto_payment.png',
                    tooltip: 'Complete your order by paying with Crypto',
                },
            },
        };
    },
    computed: {
        getActivePaymentMethod() {
            return this.$store.state.shop.activePaymentMethod;
        },
        getPaymentInfoObject() {
            return this.$store.state.shop.paymentInfo;
        },
        getPaymentMethods() {
            const methods = [];
            for (const key of Object.keys(this.info)) {
                const method = this.info[key];
                method.key = key;
                methods.push(method);
            }
            return methods;
        },
    },
    watch: {},
    methods: {
        handleClick(methodKey) {
            if (methodKey === 'steam') {
                this.showStepPaymentSkinsModal = true;
            } else {
                this.setActivePaymentMethod(methodKey);
            }
        },
        getPaymentInfo(type, field) {
            return this.getPaymentInfoObject[type][field];
        },
        setActivePaymentMethod(method) {
            this.activePaymentMethod = method;
            // set object key method to global state
            this.$store.commit('SET_KEY_VALUE_PAIR', ['activePaymentMethod', method]);

            this.$store.state.shop.activePaymentMethod = method;
            // set service fee from info object to global state
            this.$store.commit('SET_KEY_VALUE_PAIR', ['serviceFee', this.getPaymentInfoObject[method].fee]);
            // push next step
            this.$store.commit('SET_KEY_VALUE_PAIR', ['currentCheckoutStep', { title: 'Review Order', number: 2 }]);
        },
    },
};
</script>

<style scoped>
@media screen and (max-width: 480px) {
    .payment-methods {
        width: 300px;
        /* display: flex;
        align-items: center;
         */
    }
    .flex-column {
        display: flex;
        flex-direction: column;
    }

    img {
        max-width: 60px;
    }

    .method-title {
        font-size: 12px;
        word-break: keep-all;
    }

    .comingSoon {
        font-size: 8px;
    }
}
.payment-methods {
    padding-top: 15px;
}

.payment-method {
    margin-bottom: 15px;
    background-color: #383f51;
    padding-block: 15px;
    padding-inline: 15px;
    border-radius: 5px;
    color: #f1f1f1;
    font-weight: bold;
    align-items: center;
}

.comingSoon {
    font-size: 13px;
    color: #4b90ff;
}

.extraTooltip {
    font-size: 13px;
    color: orange;
}

.radio-btn {
    height: 15px;
    width: 15px;
    border: 2px solid #4b90ff;
    border-radius: 50px;
    cursor: pointer;
}

.radioBtnActive {
    background: #4b90ff;
}

.disabled {
    cursor: no-drop;
}

.method-action {
    display: flex;
    align-items: center;
}

.payment-methods .payment-method {
    cursor: pointer;
}

.payment-methods .payment-method.disabled {
    cursor: not-allowed;
}
</style>
