import Vue from 'vue';
import Vuex from 'vuex';

import getters from './getters';

import trade from './modules/trade';
import auth from './modules/auth';
import errors from './modules/errors';
import shop from './modules/shop';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        trade,
        auth,
        errors,
        shop,
    },
    getters,
});

export default store;
