<template>
    <v-container fluid fill-height id="blogPost">
        <div class="text-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" color="#fff" indeterminate></v-progress-circular>
        </div>
        <!-- Single blog post -->
        <template v-else>
            <h1 style="font-size: 40px; font-weight: bold; color: #fff">{{ post.title.rendered }}</h1>
            <v-card class="blog-post" style="background-color: #20283c; color: #fff">
                <div class="img-wrapper" style="position: relative">
                    <v-img :src="featuredImage" class="blog-overview-image" @load="onFeaturedImageLoad" />
                    <v-progress-circular
                        v-if="!isFeaturedImageLoaded"
                        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"
                        :size="70"
                        :width="7"
                        color="#fff"
                        indeterminate
                    ></v-progress-circular>
                </div>
                <v-container class="post-container">
                    <p class="post-content" style="color: #f0f0f0">Post views: {{ this.view_count }}</p>
                    <div v-html="post.content.rendered" class="post-content"></div>
                </v-container>
            </v-card>
            <v-btn color="teal accent-4" @click="$router.push({ path: '/blog' })" style="margin-top: 50px">
                Back to Posts
            </v-btn>
        </template>
    </v-container>
</template>

<script>
import axios from 'axios';
import { createBlogToken, getBlogToken } from '@/utils/auth.js';
export default {
    data() {
        return {
            loading: true,
            post: {},
            featuredImage: '',
            pageTitle: '',
            pageExcerpt: '',
            view_count: 0,
            isFeaturedImageLoaded: false,
        };
    },
    directives: {
        renderImages: {
            bind(el, binding, vnode) {
                // Find all img tags in the element
                const imgTags = el.querySelectorAll('img');

                // Loop through the img tags
                for (const imgTag of imgTags) {
                    // Create a new image element
                    const imgElement = document.createElement('img');

                    // Set the src attribute of the image element to the src attribute of the img tag
                    imgElement.setAttribute('src', imgTag.getAttribute('src'));

                    // Replace the img tag with the image element
                    imgTag.parentNode.replaceChild(imgElement, imgTag);
                }
            },
        },
    },
    created() {
        this.fetchPost();
        const user_cookie = getBlogToken();

        if (!user_cookie) {
            createBlogToken(Math.random().toString().substring(10, 15));
        }
    },
    methods: {
        generateString(length) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            conmsole.log({ result });
            return result;
        },
        async fetchPost() {
            const slug = this.$route.params.slug;
            const user_cookie = getBlogToken();
            axios
                .get(`https://blog1.trade-raid.com/wp-json/wp/v2/posts?slug=${slug}`)
                .then(async (response) => {
                    this.post = response.data[0];
                    const featuredMediaId = this.post.featured_media;
                    this.pageTitle = this.post.title.rendered;
                    this.pageExcerpt = this.post.excerpt.rendered;

                    const view_count = (
                        await axios.post('https://login.trade-raid.com:5000/blog-post-counter', {
                            slug: this.post.id,
                            cookie: user_cookie,
                        })
                    ).data;

                    if (view_count) {
                        this.view_count = view_count.view_count;
                    }
                    // Remove <p> tags from excerpt
                    this.pageExcerpt = this.pageExcerpt.replace(/<\/?p[^>]*>/g, '');
                    if (featuredMediaId) {
                        axios
                            .get(`https://blog1.trade-raid.com/wp-json/wp/v2/media/${featuredMediaId}`)
                            .then((response) => {
                                this.featuredImage = response.data.source_url;
                            });
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onFeaturedImageLoad() {
            this.isFeaturedImageLoaded = true;
        },
    },
    name: 'Blog Post',
    metaInfo() {
        return {
            title: this.pageTitle,
            meta: [
                { charset: 'utf-8' },
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.pageExcerpt,
                },
            ],
        };
    },
};
</script>

<style lang="scss">
#blogPost {
    padding-top: calc(80px + 100px);
    padding-bottom: 124px;
    flex-direction: column;
    align-content: center;

    .post-container {
        width: 100%;
        margin: 0 auto;
        object-fit: contain;
        object-position: center; /* this will align the image in the center of the element */
    }

    .blog-post {
        // On desktop it should be 50% of the screen
        @media (min-width: 768px) {
            width: calc(50% - 20px); /* 50% smaller than the parent container */
        }
        margin: 0 auto;
        padding: 20px; /* add some padding for aesthetics */
        background-color: #f0f0f0; /* add some background color */
        border-radius: 10px; /* add rounded corners */
    }
    .blog-post img {
        width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        @media (min-width: 768px) {
            width: calc(60% - 20px); /* 50% smaller than the parent container */
        }
    }

    .blogTitle {
        width: 100%;
        font-weight: bold;
        font-size: 50px;
        line-height: 49px;
        color: #fff;
        text-align: center;
    }
    .post-image {
        max-width: 100%; /* set the maximum width to 100% of the parent container */
        max-height: auto; /* Maintaining aspect ratio */
        border-radius: 10px; /* add rounded corners */
    }
    .blogSubtext {
        width: 100%;
        font-weight: bold;
        font-size: 20px;
        line-height: 37px;
        color: #fff;
        text-align: center;
    }
    .titleOverview {
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        color: #fff;
        text-align: center;
        margin-top: 100px;
        margin-bottom: 50px;
    }
    .backgroundImage {
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 500px;
        height: 500px;
        padding-top: 180px !important;
        border-radius: 25px;
    }
    .categoryTitle {
        font-weight: 300;
        font-size: 20px;
        line-height: 37px;
        color: #fff;
        text-align: left;
    }
    .post-content em {
        font-size: 0.95em; /* 5px smaller */
        border-bottom: 2px solid rgba(0, 255, 255, 0.493); /* Add a subtle yellow shadow around the text */
    }
    .post-content h2 {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
    }
    .post-content h3 {
        font-size: 17px;
        font-weight: bold;
        color: #fff;
    }
    .post-content li {
        font-size: 16px;
        color: #7f8c9a;
    }
    .post-content a {
        font-weight: bold;
        color: cyan;
        text-decoration: underline;
    }
    .post-content strong {
        font-weight: bold;
        color: #fff;
    }
    .post-content p {
        font-size: 16px;
        color: #7f8c9a;
    }

    .post-content ul,
    .post-content ol {
        font-size: 16px;
        color: #fff;
    }
    .img-wrapper {
        position: relative;
        width: 100%;
    }

    .blog-overview-image {
        background-repeat: no-repeat;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 500px;
        height: 400px;
    }
    .categories-container {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
    }
    .category-chip {
        display: inline-block;
        margin-right: 8px;
        color: #17d0c1;
    }
}
</style>
