<template>
    <v-container fill-height class="update-password-container">
        <v-row>
            <v-col cols="2"><SideNav class="sidenav-layout" /></v-col>
            <v-col cols="10">
                <v-card>
                    <v-form ref="update_password_form" lazy-validation v-model="valid" @submit.prevent="submit">
                        <v-card-title>
                            <span class="text-h5 card-title">Update Password</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            v-model="old_password"
                                            label="Current Password*"
                                            type="password"
                                            outlined
                                            height="36px"
                                            dense
                                            :rules="[
                                                rules.required,
                                                (value) => {
                                                    return (
                                                        this.is_old_password_match || this.old_password_mismatch_error
                                                    );
                                                },
                                            ]"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            v-model="new_password"
                                            label="New Password*"
                                            type="password"
                                            outlined
                                            height="36px"
                                            dense
                                            :rules="[
                                                rules.required,
                                                (value) => {
                                                    return this.is_password_match || this.password_mismatch_error;
                                                },
                                            ]"
                                            @keyup="checkPasswordMatch"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            v-model="confirm_password"
                                            label="Confirm New Password*"
                                            type="password"
                                            outlined
                                            height="36px"
                                            dense
                                            :rules="[
                                                rules.required,
                                                (value) => {
                                                    return this.is_password_match || this.password_mismatch_error;
                                                },
                                            ]"
                                            @keyup="checkPasswordMatch"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="update-password-btn" type="submit" :loading="is_submitting">
                                Update Password
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
        <template>
            <div class="text-center">
                <v-snackbar v-model="snackbar" :multi-line="true" :timeout="-1" color="primary" absolute>
                    {{ text }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="teal accent-2" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </template>
    </v-container>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SideNav from '../Profile/sidebar.vue';
export default {
    computed: {
        ...mapGetters(['user', 'token']),
    },
    components: {
        SideNav,
    },
    data() {
        return {
            valid: false,
            is_submitting: false,
            is_old_password_match: true,
            snackbar: false,
            old_password_mismatch_error: 'Current password does not match.',
            is_password_match: true,
            password_mismatch_error: 'New password does not match with confirm password',
            confirm_password: '',
            new_password: '',
            old_password: '',
            text: 'Password updated successfully.',
            rules: {
                required: (value) => !!value || 'Input field is required!',
                min: (value) => (value && value.length >= 8) || 'Please enter minimum 8 character',
            },
        };
    },
    methods: {
        submit() {
            if (this.checkPasswordMatch && this.valid) {
                this.is_submitting = true;

                var data = {
                    old_password: this.old_password,
                    new_password: this.new_password,
                    confirm_password: this.confirm_password,
                    token: this.token,
                };
                var url = process.env.VUE_APP_BACKEND + `/exchange/user/update-password`;

                axios
                    .post(
                        url,
                        { data: data },
                        {
                            headers: { Authorization: `Bearer ${this.token}` },
                        },
                    )
                    .then((response) => {
                        this.is_submitting = false;
                        if (response.data.status == 'error') {
                            this.is_old_password_match = false;
                            this.$refs.update_password_form.validate();
                        } else {
                            this.snackbar = true;
                            this.is_old_password_match = true;
                            this.$refs.update_password_form.reset();
                        }
                    })
                    .catch((error) => {
                        this.is_submitting = false;
                        console.log(error);
                    });
            }
        },
        checkPasswordMatch() {
            if (this.new_password == this.confirm_password) {
                this.is_password_match = true;

                this.$refs.update_password_form.validate();
                return true;
            } else {
                this.is_password_match = false;
                this.$refs.update_password_form.validate();
                return false;
            }
        },
    },
};
</script>

<style>
.update-password-container {
    padding-top: 6rem;
}

.update-password-container
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset,
.update-password-container
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
    > .v-input__control
    > .v-input__slot:hover
    fieldset {
    color: #555c70;
}
.update-password-container .theme--light.v-label {
    color: rgba(255, 255, 255, 0.5);
}
.update-password-container .theme--light.v-input input,
.theme--light.v-input textarea {
    color: #fff;
}
</style>

<style lang="scss" scoped>
.sidenav-layout > a {
    color: #f1f1f1 !important;
}
@media screen and (max-width: 820px) {
    .sidenav-layout {
        display: none;
    }
}
.update-password-container {
    flex-basis: 1;

    .v-card {
        background: #383f51;
        border-radius: 3px;
        border-width: 0;
        height: 100%;

        .v-card__title {
            background: #555c70;
            padding: 20px;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
        }

        .v-card__text {
            padding: 30px 20px;
            overflow: auto;
        }

        .update-password-btn {
            font-family: Open Sans !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 13px !important;
            line-height: 18px !important;
            text-align: center !important;
            text-transform: uppercase !important;
            text-decoration: none !important;
            color: #ffffff !important;
            background: #17d0c1 !important;
            box-shadow: 0px 0px 20px rgba(31, 223, 206, 0.4) !important;
            border-radius: 3px !important;
            padding: 0 30px !important;
        }
    }
}
</style>
