const state = {
    aside: false,
    cartItems: [],
    modalState: false,
    activeCurrency: '$',
    activePaymentMethod: null,
    serviceFee: 0,
    currentCheckoutStep: { title: 'Payment Method', number: 1 },
    paymentInfo: null,
    productModalState: null,
    discord_tag: null,
    shopItemDisplay: null,
    currentProductId: null,
    showShopNavModal: false,
};

const mutations = {
    SET_ASIDE_STATE: (state, activeState) => {
        state.aside = activeState;
    },
    ADD_ITEM_TO_CART: (state, item) => {
        state.cartItems.push(item);
    },
    SET_MODAL_STATE: (state, modalState) => {
        state.modalState = modalState;
    },
    SET_KEY_VALUE_PAIR: (state, [key, value]) => {
        state[key] = value;
    },
};

const actions = {
    async toggleAside({ commit }, activeState) {
        commit('SET_ASIDE_STATE', activeState);
    },
    async addItemToCart({ commit }, item) {
        commit('ADD_ITEM_TO_CART', item);
    },
    async productModal({ commit }, modalState) {
        commit('SET_MODAL_STATE', modalState);
        this.$store.dispatch('productModal', true);
    },
};

export default {
    state,
    mutations,
    actions,
};
