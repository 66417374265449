<template>
    <section id="cta" class="wrapper">
        <div class="inner">
            <div class="highlights justify-center align-stretch pb-0">
                <section>
                    <div class="content">
                        <a href="https://steamcommunity.com/id/wowgoldtrader/allcomments" target="_blank">
                            <header>
                                <div class="wrap-img">
                                    <img alt="Steam logo" src="https://trade-raid-com.mo.cloudinary.net/steam.png" />
                                </div>
                                <h2
                                    style="
                                        color: white;
                                        text-align: center;
                                        font-size: 20px;
                                        margin-top: 20px;
                                        margin-left: 100px;
                                    "
                                >
                                    Steam Rep Comments
                                </h2>
                            </header>
                        </a>
                    </div>
                </section>
                <section>
                    <div class="content">
                        <a href="https://www.trustpilot.com/review/trade-raid.com" target="_blank">
                            <header>
                                <div class="wrap-img">
                                    <user-check-icon size="5x" class="star-class"></user-check-icon>
                                </div>
                                <h2
                                    style="
                                        color: white;
                                        text-align: center;
                                        font-size: 20px;
                                        margin-top: 20px;
                                        margin-left: 100px;
                                    "
                                >
                                    Visit our Trustpilot page
                                    <arrow-right-icon size="0.7x" class="custom-class"></arrow-right-icon>
                                </h2>
                            </header>
                        </a>
                    </div>
                </section>
                <!-- <section>
                    <div class="content">
                        <a href="https://www.trustpilot.com/review/trade-raid.com" target="_blank">
                            <header>
                                <div class="wrap-img2">
                                    <img src="https://trade-raid-com.mo.cloudinary.net/trustpilot.png" />
                                </div>
                                <h3>Trustpilot Reviews</h3>
                            </header>
                        </a>
                    </div>
                </section> -->
            </div>
        </div>
    </section>
</template>

<script>
import { UserCheckIcon } from 'vue-feather-icons';
import { ArrowRightIcon } from 'vue-feather-icons';

export default {
    components: {
        UserCheckIcon,
        ArrowRightIcon,
    },
};
</script>

<style lang="scss" scoped>
.star-class {
    color: #17d0c1;
}
#cta {
    height: 450px;
}
span.fb_edge_comment_widget.fb_iframe_widget iframe {
    display: none;
}
.s-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 54px;
    overflow: hidden; /* Hide scrollbars */
    margin-bottom: 10px;
}
#cta .second {
    margin-top: calc(150px - 2rem);
}
#cta .second .invisible {
    visibility: hidden;
}
.trustpilot {
    margin-left: 100px;
    margin-top: 5px;
    background-color: rgba(115, 119, 115, 0.212);
}
.highlights .content header img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
@media screen and (max-width: 1264px) {
    .banner-container img {
        margin-right: 0;
    }
    #cta .second {
        margin-top: calc(75px - 2rem);
    }
    #cta .inner {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        max-width: unset;
    }
    #cta .inner:nth-child(2) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #cta .icon-block:not(:last-child):after {
        display: none;
    }
    #cta .first .icon-block:last-child:after {
        display: none;
    }
}
@media screen and (max-width: 960px) {
    #cta .second {
        margin-top: 0;
    }
}
</style>
