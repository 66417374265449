<template>
    <v-container fill-height class="profile-container">
        <SideNav class="sidenav-layout" />
        <v-row class="main-content-layout">
            <v-col cols="12">
                <div class="profile__row">
                    <UserInfo :orderCount="orderCount" />
                    <UserAwards :orderCount="orderCount" />
                </div>
            </v-col>

            <v-col cols="12">
                <TradeInfo class="content-block-element" />
            </v-col>
        </v-row>
        <EmailNotAuthenticated
            :user="this.user"
            :dissmisable="true"
            explainer="To fully benefit from Trade-Raid's and our services, please register as a user with your email."
            :dialog="showNoEmail"
        />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Countries from '@/assets/json/countries.json';
import axios from 'axios';
import store from '../../store';
import ConnectSteam from '../ConnectSteam';
import SideNav from './sidebar.vue';
import UserInfo from './info.vue';
import TradeInfo from './tradeInfo';
import Orders from './orders';
import UserAwards from './userAwards.vue';
import EmailNotAuthenticated from '../SignInModal/emailNotSignedIn.vue';
import { setDismissEmail, getDismissedEmail } from '../../utils/auth.js';
export default {
    name: 'Profile',
    components: {
        ConnectSteam,
        SideNav,
        UserInfo,
        TradeInfo,
        Orders,
        UserAwards,
        EmailNotAuthenticated,
    },
    computed: {
        ...mapGetters(['user', 'token']),
        showNoEmail() {
            return this.no_email;
        },
    },
    data() {
        return {
            orderCount: {
                ts: null,
                es: null,
            },
            valid: false,
            country: '',
            username: '',
            email: '',
            no_email: false,
            countries: [],
            is_unique_email: true,
            email_error_message: '',
            is_valid_email_domain: true,
            snackbar: false,
            text: 'Profile updated successfully',
            is_unique_username: true,
            username_error_message: '',
            is_submitting: false,
            modal_shows: 0,
            rules: {
                required: (value) => !!value || 'Input field is required!',
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Invalid e-mail. Please enter valid email.';
                },
            },
        };
    },
    async mounted() {
        if (this.user.email) {
            (this.country = this.user.country), (this.username = this.user.username);
            this.email = this.user.email;
        }

        setTimeout(() => {
            const check = getDismissedEmail();
            if (check === undefined) {
                setDismissEmail(false);
            }
            const dismissed = JSON.parse(getDismissedEmail());
            if (!this.user.email && this.user.steam_id && !dismissed) {
                this.no_email = true;
            }
        }, 1000);

        this.countries = Countries.map((country) => {
            return country.name;
        });
    },
    methods: {
        async submit() {
            if (this.$refs.update_profile_form.validate()) {
                this.is_submitting = true;

                await axios
                    .get(`https://disposable.debounce.io/?email=${this.email}`)
                    .then((res) => {
                        if (res.data.disposable && (res.data.disposable == true || res.data.disposable == 'true')) {
                            this.is_valid_email_domain = false;
                            this.is_submitting = false;
                            this.$refs.update_profile_form.validate();
                            return false;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                if (this.is_valid_email_domain) {
                    var data = { username: this.username, country: this.country, email: this.email, token: this.token };
                    var url = process.env.VUE_APP_BACKEND + `/exchange/user/update-profile`;

                    await axios
                        .post(
                            url,
                            { data: data },
                            {
                                headers: { Authorization: `Bearer ${this.token}` },
                            },
                        )
                        .then((response) => {
                            this.is_submitting = false;
                            response = response.data;

                            if (response.status == 'error') {
                                for (var messageKey in response.message) {
                                    var message = [...response.message[messageKey]].shift();

                                    if (messageKey == 'email') {
                                        this.is_unique_email = false;
                                        this.email_error_message = 'Use another email';
                                    } else if (messageKey == 'username') {
                                        this.is_unique_username = false;
                                        this.username_error_message = message;
                                    }
                                }
                                this.$refs.update_profile_form.validate();
                            } else {
                                this.snackbar = true;
                                this.is_unique_username = true;
                                store.commit('SET_USER', response.user);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.is_submitting = false;
                        });
                }
            }
        },
        updateUserNameErrorMessage() {
            this.is_unique_username = true;
            this.username_error_message = '';
            this.is_valid_email_domain = true;
            this.$refs.update_profile_form.validate();
        },
        navigateToTradePage() {
            this.$router.push({ name: 'Exchange' });
        },
    },
};
</script>

<style>
.profile-container input::placeholder {
    font-size: 13px !important;
    line-height: 18px !important;
    color: rgba(255, 255, 255, 0.5) !important;
}
.profile-container
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset,
.profile-container
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
    > .v-input__control
    > .v-input__slot:hover
    fieldset {
    color: #555c70;
}
.profile-container .theme--light.v-list-item:not(.v-list-item--disabled) {
    color: #fff !important;
}
.profile-container .theme--light.v-select .v-select__selection--comma {
    color: #fff;
}
.profile-container .v-menu__content .v-list {
    background: #555c70;
}
.profile-container .theme--light.v-input input {
    color: #fff;
}
.profile-container label.v-label.v-label--active.theme--light {
    color: #555c70;
}
</style>

<style lang="scss" scoped>
.profile-container {
    display: flex;
    flex-wrap: nowrap;
    // gap: 8rem;
}
.profile__sections {
    display: flex;
    gap: 10px;
}
.page__title {
    color: #f1f1f1;
    margin-bottom: 33px;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
}
.profile__row {
    display: flex;
    gap: 10px;
}
/** medium screens */
@media screen and (max-width: 1500px) {
    .profile__row {
        display: flex;
        flex-direction: column;
    }
}

/** small screens */
@media screen and (max-width: 1000px) {
    .grid-container {
        display: flex !important;
        margin-bottom: 450px;
    }
    .sidenav {
        display: none !important;
    }
}

@media screen and (max-width: 820px) {
    .sidenav-layout {
        display: none;
    }
    .main-content-layout {
        display: flex;
        align-items: center;
    }
    .content-block-element {
        display: block;
        width: 100% !important;
    }
}

.trade-info__row {
    width: 100%;
    margin-top: 25px;
}

.v-snack__wrapper {
    bottom: 66vh !important;
}
.profile-container {
    // flex-basis: 1;
    padding-top: 6rem;
    padding-bottom: 250px;
    .v-card {
        background: #383f51;
        border-radius: 3px;
        border-width: 0;
        height: 100%;

        .v-card__title {
            background: #555c70;
            padding: 20px;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
        }

        .v-card__text {
            padding: 30px 20px;
            overflow: auto;
        }

        .update-profile-btn,
        .create-profile-btn {
            font-family: Open Sans !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 13px !important;
            line-height: 18px !important;
            text-align: center !important;
            text-transform: uppercase !important;
            text-decoration: none !important;
            color: #ffffff !important;
            background: #17d0c1 !important;
            box-shadow: 0px 0px 20px rgba(31, 223, 206, 0.4) !important;
            border-radius: 3px !important;
            padding: 0 30px !important;
        }
    }
}
</style>
