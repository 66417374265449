<template>
    <div class="orders-wrapper">
        <div v-if="orders && orders.length" class="orders">
            <vue-good-table
                :columns="columns"
                :rows="orders"
                :pagination="true"
                :search="true"
                :line-numbers="true"
                theme="nocturnal"
                :search-options="{
                    enabled: true,
                    placeholder: 'Search for an Order',
                }"
                :pagination-options="{
                    enabled: true,
                    perPage: 10,
                }"
            >
                <div slot="emptystate">You have no Orders yet</div>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'id'">
                        {{ props.row.id }}
                    </span>
                    <span v-if="props.column.field === 'status'">
                        <div class="order-status" :class="statusController(props.row.status).toLowerCase()">
                            {{ statusController(props.row.status) }}
                        </div>
                    </span>
                    <span v-if="props.column.field == 'created_at'">
                        {{ new Date(props.row.created_at).toLocaleDateString() }}
                        {{
                            new Date(props.row.created_at).toLocaleTimeString(language, {
                                hour: 'numeric',
                                minute: 'numeric',
                            })
                        }}
                    </span>
                    <span v-if="props.column.field === 'client_items'">
                        <div class="flex-inline">
                            {{ props.row.computed_items.length }} Product(s)<ArrowRightIcon />
                            <span class="bold interactive"
                                >{{ $store.state.shop.activeCurrency }} {{ props.row.total_price_in_cents }}</span
                            >
                        </div>
                    </span>
                    <span v-if="props.column.field === 'show_more'">
                        <div @click="showMore(props.row)" class="interactive-btn interactive">show more</div>
                    </span>
                </template>
            </vue-good-table>
            <v-dialog class="order-modal custom-modal-config" v-model="modal" width="500">
                <ShopOrdersModal :order="current_order" />
            </v-dialog>
        </div>
        <div v-else class="loader">
            <Loader />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VueGoodTable } from 'vue-good-table';
import Loader from '../UI/loader.vue';
import { orderHistory } from '../../api/auth';
import { ArrowRightIcon } from 'vue-feather-icons';
import { OrderStatusController } from '../../utils/orderService.js';
import ShopOrdersModal from './shopOrdersModal.vue';

export default {
    name: 'ShopOrders',
    components: {
        VueGoodTable,
        Loader,
        ShopOrdersModal,
        ArrowRightIcon,
    },
    data() {
        return {
            orders: null,
            current_order: null,
            modal: false,
            columns: [
                {
                    label: 'Order-id',
                    field: 'id',
                    slot: 'id',
                },
                {
                    label: 'Status',
                    field: 'status',
                    slot: 'status',
                },
                {
                    label: 'Date',
                    field: 'created_at',
                    type: 'date',
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
                    dateOutputFormat: 'yyyy-MM-dd HH:mm:ss', // outputs 2023-01-02 16:25:40
                },
                {
                    label: 'Total Price',
                    field: 'client_items',
                    slot: 'number_of_products',
                },
                {
                    label: '',
                    field: 'show_more',
                },
            ],
        };
    },
    methods: {
        statusController(status) {
            return OrderStatusController(status);
        },
        showMore(order) {
            this.current_order = order;
            this.modal = true;
        },
        computedTotalPrice(products) {
            let total_price = 0;
            for (const product of products) {
                total_price += product.price;
            }
            // price is in cents;
            return total_price / 100;
        },
    },
    watch: {},
    computed: {
        ...mapGetters(['user']),
    },
    mounted: async function () {
        this.language = navigator.language;
        if (this.user._id && this.user.access_token) {
            this.orders = (await orderHistory('shop', this.user._id, this.user.access_token)).data.data;
        }
    },
};
</script>

<style scoped>
.order-modal {
    overflow-x: hidden !important;
    z-index: 900;
}

.orders {
    color: #f1f1f1;
    width: 100%;
}
.order-status {
    padding: 5px;
    border-radius: 5px;
    min-width: 85px;
    display: flex;
    justify-content: center;
    font-size: 0.7rem;
}
.accepted {
    color: #79c175;
    background-color: #3d4442;
}
.pending {
    color: #fad303;
    background-color: #a59233;
    height: 50%;
}
.declined {
    color: #dc6e6f;
    background-color: #53484e;
}
.interactive {
    color: #17d0c1;
    cursor: pointer;
}
.interactive:hover {
    border-radius: 5px;
}
.loader {
    margin: auto;
    background-color: #383f51;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-inline {
    display: flex;
    flex-direction: row;
    align-items: center;
}
</style>
