<template>
    <div>
        <header id="header" class="defaultscroll sticky" :class="{ 'bg-white': isWhiteheader === true }">
            <div class="container" style="">
                <!-- Logo container-->
                <div>
                    <router-link class="logo" to="/" @click.native="toggleMenu()">
                        <img
                            src="https://trade-raid-com.mo.cloudinary.net/icons/tr-logo-2022.png"
                            class="l-dark"
                            height="43"
                            width="auto"
                            alt="trade-raid.com logo"
                        />
                        <img
                            src="https://trade-raid-com.mo.cloudinary.net/icons/tr-logo-2022.png"
                            class="l-light"
                            height="43"
                            width="auto"
                            alt="trade-raid.com logo"
                        />
                    </router-link>
                </div>
                <!-- End Logo div-->

                <!-- mobile menu toggle-->
                <div class="menu-extras">
                    <div class="menu-item">
                        <!-- Mobile menu toggle-->
                        <div class="header-toggle" @click="toggleMenu()" :class="{ open: isCondensed === true }">
                            <div class="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="navigation">
                    <!-- Navigation Menu-->
                    <ul
                        class="navigation-menu"
                        :class="{ 'nav-light': navLight === true }"
                        style="float: center; position: center"
                    >
                        <li>
                            <router-link to="/services" class="" @click.native="toggleMenu()">Services</router-link>
                        </li>
                        <!-- <li>
                            <router-link
                                to="/shop"
                                style="text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5)"
                                class=""
                                @click.native="toggleMenu()"
                                >Buy</router-link
                            >
                        </li> -->
                        <li>
                            <router-link to="/faq" class="side-nav-link-ref" @click.native="toggleMenu()"
                                >FAQ</router-link
                            >
                        </li>
                        <li>
                            <router-link to="/blog" class="side-nav-link-ref" @click.native="toggleMenu()"
                                >Blog</router-link
                            >
                        </li>
                        <li>
                            <router-link to="/giveaways" class="side-nav-link-ref" @click.native="toggleMenu()"
                                >REWARDS
                            </router-link>
                        </li>
                        <!-- <li class="has-submenu">
                            <a href="javascript:void(0)" @click="onMenuClick">Services</a
                            ><span class="menu-arrow"></span>
                            <ul class="submenu megamenu">
                                <li>
                                    <ul>
                                        <li>
                                            <router-link
                                                to="/trade"
                                                class="side-nav-link-ref"
                                                @click.native="toggleMenu()"
                                                >Trade Service
                                                <span class="badge badge-pill badge-danger">Beta</span>
                                                <br /><br />Trade your STEAM items<br />for MMO Gold
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li>
                                            <router-link
                                                to="/exchange"
                                                class="side-nav-link-ref"
                                                @click.native="toggleMenu()"
                                                >Exchange Service <span class="badge badge-pill badge-info">Beta</span
                                                ><br /><br />Trade your MMO gold<br />across servers & games
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li> -->
                        <!-- <li>
                            <router-link to="/giveaway" class="side-nav-link-ref"
                                >Summer Gold Giveaway
                                <span class="badge badge-pill badge-gold">GIVEAWAY</span>
                            </router-link>
                        </li> -->
                        <!-- <div style="float:right; margin-left:150px; right:200px; z-index:999;"> -->
                        <!--STEAM button-->

                        <div class="buy-button flex-inline" v-if="isIcons !== true">
                            <!-- <VaultModule />
                            <ShoppingCartModule /> -->
                            <sign-in v-if="this.is_new_trade_page" />
                            <v-btn
                                class="btn"
                                :class="{
                                    'btn-light': navLight === true,
                                    'btn-primary': navLight !== true,
                                }"
                                @click="signIn"
                                height="45px"
                                style="display: block; margin-top: 15px"
                                v-if="
                                    !$store.state.auth.user.email &&
                                    !$store.state.auth.user.avatar &&
                                    !this.is_new_trade_page
                                "
                            >
                                <img
                                    src="https://trade-raid-com.mo.cloudinary.net/icons/steam.svg"
                                    alt="Steam logo"
                                    style="display: block; margin-right: 10px"
                                />
                                SIGN IN WITH STEAM
                            </v-btn>
                            <v-menu
                                bottom
                                offset-y
                                v-if="$store.state.auth.user.email || $store.state.auth.user.steam_id"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="sign-button user d-flex justify-space-between"
                                        height="44px"
                                        v-on="on"
                                        style="margin-top: 15px"
                                    >
                                        <div
                                            class="d-flex align-center"
                                            v-if="$store.state.auth.user.username && !$store.state.auth.user.avatar"
                                        >
                                            <span class="white--text">{{ $store.state.auth.user.username }}</span>
                                        </div>
                                        <div class="d-flex align-center" v-else-if="$store.state.auth.user.steam_id">
                                            <img
                                                :src="$store.state.auth.user.avatar"
                                                :alt="$store.state.auth.username"
                                                style="
                                                    display: block;
                                                    margin-right: 10px;
                                                    width: 26px;
                                                    height: 26px;
                                                    border-radius: 26px;
                                                "
                                            />
                                            {{ $store.state.auth.user.username }}
                                        </div>
                                        <div class="d-flex align-center" v-else>
                                            <img
                                                :src="$store.state.auth.user.avatar"
                                                :alt="$store.state.auth.user.name"
                                                style="
                                                    display: block;
                                                    margin-right: 10px;
                                                    width: 26px;
                                                    height: 26px;
                                                    border-radius: 26px;
                                                "
                                            />
                                            {{ $store.state.auth.user.username }}
                                        </div>

                                        <img
                                            src="https://trade-raid-com.mo.cloudinary.net/icons/carret.svg"
                                            alt="dropdown"
                                        />
                                    </v-btn>
                                </template>

                                <v-list class="menu-container">
                                    <v-list-item class="menu-item" :to="'/profile'" link>
                                        <v-list-item-title>Profile</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item class="menu-item" :to="'/orders#ts'" link>
                                        <v-list-item-title>Your Orders</v-list-item-title>
                                    </v-list-item>

                                    <!-- <v-list-item
                                        class="menu-item"
                                        v-if="$store.state.auth.user.steam_id"
                                        :to="'/trade-offer'"
                                        link
                                    >
                                        <v-list-item-title>Trade-Offer Link</v-list-item-title>
                                    </v-list-item> -->

                                    <v-list-item
                                        class="menu-item"
                                        v-if="$store.state.auth.user.email !== null"
                                        :to="'/update-password'"
                                        link
                                    >
                                        <v-list-item-title>Security</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item class="menu-item" @click="logout">
                                        <v-list-item-title class="logout-btn" style="font-weight: bold"
                                            >Log out</v-list-item-title
                                        >
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <!--end STEAM button-->
                        <!-- </div> -->
                    </ul>
                    <!--end navigation menu-->
                </div>
            </div>
        </header>
        <!-- <steamErrorBar v-if="isSteamErrorsActive"></steamErrorBar> -->
        <!-- <giveAwayBar v-if="isThereGiveaways"></giveAwayBar> -->
        <!-- <ShoppingCartAsideModule /> -->
        <CustomBackDrop v-if="$store.state.shop.aside" />
    </div>
</template>

<script>
import SignIn from '../../components/SignIn';
import SignUp from '../../components/SignUp';

export default {
    components: {
        SignIn,
        SignUp,
    },
    data() {
        return {
            lastScrollTop: 0,
            dialog: false,
            headerFixed: true,
            is_new_trade_page: false,
            isCondensed: false,
            showAsideMenu: false,
            isSteamErrorsActive: false,
            isThereGiveaways: false,
            page: 'Trade',
        };
    },
    watch: {
        $route(to, from) {
            this.checkRouteAccess();
        },
    },
    props: {
        isWhiteheader: {
            type: Boolean,
        },
        navLight: {
            type: Boolean,
        },
        isIcons: {
            type: Boolean,
        },
    },
    mounted() {
        this.checkPage();
        window.onscroll = function () {
            onwindowScroll();
        };
        function onwindowScroll() {
            if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                document.getElementById('header').classList.add('nav-sticky');
            } else {
                document.getElementById('header').classList.remove('nav-sticky');
            }
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                document.getElementById('back-to-top').style.display = 'inline';
            } else {
                document.getElementById('back-to-top').style.display = 'none';
            }
        }
        this.checkRouteAccess();

        var links = document.getElementsByClassName('side-nav-link-ref');
        var matchingMenuItem = null;
        for (var i = 0; i < links.length; i++) {
            if (window.location.pathname === links[i].pathname) {
                matchingMenuItem = links[i];
                break;
            }
        }

        if (matchingMenuItem) {
            matchingMenuItem.classList.add('active');
            var parent = matchingMenuItem.parentElement;

            /**
             * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
             * We should come up with non hard coded approach
             */
            if (parent) {
                parent.classList.add('active');
                const parent2 = parent.parentElement;
                if (parent2) {
                    parent2.classList.add('active');
                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.add('active');
                        const parent4 = parent3.parentElement;
                        if (parent4) {
                            const parent5 = parent4.parentElement;
                            parent5.classList.add('active');
                        }
                    }
                }
            }
        }
    },
    updated() {
        this.checkRouteAccess();
    },
    methods: {
        /**
         * Toggle menu
         */
        toggleMenu() {
            this.isCondensed = !this.isCondensed;
            if (this.isCondensed) {
                document.getElementById('navigation').style.display = 'block';
            } else document.getElementById('navigation').style.display = 'none';
        },
        close() {
            document.getElementById('navigation').style.display = 'none';
        },
        /**
         * Menu clicked show the submenu
         */
        onMenuClick(event) {
            event.preventDefault();
            const nextEl = event.target.nextSibling.nextSibling;

            if (nextEl && !nextEl.classList.contains('open')) {
                const parentEl = event.target.parentNode;
                if (parentEl) {
                    parentEl.classList.remove('open');
                }
                nextEl.classList.add('open');
            } else if (nextEl) {
                nextEl.classList.remove('open');
            }
            return false;
        },
        async signIn() {
            this.$store.dispatch('signIn');
        },
        async logout() {
            this.$store.dispatch('resetToken').then(() => {
                this.$router.push({ name: 'Home' });
            });
        },
        checkRouteAccess() {
            window.location.pathname == '/exchange' ||
            window.location.pathname == '/exchange-success' ||
            window.location.pathname == 'profile' ||
            window.location.pathname == 'update-password'
                ? (this.is_new_trade_page = true)
                : (this.is_new_trade_page = false);
        },
        checkPage() {
            if (this.$route.name === this.page) {
                this.isSteamErrorsActive = true;
                this.isThereGiveaways = false;
            } else {
                this.isSteamErrorsActive = false;
                this.isThereGiveaways = true;
            }
        },
    },
    watch: {
        '$route.name': function () {
            this.checkPage();
        },
    },
};
</script>

<style scoped lang="scss">
/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.cart-button {
    // height: 25px;
}
:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}
*,
::after,
::before {
    box-sizing: border-box;
}
header {
    display: block;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}
ul ul {
    margin-bottom: 0;
}
a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
a:hover {
    color: #0056b3;
    text-decoration: underline;
}
a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}
img {
    vertical-align: middle;
    border-style: none;
}
svg {
    overflow: hidden;
    vertical-align: middle;
}
::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}
template {
    display: none;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}
.btn:hover {
    color: #212529;
    text-decoration: none;
}
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn:disabled {
    opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}
.btn-primary:focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}
.btn-light:focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.dropdown {
    position: relative;
}
.header {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}
.header .container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}
a.badge:focus,
a.badge:hover {
    text-decoration: none;
}
.badge:empty {
    display: none;
}
.btn .badge {
    position: relative;
    top: -1px;
}
.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}
.badge-info {
    color: #fff;
    background-color: #17a2b8;
}
.badge-gold {
    color: #fff;
    background-color: #dcc335;
}
a.badge-info:focus,
a.badge-info:hover {
    color: #fff;
    background-color: #117a8b;
}
a.badge-info:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.badge-danger {
    color: #fff;
    background-color: #dc3545;
}
a.badge-danger:focus,
a.badge-danger:hover {
    color: #fff;
    background-color: #bd2130;
}
a.badge-danger:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}
@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}
@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
.bg-white {
    background-color: #fff !important;
}
@media print {
    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }
    a:not(.btn) {
        text-decoration: underline;
    }
    img {
        page-break-inside: avoid;
    }
    @page {
        size: a3;
    }
    body {
        min-width: 992px !important;
    }
    .container {
        min-width: 992px !important;
    }
    .header {
        display: none;
    }
    .badge {
        border: 1px solid #000;
    }
}
/*# sourceMappingURL=bootstrap.min.css.map */
/* Template Name: Landrick - Saas & Software Landing Page Template
   Author: Themesbrand
   E-mail: themesbrand@gmail.com
   Version: 2.6
   Updated: November 2020
   File Description: Main Css file of the template
*/
/*********************************/
/*         INDEX                 */
/*================================
 *     01.  Variables            *
 *     02.  Bootstrap Custom     *
 *     03.  Components           *
 *     04.  General              *
 *     05.  Helper               *
 *     06.  Menu                 *
 *     07.  Home / Landing       *
 *     08.  Features / Services  *
 *     09.  Testimonial          *
 *     10.  Price                *
 *     11.  C.T.A                *
 *     12.  Blog / Careers / Job *
 *     13.  Work / Projects      *
 *     14.  Team                 *
 *     15.  User Pages           *
 *     16.  Countdown            *
 *     17.  Contact              *
 *     18.  Footer               *
 *********************************
 
 *********************************
 *         Dark SCSS             *
 *********************************
 *     01.  Variables            *
 *     02.  Bootstrap Custom     *
 *     03.  Components           *
 *     04.  General              *
 *     05.  Helper               *
 *     06.  Menu                 *
 ================================*/
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.btn-primary {
    background-color: #17d0c1 !important;
    border: 1px solid #17d0c1 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
    box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
    background-color: #2443ac !important;
    border-color: #2443ac !important;
    color: #ffffff !important;
}

.btn-light {
    background-color: #f8f9fc !important;
    border: 1px solid #f8f9fc !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
    box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.active {
    background-color: #d4daed !important;
    border-color: #d4daed !important;
    color: #ffffff !important;
}

.btn {
    padding: 8px 20px;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-weight: 600;
    border-radius: 6px;
}

.btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.btn.btn-light {
    color: #3c4858 !important;
    border: 1px solid #dee2e6 !important;
}

.btn.btn-light:hover,
.btn.btn-light:focus,
.btn.btn-light:active,
.btn.btn-light.active {
    background-color: #d4daed !important;
    color: #3c4858 !important;
}

.badge-info {
    background-color: #17a2b8 !important;
    color: #ffffff !important;
}

.badge-danger {
    background-color: #e43f52 !important;
    color: #ffffff !important;
}

.badge {
    display: inline-block;
    letter-spacing: 0.5px;
    padding: 4px 8px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}

.badge.badge-pill {
    border-radius: 12px;
}

.badge:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

@-webkit-keyframes animate-positive {
    0% {
        width: 0;
    }
}

@keyframes animate-positive {
    0% {
        width: 0;
    }
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.back-to-top {
    z-index: 99;
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: none;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.back-to-top .icons {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.back-to-top:hover {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.back-to-top:hover .icons {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

body {
    font-family: 'Nunito', sans-serif;
    overflow-x: hidden !important;
    font-size: 16px;
    color: #161c2d;
}

::-moz-selection {
    background: rgba(47, 85, 212, 0.9);
    color: #ffffff;
}

::selection {
    background: rgba(47, 85, 212, 0.9);
    color: #ffffff;
}

.menu-container {
    display: flex;
    z-index: 1;
    flex-direction: column;
    background-color: #555c70;
    color: #f1f1f1;
}

.menu-item {
    color: #f1f1f1 !important;
}

a {
    text-decoration: none !important;
}

@-webkit-keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
}

@keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
}

@-webkit-keyframes spinner-animation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-animation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

#header {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1030;
    background-color: transparent;
    border: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#header .logo {
    position: absolute;
    left: 100px;
    color: #3c4858 !important;
}

#header .logo .l-dark {
    display: none;
}

#header .logo .l-light {
    display: inline-block;
}

#header .has-submenu.active a {
    color: #ffffff;
}

/* #header .has-submenu.active.active .menu-arrow {
    border-color: #17d0c1;
} */

#header .has-submenu {
    position: relative;
}

#header .header-toggle {
    border: 0;
    position: relative;
    width: 60px;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

#header .header-toggle .lines {
    width: 25px;
    display: block;
    position: relative;
    margin: 28px 20px;
    height: 18px;
}

#header .header-toggle span {
    height: 2px;
    width: 100%;
    background-color: #3c4858;
    display: block;
    margin-bottom: 5px;
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

#header .header-toggle span:last-child {
    margin-bottom: 0;
}

#header .header-toggle.open span {
    position: absolute;
}

#header .header-toggle.open span:first-child {
    top: 6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

#header .header-toggle.open span:nth-child(2) {
    visibility: hidden;
}

#header .header-toggle.open span:last-child {
    width: 100%;
    top: 6px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/* #header .header-toggle.open span:hover {
    background-color: #17d0c1;
} */

#header .header-toggle:hover,
#header .header-toggle:focus,
#header .header-toggle .navigation-menu > li > a:hover,
#header .header-toggle:focus {
    background-color: transparent;
}

#header .navigation-menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

#header .navigation-menu > li {
    float: left;
    display: block;
    position: relative;
    margin: 0 10px;
}

@media screen and (min-width: 1100px) {
    #header .navigation-menu > li:hover {
        background-color: cyan !important;

        height: 5px;
        // width: 1%;
        border-radius: 5px;
        transition: height 1s ease;
        // position: static
    }
}

#header .navigation-menu > li:hover > a {
    color: cyan !important;
    // background-color: #a59e9e!important;
}

.active {
    color: cyan !important;
}

#header .navigation-menu > li > a {
    display: block;
    color: #3c4858;
    font-size: 13px;
    background-color: transparent !important;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 24px;
    text-transform: uppercase;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    font-family: 'Nunito', sans-serif;
    padding-left: 15px;
    padding-right: 15px;
}

#header .navigation-menu > li > a:hover,
#header .navigation-menu > li > a:active {
    color: #17d0c1;
}

#header .navigation-menu .has-submenu .menu-arrow {
    border: solid #3c4858;
    border-radius: 0.5px;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    right: -1px;
    top: 30px;
}

#header .navigation-menu .has-submenu:hover .menu-arrow {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
}

#header .menu-extras {
    float: right;
}

#header.nav-sticky {
    background: #ffffff;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

#header.nav-sticky .navigation-menu.nav-light > li > a {
    color: #3c4858;
}

#header.nav-sticky .navigation-menu.nav-light > li.active > a {
    color: #17d0c1 !important;
}

#header.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow,
#header.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
    border-color: #17d0c1 !important;
}

#header.nav-sticky .navigation-menu.nav-light > li:hover > a,
#header.nav-sticky .navigation-menu.nav-light > li.active > a {
    color: #17d0c1 !important;
}

#header.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
    border-color: #3c4858;
}

#header.nav-sticky .logo .l-dark {
    display: inline-block;
}

#header.nav-sticky .logo .l-light {
    display: none;
}

.logo {
    font-weight: 700;
    font-size: 24px;
    padding: 0 0 6px;
    letter-spacing: 1px;
    line-height: 68px;
}

@media (min-width: 992px) {
    #header .navigation-menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    #header .navigation-menu > .has-submenu > .submenu > .has-submenu > .submenu::before {
        top: 20px;
        margin-left: -35px;
        -webkit-box-shadow: 1px 1px 0px 0px rgba(60, 72, 88, 0.15);
        box-shadow: 1px 1px 0px 0px rgba(60, 72, 88, 0.15);
        border-color: transparent #ffffff #ffffff transparent;
    }
    #header .navigation-menu > .has-submenu:hover .menu-arrow {
        top: 33px !important;
    }
    #header .navigation-menu > .has-submenu.active .menu-arrow {
        top: 30px;
    }
    #header .navigation-menu > li .submenu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        padding: 15px 0;
        list-style: none;
        min-width: 180px;
        visibility: hidden;
        opacity: 0;
        margin-top: 10px;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        border-radius: 6px;
        background-color: #ffffff;
        -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
        box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    }
    #header .navigation-menu > li .submenu:before {
        content: '';
        position: absolute;
        top: 2px;
        left: 45px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 7px solid #495057;
        border-color: transparent transparent #ffffff #ffffff;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        -webkit-box-shadow: -2px 2px 2px -1px rgba(60, 72, 88, 0.15);
        box-shadow: -2px 2px 2px -1px rgba(60, 72, 88, 0.15);
    }
    #header .navigation-menu > li .submenu li {
        position: relative;
    }
    #header .navigation-menu > li .submenu li a {
        display: block;
        padding: 10px 20px;
        clear: both;
        white-space: nowrap;
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        font-weight: 700;
        color: #3c4858 !important;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }
    #header .navigation-menu > li .submenu li a:hover {
        color: #17d0c1 !important;
    }
    #header .navigation-menu > li .submenu li ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
    }
    #header .navigation-menu > li .submenu.megamenu {
        white-space: nowrap;
        width: auto;
    }
    #header .navigation-menu > li .submenu.megamenu > li {
        overflow: hidden;
        min-width: 180px;
        display: inline-block;
        vertical-align: top;
    }
    #header .navigation-menu > li .submenu.megamenu > li .submenu {
        left: 100%;
        top: 0;
        margin-left: 10px;
        margin-top: -1px;
    }
    #header .navigation-menu > li .submenu > li .submenu {
        left: 106%;
        top: 0;
        margin-left: 10px;
        margin-top: -1px;
    }
    #header .navigation-menu > li > a {
        padding-top: 25px;
        padding-bottom: 25px;
        min-height: 62px;
    }
    #header .navigation-menu > li:hover > .menu-arrow {
        border-color: #17d0c1;
    }
    /* #header .navigation-menu > li:hover > a,
    #header .navigation-menu > li.active > a {
        color: #17d0c1 !important;
    } */
    #header .navigation-menu.nav-light > li > a {
        color: rgba(255, 255, 255, 0.5);
    }
    #header .navigation-menu.nav-light > li.active > a {
        color: #ffffff !important;
    }
    #header .navigation-menu.nav-light > li:hover > .menu-arrow {
        border-color: #ffffff !important;
    }
    #header .navigation-menu.nav-light > li:hover > a {
        color: #ffffff !important;
    }
    #header .navigation-menu.nav-light .has-submenu .menu-arrow {
        border-color: rgba(255, 255, 255, 0.5);
    }
    #header .navigation-menu.nav-light .has-submenu.active .menu-arrow {
        border-color: #ffffff !important;
    }
    #header .buy-button {
        position: absolute;
        right: 100px;
        line-height: 68px;
    }
    #header .header-toggle {
        display: none;
    }
    #header #navigation {
        display: block !important;
    }
}

@media (max-width: 991px) {
    #header {
        background-color: #ffffff;
        -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
        box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
        min-height: 74px;
    }
    #header .logo .l-dark {
        display: inline-block !important;
    }
    #header .logo .l-light {
        display: none !important;
    }
    #header .container {
        width: auto;
    }
    #header #navigation {
        max-height: 400px;
    }
    #header .navigation-menu {
        float: none;
    }
    #header .navigation-menu > li {
        float: none;
    }
    #header .navigation-menu > li .submenu {
        display: none;
        list-style: none;
        padding-left: 20px;
        margin: 0;
    }
    #header .navigation-menu > li .submenu li a {
        display: block;
        position: relative;
        padding: 7px 15px;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.04em;
        font-weight: 700;
        color: #3c4858 !important;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }
    #header .navigation-menu > li .submenu.open {
        display: block;
    }
    #header .navigation-menu > li .submenu .submenu {
        display: none;
        list-style: none;
    }
    #header .navigation-menu > li .submenu .submenu.open {
        display: block;
    }
    #header .navigation-menu > li .submenu.megamenu > li > ul {
        list-style: none;
        padding-left: 0;
    }
    #header .navigation-menu > li .submenu.megamenu > li > ul > li > span {
        display: block;
        position: relative;
        padding: 10px 15px;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 2px;
        color: #8492a6;
    }
    #header .navigation-menu > li > a {
        color: #3c4858;
        padding: 10px 20px;
    }
    #header .navigation-menu > li > a:after {
        position: absolute;
        right: 15px;
    }
    #header .navigation-menu > li > a:hover,
    #header .navigation-menu > li .submenu li a:hover,
    #header .navigation-menu > li.has-submenu.open > a {
        color: #17d0c1;
    }
    #header .menu-extras .menu-item {
        border-color: #8492a6;
    }
    #header .has-submenu.active a {
        color: #17d0c1;
    }
    #navigation {
        position: absolute;
        top: 74px;
        left: 0;
        width: 100%;
        display: none;
        height: auto;
        padding-bottom: 0;
        overflow: auto;
        border-top: 1px solid #f1f3f9;
        border-bottom: 1px solid #f1f3f9;
        background-color: #ffffff;
    }
    #navigation.open {
        display: block;
        overflow-y: auto;
    }
}

@media (max-width: 768px) {
    #header .navigation-menu .has-submenu .menu-arrow {
        right: 16px;
        top: 16px;
    }
    #header .buy-button {
        padding: 3px 0;
    }
}

@media (min-width: 768px) {
    #header .navigation-menu > li.has-submenu:hover > .submenu {
        visibility: visible;
        opacity: 1;
        margin-top: 0;
    }
    #header .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
        visibility: visible;
        opacity: 1;
        margin-left: 0;
        margin-right: 0;
    }
    .header-toggle {
        display: block;
    }
}

@media (max-width: 375px) {
    #header .header-toggle .lines {
        margin-right: 0 !important;
    }
}

.bg-white {
    background-color: #1f2d3d !important;
}

.btn-primary {
    background-color: #17d0c1 !important;
    border: 1px solid #17d0c1 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
    box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
    background-color: #2443ac !important;
    border-color: #2443ac !important;
    color: #ffffff !important;
}

.btn-light {
    background-color: #253649 !important;
    border: 1px solid #253649 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(37, 54, 73, 0.3);
    box-shadow: 0 3px 5px 0 rgba(37, 54, 73, 0.3);
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.active {
    background-color: #141d27 !important;
    border-color: #141d27 !important;
    color: #ffffff !important;
}

.btn.btn-light {
    color: #ffffff !important;
    border-color: #494f54 !important;
}

.btn.btn-light:hover,
.btn.btn-light:focus,
.btn.btn-light:active,
.btn.btn-light.active {
    background-color: #364f6b !important;
    color: #ffffff !important;
}

.badge-info {
    background-color: #17a2b8 !important;
    color: #ffffff !important;
}

.badge-danger {
    background-color: #e43f52 !important;
    color: #ffffff !important;
}

.back-to-top {
    color: #ffffff;
    background-color: #495057;
    -webkit-box-shadow: 0 0 3px rgba(255, 255, 255, 0.15);
    box-shadow: 0 0 3px rgba(255, 255, 255, 0.15);
}

.back-to-top:hover {
    color: #1f2d3d;
}

body {
    color: #ffffff;
    background: #1f2d3d;
}

::-moz-selection {
    color: #ffffff;
}

::selection {
    color: #ffffff;
}

#header .logo {
    color: #ffffff !important;
}

#header .logo .l-dark {
    display: none !important;
}

#header .logo .l-light {
    display: inline-block !important;
}

#header .has-submenu.active a {
    color: #1f2d3d;
}

#header .header-toggle span {
    background-color: #ffffff;
}

#header .navigation-menu > li > a {
    color: #ffffff;
}

#header .navigation-menu .has-submenu .menu-arrow {
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
}

#header.nav-sticky {
    background: #1f2d3d;
    -webkit-box-shadow: 0 0 3px rgba(255, 255, 255, 0.15);
    box-shadow: 0 0 3px rgba(255, 255, 255, 0.15);
}

#header.nav-sticky .navigation-menu.nav-light > li > a {
    color: #ffffff;
}

#header.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
    border-color: #ffffff;
}

@media (min-width: 992px) {
    #header .navigation-menu > .has-submenu > .submenu > .has-submenu > .submenu::before {
        -webkit-box-shadow: 1px 1px 0px 0px rgba(255, 255, 255, 0.15);
        box-shadow: 1px 1px 0px 0px rgba(255, 255, 255, 0.15);
        border-color: transparent #1f2d3d #1f2d3d transparent;
    }
    #header .navigation-menu > li .submenu {
        background-color: #1f2d3d;
        -webkit-box-shadow: 0 0 3px rgba(255, 255, 255, 0.15);
        box-shadow: 0 0 3px rgba(255, 255, 255, 0.15);
    }
    #header .navigation-menu > li .submenu:before {
        border: 7px solid #f8f9fa;
        border-color: transparent transparent #1f2d3d #1f2d3d;
        -webkit-box-shadow: -2px 2px 2px -1px rgba(255, 255, 255, 0.15);
        box-shadow: -2px 2px 2px -1px rgba(255, 255, 255, 0.15);
    }
    #header .navigation-menu > li .submenu li a {
        color: #ffffff !important;
    }
}

@media (max-width: 991px) {
    #header {
        background-color: #1f2d3d;
        -webkit-box-shadow: 0 0 3px rgba(255, 255, 255, 0.15);
        box-shadow: 0 0 3px rgba(255, 255, 255, 0.15);
    }
    #header .navigation-menu > li .submenu li a {
        color: #ffffff !important;
    }
    #header .navigation-menu > li .submenu.megamenu > li > ul > li > span {
        color: #9bacc4;
    }
    #header .navigation-menu > li > a {
        color: #ffffff;
    }
    #header .menu-extras .menu-item {
        border-color: #9bacc4;
    }
    #navigation {
        border-top: 1px solid #223142;
        border-bottom: 1px solid #223142;
        background-color: #1f2d3d;
    }
}
/*# sourceMappingURL=style-dark.css.map */
</style>
