import { render, staticRenderFns } from "./trade-protection.vue?vue&type=template&id=31c9e188&scoped=true&"
import script from "./trade-protection.vue?vue&type=script&lang=js&"
export * from "./trade-protection.vue?vue&type=script&lang=js&"
import style0 from "./trade-protection.vue?vue&type=style&index=0&id=31c9e188&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31c9e188",
  null
  
)

export default component.exports