<template>
    <v-app id="app">
        <Header />
        <DynamicBanner class="banner" v-for="(banner, bidx) in banners" :banner="banner" :key="bidx" />
        <v-main>
            <router-view />
        </v-main>
        <!-- <Cookie /> -->
        <Footer />
    </v-app>
</template>

<script>
import Header from './components/Header/';
import Footer from './components/Footer/';
import Cookie from './components/UI/cookie-badge.vue';
import { getActiveBanners, getEventBadges } from './api/services';
// change here
export default {
    async beforeCreate() {
        await getActiveBanners().then((res) => {
            this.banners = res.data;
            console.log(this.banners);
        });
        await getEventBadges().then((res) => {
            this.$store.state.trade.eventBadges = res.data;
        });
    },
    mounted() {
        this.$store.dispatch('getInfo');
        this.onResize();
        window.addEventListener('resize', this.onResize, { passive: true });
    },
    beforeDestroy() {
        if (typeof window === 'undefined') return;
        window.removeEventListener('resize', this.onResize, { passive: true });
    },
    name: 'App',
    components: {
        Header,
        Cookie,
        Footer,
    },
    data() {
        return {
            isMobile: false,
            banners: [
                // {
                //     color: '#806CF5',
                //     text_desktop: 'Patch 10.1 Giveaway has ended! Use 15% Coupon Code: THANKU15 on all orders! -',
                //     text_mobile: 'Patch 10.1 Giveaway has ended! Use 15% Coupon Code: THANKU15 on all orders! -',
                //     href_link: '/services',
                //     link_text: 'Trade Now!',
                //     pages: ['/', '/services', '/shop', '/exchange'],
                //     icon_start: '🐉',
                //     icon_end: '💰',
                // },
                // {
                //     color: '#333',
                //     text_desktop: 'Trade Banner',
                //     text_mobile: 'Trade Banner',
                //     href_link: null,
                //     link_text: null,
                //     pages: ['/trade'],
                //     icon_start: '🐉',
                //     icon_end: '💰',
                // }
            ],
        };
    },
    methods: {
        onResize() {
            this.isMobile = window.innerWidth < 600;
        },
    },
    watch: {
        isMobile: function (n_val, o_val) {
            console.log(n_val);
            // this.$store.state.auth.isMobile = n_val
        },
        user: {
            handler() {
                if (user.steam_id) {
                    this.$store.dispatch('setGame', 'steam');
                    this.$store.dispatch('getItems', 'steam');
                }
            },
        },
    },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Trade-Raid.com - Buy WoW gold with Steam items',
        // all titles will be injected into this template
        titleTemplate: '%s - Trade-raid.com',
    },
};
</script>

<style>
.v-main {
    /* 
        primary color (new) : 202136
        primary color (old) : 20283c 
    */
    background: #202136;
}
.banner {
    transition: ease-in 1s;
}
.silver {
    color: #d2dbdb !important;
}
.mr-1 {
    margin-right: 1px;
}
.mr-2 {
    margin-right: 2px;
}
.mr-3 {
    margin-right: 3px;
}
.mr-4 {
    margin-right: 4px;
}
.mr-5 {
    margin-right: 5px;
}
.custom-modal-config {
    /** use this class to ensure if modals overlap top-header it is first */
    z-index: 1031 !important;
}
</style>
