import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orders-wrapper"},[(_vm.orders && _vm.orders.length)?_c('div',{staticClass:"orders"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.orders,"pagination":true,"search":true,"line-numbers":true,"theme":"nocturnal","search-options":{
                enabled: true,
                placeholder: 'Search for an Order',
            },"pagination-options":{
                enabled: true,
                perPage: 10,
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'id')?_c('span',[_vm._v(" "+_vm._s(props.row.id)+" ")]):_vm._e(),(props.column.field === 'status')?_c('span',[_c('div',{staticClass:"order-status",class:_vm.statusController(props.row.status).toLowerCase()},[_vm._v(" "+_vm._s(_vm.statusController(props.row.status))+" ")])]):_vm._e(),(props.column.field == 'created_at')?_c('span',[_vm._v(" "+_vm._s(new Date(props.row.created_at).toLocaleDateString())+" "+_vm._s(new Date(props.row.created_at).toLocaleTimeString(_vm.language, { hour: 'numeric', minute: 'numeric', }))+" ")]):_vm._e(),(props.column.field === 'client_items')?_c('span',[_c('div',{staticClass:"flex-inline"},[_vm._v(" "+_vm._s(props.row.computed_items.length)+" Product(s)"),_c('ArrowRightIcon'),_c('span',{staticClass:"bold interactive"},[_vm._v(_vm._s(_vm.$store.state.shop.activeCurrency)+" "+_vm._s(props.row.total_price_in_cents))])],1)]):_vm._e(),(props.column.field === 'show_more')?_c('span',[_c('div',{staticClass:"interactive-btn interactive",on:{"click":function($event){return _vm.showMore(props.row)}}},[_vm._v("show more")])]):_vm._e()]}}],null,false,2772548388)},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v("You have no Orders yet")])]),_c(VDialog,{staticClass:"order-modal custom-modal-config",attrs:{"width":"500"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('ShopOrdersModal',{attrs:{"order":_vm.current_order}})],1)],1):_c('div',{staticClass:"loader"},[_c('Loader')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }