<template>
    <div class="text-center" v-if="user.steam_id">
        <v-dialog persistent v-model="dialog" width="500">
            <v-card color="#555C70">
                <v-card-title class="headline modal grey text-white"> TRADE OFFER LINK </v-card-title>
                <v-card-text class="modal text-white">
                    <p class="text-center">
                        To calculate your Steam Inventory to MMO Gold Trade-Raid needs your Trade Offer Link.
                        <br />
                        <span> You can retrieve your Trade Offer Link </span>
                        <a
                            :href="`https://steamcommunity.com/profiles/${user.steam_id}/tradeoffers/privacy#trade_offer_access_url`"
                            class="ext_link"
                            target="_blank"
                        >
                            HERE
                        </a>
                    </p>
                    <p class="mobile-text">
                        On mobile: If your Steam app doesn't load, close the app and press the link above again.
                    </p>
                </v-card-text>
                <p class="text-center text-white">Copy and Paste your Trade Offer Link in the box below:</p>
                <div style="width: 70%; margin: 0 auto; color: white">
                    <v-text-field
                        v-model="url"
                        filled
                        label="Your Trade Offer Link"
                        outlined
                        class="text-white"
                        color="#fff"
                        light
                        required
                    ></v-text-field>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#17D0C1" class="signin-btn mr-3" @click="hideModal()" style="width: 100%">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { setTradeOfferInfo } from '../../api/auth';
export default {
    props: {
        dialog: Boolean,
        user: Object,
    },
    data() {
        return {
            url: '',
        };
    },
    methods: {
        async hideModal() {
            try {
                await setTradeOfferInfo(this.user._id, { link: this.url }, this.user.access_token).then(() => {
                    this.$store.dispatch('getInfo');
                    this.$emit('closeOfferLinkModal');
                });
            } catch (error) {
                console.log(error);
            }

            /**this.$store
                .dispatch('setTradeOfferInfo', this.url)
                .then(() => {
                    this.$emit('closeOfferLinkModal');
                })
                .catch((err) => {
                    console.log(err);
                });*/
        },
    },
};
</script>
<style scoped>
.mobile-text {
    display: none;
}

@media only screen and (max-width: 768px) {
    .mobile-text {
        display: block;
    }
}
.v-dialog .modal.grey {
    background: #555c70;
}
.text-white {
    color: #fff !important;
}
.ext_link {
    color: #17d0c1;
    text-decoration: underline;
}

.ext_link:hover {
    color: #17d0c1 !important;
    opacity: 0.5;
}
.v-dialog .modal.text-white {
    color: #fff;
    padding: 10px 20px;
    font-size: 17px;
    line-height: 35px;
    font-family: 'Roboto';
}
.v-dialog .text-white >>> .v-label {
    color: #fff;
}
.v-dialog .text-white >>> input {
    color: #fff;
}
</style>
