import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    primary: '#555C70',
    theme: {
        primary: '#555C70',
        themes: {
            primary: '#555C70',
            light: {
                primary: '#555C70',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
        },
    },
});
